<mat-card appearance="outlined">
    <div fxLayout="row" fxLayoutGap="40px">
        <div fxLayout="column" fxFlex>
            <div>
                <div fxLayout="row">
                    <div fxFlex="35%" fxLayout="row" fxLayoutAlign="start center">
                        <i class="material-icons layers-icon">layers</i>
                        <span class="title">{{ projectName }}</span>
                        <button mat-icon-button (click)="editProject()" *ngIf="user.permissions.canUpdate">
                            <i class="material-icons">edit</i>
                        </button>
                    </div>
                    <div class="area" fxFlex fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="35px">
                        <span>{{ projectArea }}</span>

                        <div class="nbz-status" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                <i
                                    class="material-icons {{
                                        projectStatus?.projectOnRunning
                                            ? 'icon-on'
                                            : 'icon-off'
                                    }}"
                                    >fiber_manual_record</i
                                >
                                <span>Projeto</span>
                                <span class="bold">{{
                                    projectStatus.projectOnRunning ? "ligado" : "desligado"
                                }}</span>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                <i class="material-icons {{ projectStatus.projectApt ? 'icon-on' : 'icon-off' }}">{{
                                    projectStatus.projectApt ? "check_circle" : "cancel"
                                }}</i>
                                <span>Projeto</span>
                                <span class="bold">{{ projectStatus.projectApt ? "apto" : "inapto" }}</span>
                                <a [matMenuTriggerFor]="projectStatusMenu" (click)="getProjectStatus()">
                                    <div fxLayout="column" fxLayoutAlign="end">
                                        <i class="material-icons menu bold">arrow_drop_down</i>
                                    </div>
                                </a>
                            </div>
                            <div class="last-date" fxLayout="row" fxFlex fxLayoutAlign="start center">
                                <span
                                    >Última Execução:
                                    {{ projectStatus.lastRunAt ? lastRunStr : emptyLastRunAtStr }}</span
                                >
                            </div>
                            <mat-menu #projectStatusMenu="matMenu" xPosition="before">
                                <div fxLayout="column" class="menu-container" fxLayoutGap="10px">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i
                                            class="material-icons {{
                                                projectStatus.quarterbackStatus ? 'icon-apt' : 'icon-off'
                                            }}"
                                            >{{ projectStatus.quarterbackStatus ? "check_circle" : "cancel" }}</i
                                        >
                                        <span>Status da Coleta de Dados</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i
                                            class="material-icons {{
                                                projectStatus.engineStatus ? 'icon-apt' : 'icon-off'
                                            }}"
                                            >{{ projectStatus.engineStatus ? "check_circle" : "cancel" }}</i
                                        >
                                        <span>Status do Tratamento de Dados</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i
                                            class="material-icons {{
                                                projectStatus.workerStatus ? 'icon-apt' : 'icon-off'
                                            }}"
                                            >{{ projectStatus.workerStatus ? "check_circle" : "cancel" }}</i
                                        >
                                        <span>Status dos Cálculos</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                                        <i
                                            class="material-icons {{
                                                projectStatus.processStatus ? 'icon-apt' : 'icon-off'
                                            }}"
                                            >{{ projectStatus.processStatus ? "check_circle" : "cancel" }}</i
                                        >
                                        <span>Status do Processo</span>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <span class="description">
                {{ projectDescription }}
            </span>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
            <button
                class="customButton add-button"
                mat-raised-button
                type="button"
                (click)="setProjectDefault()"
                [disabled]="project.projectDefault"
                data-cy="set-default"
            >
                {{ project.projectDefault ? "PROJETO SELECIONADO" : "SELECIONAR PROJETO" }}
            </button>
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                <span class="project-status" *ngIf="user.permissions.canUpdate">
                    {{ projectSettings.projectOnWebapp ? "Ligado" : "Desligado" }}
                    <mat-slide-toggle
                        color="primary"
                        (change)="changeProjectStatus()"
                        checked="{{ projectSettings.projectOnWebapp }}"
                        data-cy="toggle-on"
                    ></mat-slide-toggle>
                </span>
                <button mat-icon-button (click)="editProjectSettings()" data-cy="project-settings">
                    <i class="material-icons">settings</i>
                </button>
                <button mat-icon-button (click)="deleteProject()" *ngIf="user.permissions.canDelete" data-cy="delete">
                    <i class="material-icons">delete</i>
                </button>
            </div>
        </div>
    </div>
</mat-card>
