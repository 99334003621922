import { Tag } from './tag.model';
import { Weighted } from './weighted.model';

export class WeightedEntry {
    id: string;
    entry_tag: Tag = new Tag();
    condition_tag: Tag = new Tag();
    weighted: Weighted = new Weighted();
    weight_value: number;
}
