import { Tag } from './tag.model';
import { TagValue } from './tagValue.model';

export class CycleInfo {
    id: string;
    projectId: string;
    cycleNumber: number;
    cycleStatus: string;
    cycleTimestamp: string;
    dependency: CycleTagDependency;
    cycleRuntime: string;
    mainProjectId: string;
    quarterbackStatus: string;
    engineStatus: string;
    workerStatus: string;
    processStatus: string;
    mock?: boolean;
}

export class CycleTagDependency {
    dependencies: CycleTagDependency[];
    status: string;
    tag: Tag;
    tagValue: TagValue;
}

export type CycleStatus = '';
