export class Project {
    id: string;
    name: string;
    description: string;
    area: string;
    projectDefault: boolean;

    created: Date | string;
    creator: string;
    versionType: 'RUNNING' | 'BUILDING' | 'OLD';
    defaultVersion: boolean;
    mainProjectId: string;
    version: string;
}
