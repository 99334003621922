import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { FuzzySet } from '../../models/views-models/fuzzySet.model';
import { Response } from '../../models/response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';

const API = environment.serverUrl;

@Injectable()
export class FuzzySetService {
    constructor(private httpClient: HttpClient) {}

    findAllFuzzySets(): Observable<FuzzySet[]> {
        return this.httpClient.get<Response>(`${API}fuzzy-set/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const fuzzySets: FuzzySet[] = response.data;
                return fuzzySets;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
