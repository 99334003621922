<div
    *ngIf="loadChart"
    echarts
    [options]="chartOptions"
    [merge]="chartMergeOptions"
    (chartClick)="onClickChartEvent($event)"
    (chartDblClick)="onClickChartEvent($event, true)"
    (chartLegendSelectChanged)="onClickChartLegend($event)"
    class="time-line-chart-content"
    [initOpts]="chartInitOpts"
    [autoResize]="true"
></div>
