import { IncrementSetpointComponent } from './increment-setpoint.component';
import { Routes } from '@angular/router';

export const SetpointsRoutes: Routes = [
    {
        path: '',
        component: IncrementSetpointComponent,
        data: {
            title: 'AddEditSetpoint',
        },
    },
];
