import { ProcessInputService } from './../../../service/views-services/process.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Quarterback } from 'src/app/shared/models/views-models/quarterback.model';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import PerfectScrollbar from 'perfect-scrollbar';
import { getUserData } from 'src/app/shared/utils/userRole';
import { QuarterbackService } from 'src/app/shared/service/quarterback/quarterback.service';
import { OpcServer } from 'src/app/shared/models/views-models/opcServer.model';
import { OpcServerService } from 'src/app/shared/service/views-services/opcServer.service';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
    private bodyPS: PerfectScrollbar;
    dialogRefMsg: any;
    dynamicLabel: any;

    identifiers = ['ClsID', 'ProgID'];
    user: any;
    isEditingOPCServer = false;
    opcSettingsForm: UntypedFormGroup;
    opcServers: OpcServer[];

    quarterbackForm: UntypedFormGroup;
    isEditingQuarterback = false;
    qbPort: number;
    qbHost: string;
    qbSettings = new Quarterback();
    minute = 60;
    maxCollectorTime: number;
    minCollectorTime = 1;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private opcServerService: OpcServerService,
        private quarterbackService: QuarterbackService,
        private processInputService: ProcessInputService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<AdminSettingsComponent>
    ) {}

    ngOnInit(): void {
        this.user = getUserData();
        this.initForms();
        this.setDefaultOpcValues();
        this.setDefaultQuarterbackValues();
        this.getOpcServersSettings();
        this.getQbSettings();
        this.loadScroll();
    }

    initForms(): void {
        this.opcSettingsForm = this.formBuilder.group({
            host: [null, [Validators.required, Validators.maxLength(250)]],
            key_type: [null],
            identifier: [null, Validators.maxLength(100)],
            domain: [null, Validators.maxLength(100)],
            password: [null, Validators.maxLength(100)],
            user: [null, Validators.maxLength(100)],
            name: [null, [Validators.required, Validators.maxLength(100)]],
        });
        this.opcSettingsForm.disable();
        this.quarterbackForm = this.formBuilder.group({
            collector_time: [null, [Validators.required, Validators.min(1)]],
            quarterback_host: [null, Validators.required],
            quarterback_port: [null, Validators.required],
        });
        this.quarterbackForm.disable();
    }

    getQbSettings(): void {
        this.quarterbackService.getQuarterbackSettings().subscribe((qbSettings) => {
            this.qbSettings = qbSettings;
            this.qbPort = qbSettings.port;
            this.qbHost = qbSettings.host;
            this.quarterbackForm.patchValue({
                collector_time: this.qbSettings.collectorTime,
                quarterback_host: this.qbHost,
                quarterback_port: this.qbPort,
            });
        });
    }

    async getOpcServersSettings(): Promise<void> {
        this.opcServers = await this.opcServerService.getAllOpcServers().toPromise();
        if (this.opcServers.length === 0) {
            const opcServer = new OpcServer();
            this.opcServers.push(opcServer);
            this.setDefaultOpcValues();
        }
        this.setLabelNames();
    }

    currentOpcServer(opcServer: OpcServer): void {
        if (opcServer) {
            this.opcSettingsForm.patchValue({
                domain: opcServer.domain,
                user: opcServer.username,
                password: opcServer.password,
                host: opcServer.host,
                key_type: opcServer.keyType,
                identifier: opcServer.keyType == VariableTypeEnum.CLS_ID ? opcServer.clsId : opcServer.progId,
                name: opcServer.name,
            });
        } else {
            this.setDefaultOpcValues();
        }
    }

    loadScroll() {
        if (this.bodyPS) {
            this.bodyPS.destroy();
        }
        this.bodyPS = new PerfectScrollbar('.container', {
            suppressScrollX: true,
        });
    }

    enableOpcFormEdit(): void {
        this.isEditingOPCServer = true;
        if (this.user.permissions.canUpdate) this.opcSettingsForm.enable();
    }

    disableOpcServerEdit(): void {
        this.isEditingOPCServer = false;
        this.opcSettingsForm.disable();
    }

    removeOPCServer(opcServer: OpcServer): void {
        try {
            if (this.opcServers.length === 1) {
                this.defaultDialog.data.message = 'É necessário haver pelo menos um servidor OPC';
                this.openDialog(this.defaultDialog);
            } else {
                const confirmDelete = {
                    component: DeleteDialogComponent,
                    width: 'auto',
                    height: 'auto',
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        message: '',
                    },
                };
                this.openDialog(confirmDelete);

                this.dialogRefMsg.afterClosed().subscribe((result) => {
                    // if opc server hasn't yet been created then opcServer.id is undefined
                    // so its necessary to remove it from from frontend but not from backend
                    if (result && !opcServer.id) {
                        this.opcServers.pop();
                        return;
                    } else if (!result && !opcServer.id) {
                        return;
                    }

                    const isOpcBeingUsedPromise = this.isOpcServerInUse(opcServer.id);

                    isOpcBeingUsedPromise.then((isOpcBeingUsed) => {
                        if (result && !isOpcBeingUsed) {
                            this.opcServerService.removeOpcServer(opcServer.id).subscribe();
                            this.opcServers = this.opcServers.filter((element) => element.id !== opcServer.id);
                            this.dialogMessage(MessagesEnum.DeleteMessage);
                        } else {
                            this.dialogMessage(MessagesEnum.OpcInUse);
                        }
                    });
                });
            }
        } catch (error) {
            this.dialogMessage(MessagesEnum.RemoveFailed);
        }
    }

    async isOpcServerInUse(opcServerId: string): Promise<boolean> {
        try {
            const processes = await this.processInputService.getAllProcesses().toPromise();
            const processWithOpcServer = processes.find((process) => process.opcServerId === opcServerId);

            return !!processWithOpcServer;
        } catch (error) {
            console.error('An error occurred while checking if OPC server is in use:', error);
            throw error;
        }
    }

    dialogMessage(message): void {
        let conf = {
            component: ConfirmDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        conf.data.message = message;
        this.openDialog(conf);
    }

    addOPCServerForm(): void {
        let mockOpcServer = new OpcServer();
        this.opcServers.push(mockOpcServer);
    }

    onSaveOPCServerSettings(opcServer: OpcServer): void {
        let opcServerSetting = this.opcSettingsForm.getRawValue();
        opcServerSetting.username = opcServerSetting.user;
        opcServerSetting.user = this.user.username;
        opcServerSetting.keyType = opcServerSetting.key_type;
        delete opcServerSetting.key_type;
        if (opcServerSetting.keyType == VariableTypeEnum.CLS_ID) {
            opcServerSetting.clsId = opcServerSetting.identifier;
            opcServerSetting.progId = null;
        } else {
            opcServerSetting.progId = opcServerSetting.identifier;
            opcServerSetting.clsId = null;
        }

        try {
            if (opcServer.id !== undefined) {
                opcServerSetting.id = opcServer.id;
                this.opcServerService.editOpcServer(opcServer.id, opcServerSetting).subscribe((settings) => {
                    //TODO: Implement here flag to reload quarterback.
                    this.disableOpcServerEdit();
                });
            } else {
                if(opcServerSetting.password == undefined){
                    opcServerSetting.password = "";
                }
                this.opcServerService.saveOpcServer(opcServerSetting).subscribe((settings) => {
                    //TODO: Implement here flag to reload quarterback.
                    this.disableOpcServerEdit();
                });
            }
            this.dialogMessage(MessagesEnum.SuccessMessage);
        } catch (error) {
            this.dialogMessage(MessagesEnum.invalidFormMessage);
        }
    }

    enableQuarterbackEdit(): void {
        this.isEditingQuarterback = true;
        if (this.user.permissions.canUpdate) this.quarterbackForm.enable();
    }

    disableQuarterbackEdit(): void {
        this.isEditingQuarterback = false;
        this.quarterbackForm.disable();
    }

    onSaveQuarterbackConfigs(): void {
        if (this.quarterbackForm.valid) {
            let quarterbackFormData = this.quarterbackForm.getRawValue();
            this.qbSettings.host = quarterbackFormData.quarterback_host;
            this.qbSettings.port = quarterbackFormData.quarterback_port;
            this.qbSettings.collectorTime = quarterbackFormData.collector_time;
            this.isEditingQuarterback = false;
            this.quarterbackService.saveQuarterback(this.qbSettings).subscribe(
                (settings) => {
                    //TODO: Implement here flag to reload quarterback.
                    this.qbSettings = settings;
                    this.disableQuarterbackEdit();
                },
                (error) => {
                    let conf = {
                        component: ConfirmDialogComponent,
                        width: 'auto',
                        height: 'auto',
                        panelClass: 'pop-up-dialog-container',
                        data: {
                            message: '',
                        },
                    };
                    conf.data.message = MessagesEnum.ErroMessage;
                    this.quarterbackForm.get('collector_time').setValue(this.qbSettings.collectorTime);
                    this.quarterbackForm.get('quarterback_host').setValue(this.qbHost);
                    this.quarterbackForm.get('quarterback_port').setValue(this.qbPort);
                    this.openDialog(conf);
                }
            );
        }
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        if (options.data.message == MessagesEnum.SuccessMessage) {
            this.dialogRefMsg.afterClosed().subscribe((result) => {
                this.close();
            });
        }
    }

    setLabelNames(): void {
        let formData = this.opcSettingsForm.getRawValue();
        this.dynamicLabel = formData.key_type ? formData.key_type : 'ClsID/ProgID';
    }

    clearIdentifier(): void {
        this.opcSettingsForm.patchValue({ identifier: '' });
        this.setLabelNames();
    }

    setDefaultOpcValues(): void {
        this.opcSettingsForm.reset();
        this.opcSettingsForm.patchValue({
            name: 'Servidor OPC 1',
            host: 'localhost',
            key_type: VariableTypeEnum.CLS_ID,
        });
    }

    setDefaultQuarterbackValues(): void {
        this.quarterbackForm.patchValue({
            quarterback_host: 'localhost',
            quarterback_port: 8081,
            collector_time: 5,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
