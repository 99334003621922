import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
    deleteMessage = MessagesEnum.defaultDeleteMessage;
    deleteTitle: string;
    manyDeleteMessage = MessagesEnum.manyDeleteMessage;

    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data.message) {
            this.deleteMessage = this.data.message;
        } else if (this.data.singleDelete) {
            this.deleteMessage = this.data.singleDelete ? this.deleteMessage : this.manyDeleteMessage;
        }
        if (this.data.title) {
            this.deleteTitle = this.data.title;
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }
}
