import { IncrementSetpoint } from './incrementSetpoint.model';
import { SpConditionValue } from './spConditionValue.model';

export class SetpointCalculation {
    incrementSetpoint: IncrementSetpoint;
    increment: any;
    minLimit: any;
    maxLimit: any;
    timestamp: string;

    currentValue: number;
    newValue: number;
    fuzzyValue: number;
    projectCycle: number;
    currentCycle: number;
    cycles: number;

    factor: string;
    equation: string;

    conditionsActive: boolean;
    conditionsValid: boolean;
    setpointOn: boolean;
    writeCycle: boolean;
    isSync: boolean;
    projectApt: boolean;
    projectOn: boolean;

    conditionValues: SpConditionValue[];
}
