import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Resp, Response } from '../../models/response.model';
import { Equation } from '../../models/views-models/equation.model';
import { Tag } from '../../models/views-models/tag.model';

const API = environment.serverUrl;

@Injectable()
export class EquationService {
    sufix: string;
    constructor(private httpClient: HttpClient) {
        this.sufix = 'equations';
    }

    getAll(): Observable<Equation[]> {
        return this.httpClient.get<Response>(`${API}${this.sufix}`).pipe(
            map((response: Response) => {
                const equations: Equation[] = response.data.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
                return equations;
            })
        );
    }

    get(id: string): Observable<Equation> {
        return this.httpClient.get<Response>(`${API}${this.sufix}/${id}`,).pipe(
            map((response: Response) => {
                const equation: Equation = response.data;
                return equation;
            })
        );
    }

    add(equation: Equation): Resp<Equation> {
        return this.httpClient.post<Response>(`${API}${this.sufix}`, equation,).pipe(
            map((response: Response) => {
                const equation: Equation = response.data;
                const error: string = response.error;
                return { error: error, data: equation };
            })
        );
    }

    update(equation: Equation): Resp<Equation> {
        return this.httpClient
            .put<Response>(`${API}${this.sufix}/${equation.id}`, equation, )
            .pipe(
                map((response: Response) => {
                    const equation: Equation = response.data;
                    const error: string = response.error;
                    return { error: error, data: equation };
                })
            );
    }

    delete(id: string): Resp<Array<Tag>> {
        return this.httpClient.delete<Response>(`${API}${this.sufix}/${id}`, );
    }

}
