import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { RolesEnum } from 'src/app/shared/models/enum/roles.enum';
import { User } from 'src/app/shared/models/views-models/user.model';
import { UserService } from 'src/app/shared/service/views-services/user.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
    permissions = RolesEnum.roles;
    userForm: UntypedFormGroup;
    currentUser: any;
    dialogRefMsg: any;
    isEditing = false;
    passwordUpdate = false;
    validPassword: boolean;
    user: User = new User();
    setPassword: boolean;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
            cancelAvailable: false,
        },
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.currentUser = getUserData();
        this.loadData();
        this.initForm();
    }

    loadData() {
        this.filterPermissions();
    }

    initForm() {
        this.userForm = this.formBuilder.group({
            name: [null, [Validators.required, Validators.minLength(3)]],
            company: [null, Validators.required],
            sector: [null],
            registry: [null],
            email: [null, [Validators.required, Validators.email]],
            username: [null, [Validators.required, Validators.minLength(3)]],
            password: [null, [Validators.required, Validators.minLength(6)]],
            matchPassword: [null, [Validators.required, Validators.minLength(6)]],
            role: [null, Validators.required],
        });
        if (this.data) {
            this.user = this.data;
            this.isEditing = true;
            this.userForm.patchValue({
                name: this.user.name,
                company: this.user.company,
                sector: this.user.sector,
                registry: this.user.registry,
                email: this.user.email,
                username: this.user.username,
                password: 'password',
                matchPassword: 'password',
                role: this.user.role.id,
            });
            this.userForm.controls['matchPassword'].disable();
            this.userForm.controls['username'].disable();

            let isToDisableEmail = this.user.origin == 'LDAP';
            if (isToDisableEmail) {
                this.userForm.controls['name'].disable();
                this.userForm.controls['email'].disable();
            }

            let isToDisableRoles =
                this.user.username == this.currentUser.username ||
                (this.currentUser.permissions.ldapAuthorization && this.user.origin == 'LDAP');
            if (isToDisableRoles) {
                this.userForm.controls['role'].disable();
            }

            if (this.currentUser.isSuperUser) {
                this.userForm.controls['username'].enable();
            }
            this.userForm.get('password').valueChanges.subscribe((newPassword) => {
                if (newPassword) {
                    this.passwordUpdate = true;
                    this.userForm.patchValue({ matchPassword: '' });
                    this.userForm.controls['matchPassword'].enable();
                }
            });
        } else {
            this.passwordUpdate = true;
        }
        this.setPassword = !this.data || this.user.origin == 'NATIVE';
    }

    filterPermissions() {
        this.permissions = this.currentUser.isSuperUser
            ? this.permissions
            : this.permissions.filter((permission) => permission.role != RolesEnum.ROLE_OWNER);
        this.permissions = this.currentUser.permissions.canCreate
            ? this.permissions
            : this.permissions.filter((permission) => permission.role != RolesEnum.ROLE_ADMIN);
    }

    onSubmit() {
        this.validPassword = true;
        this.defaultDialog.data.cancelAvailable = false;
        if (this.userForm.valid && this.validatePassword()) {
            let formData = this.userForm.getRawValue();
            this.setObjectData(formData);
            if (this.isEditing && this.passwordUpdate) {
                this.defaultDialog.data.cancelAvailable = true;
                this.defaultDialog.data.message = MessagesEnum.passwordChangeConfirm;
                this.openDialog(this.defaultDialog);
                this.dialogRefMsg.afterClosed().subscribe((confirm) => {
                    if (confirm) {
                        this.saveUser();
                    }
                });
            } else {
                this.saveUser();
            }
        } else {
            if (!this.userForm.controls['password'].value || !this.validPassword) {
                this.userForm.controls['password'].setErrors({ incorrect: true });
                this.userForm.controls['matchPassword'].setErrors({ incorrect: true });
            }
            if (!this.validPassword) {
                this.defaultDialog.data.message = MessagesEnum.passwordMatchFail;
            } else {
                this.defaultDialog.data.message = MessagesEnum.invalidFormMessage;
                if (this.userForm.controls['password'].invalid) {
                    this.defaultDialog.data.message += '. A senha deve conter no mínimo 6 caraceteres.';
                }
            }
            this.openDialog(this.defaultDialog);
        }
    }

    saveUser() {
        this.defaultDialog.data.cancelAvailable = false;
        if (!this.isEditing) {
            this.userService.newUser(this.user).subscribe(() => {
                this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
                this.openDialog(this.defaultDialog);
            });
        } else {
            this.userService.updateUser(this.user).subscribe(() => {
                this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
                this.openDialog(this.defaultDialog);
            });
        }
    }

    validatePassword() {
        let formData = this.userForm.getRawValue();
        let password = formData.password;
        let matchPassword = formData.matchPassword;
        if (password == matchPassword && this.userForm.controls['password'].valid) {
            return true;
        } else {
            this.validPassword = false;
            return false;
        }
    }

    setObjectData(form) {
        this.user.name = form.name;
        this.user.username = form.username;
        this.user.password = this.passwordUpdate ? form.password : this.user.password;
        this.user.email = form.email;
        this.user.company = form.company;
        this.user.sector = form.sector;
        this.user.registry = form.registry;
        this.user.role.id = form.role;
    }

    clearPasswordField() {
        this.userForm.patchValue({ password: '' });
    }

    deleteUser() {
        let confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        this.openDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((response) => {
            if (response) {
                this.userService.deleteUser(this.user.username).subscribe(() => {
                    this.user = new User();
                    this.defaultDialog.data.message = MessagesEnum.DeleteMessage;
                    this.openDialog(this.defaultDialog);
                });
            }
        });
    }

    openDialog(options: any): void {
        let data = options.data;
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (data.message == MessagesEnum.SuccessMessage || data.message == MessagesEnum.DeleteMessage) {
                if (this.currentUser && this.user && this.currentUser.username == this.user.username) {
                    this.updateLocalStorage();
                }
                this.dialogRef.close(this.user);
            }
        });
    }

    updateLocalStorage() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.name = this.user.name;
        currentUser.role = this.permissions.find((permission) => permission.id == this.user.role.id).role;
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        window.location.reload();
    }

    close() {
        this.dialogRef.close();
    }
}
