import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { filter, lastValueFrom } from 'rxjs';
import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { DatetimeInterval } from 'src/app/shared/models/views-models/dateInterval.model';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { SetpointCalculation } from 'src/app/shared/models/views-models/setpointCalculation.model';
import { OpenPanelInfo } from 'src/app/shared/models/views-models/sidepanelInfo.model';
import { SPFormula } from 'src/app/shared/models/views-models/spFormula.model';
import { ProcessOutputService } from 'src/app/shared/service/views-services/processOutput.service';
import { SetPointService } from 'src/app/shared/service/views-services/setpoint.service';
import {
    AxisConfig,
    ChartClickEvent,
    CycleOutput,
    CycleOutputType,
    getCycleOutputTagName,
    getCycleOutputType,
    getOutputTag,
} from '../setpoint-models/axis.config';
import { SetpointsTrackingSettingsComponent } from '../setpoint-tracking-settings/setpoints-tracking-settings.component';
import { SetpointValuesService } from '../setpoint-values.service';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { ProjectSettings } from 'src/app/shared/models/views-models/projectSettings.model';
import { re } from 'mathjs';

@Component({
    templateUrl: './setpoints-tracking.component.html',
    styleUrls: ['./setpoints-tracking.component.scss'],
})
export class SetpointsTrackingComponent implements OnInit {
    /* Starts Here*/
    cycleTime: number;
    tagNames: string[];
    redirectedTag: string;
    navigatorPlaceholder = 'Setpoint';
    outputList: CycleOutput[];
    axisConfig: AxisConfig;
    calculationLog: SetpointCalculation;
    dialogRef: MatDialogRef<any>;
    openPanel: OpenPanelInfo;
    isDrawerOpen: boolean;
    dateInterval: DatetimeInterval;

    constructor(
        public dialog: MatDialog,
        private activateRouter: ActivatedRoute,
        private setpointService: SetPointService,
        private outputService: ProcessOutputService,
        private setpointValueService: SetpointValuesService,
        private projectService: ProjectService
    ) {}

    ngOnInit() {
        this.loadData();
        this.setProjectCycleTime();
    }

    async loadData() {
        const setpoints = await lastValueFrom(this.setpointService.getAllIncrementSetPoints());
        const outputs = await lastValueFrom(this.outputService.getAllOutputProcesses());
        this.outputList = [...setpoints, ...outputs];
        this.tagNames = this.outputList.map((o) => getCycleOutputTagName(o));
        const output = this.outputList.find((o) => o.id == this.activateRouter.snapshot?.fragment);
        if (output) this.redirectedTag = getCycleOutputTagName(output);
        this.axisConfig = new AxisConfig(output);
        this.setpointValueService.$observeValuesLoaded.pipe(filter((t) => t)).subscribe((_) => {
            let cycleInfo = this.setpointValueService.getLastSerieValue<CycleInfo>(CycleInfo);
            this.openPanel = {
                timestamp: new Date(cycleInfo?.cycleTimestamp),
                autoOpen: false,
                cycleInfo: cycleInfo,
                name: this.redirectedTag,
                drawer: null,
                output: this.axisConfig.output,
            };
        });
    }

    updateChartInfo(dateInterval: DatetimeInterval) {
        if (dateInterval.validDateInterval) {
            this.dateInterval = dateInterval;
            const output = this.outputList.find((o) => getCycleOutputTagName(o) == dateInterval.tagName);
            if (getCycleOutputTagName(output) != getCycleOutputTagName(this.axisConfig.output)) {
                this.axisConfig = new AxisConfig(output);
            }
            this.setProjectCycleTime();
            this.redirectedTag = dateInterval.tagName;
        }
    }

    async setProjectCycleTime() {
        const projectId = this._getProjectId();
        if (!projectId) return;
        const project = await lastValueFrom(this.projectService.getProjectSettings(projectId));
        this.cycleTime = project.cycleTime;
    }

    private _getProjectId() {
        const output = this.axisConfig?.output;
        if (!output) return undefined;
        const { new_sp_tag } = output as IncrementSetpoint;
        if (new_sp_tag?.project?.id) {
            return new_sp_tag.project.id;
        }
        const { projectId } = output as OutputProcess;
        return projectId;
    }

    openSettings() {
        this.dialogRef = this.dialog.open(SetpointsTrackingSettingsComponent, {
            panelClass: 'setpoint-settings-dialog-container',
            width: '70%',
            height: 'auto',
            maxWidth: '800px',
            data: this.axisConfig,
        });

        this.dialogRef
            .afterClosed()
            .pipe(filter((axis) => Boolean(axis)))
            .subscribe((axis: AxisConfig) => (this.axisConfig = axis));
    }

    isSetpointTag(): boolean {
        return getCycleOutputType(this?.axisConfig?.output) == 'Setpoint';
    }

    updateSelectedCycle($event: ChartClickEvent) {
        this.openPanel = {
            autoOpen: $event.isDoubleClick,
            timestamp: new Date($event?.cycleInfo?.cycleTimestamp),
            cycleInfo: $event?.cycleInfo,
            name: $event?.cycleInfo?.dependency?.tag?.name,
            drawer: null,
            output: this.axisConfig.output,
        };
    }

    /**Tooltip*/
    getConditionsDescriptionTooltip(conditionsList) {
        let conditions = '';
        for (let condition of conditionsList) {
            let value = condition.equation_value ? condition.equation_value : condition.equation_value_tag.name;
            conditions += `${condition.name}: ${condition.tag.name} ${condition.equation_operator} ${value}\n`;
        }
        return conditions;
    }

    getIncDecTooltip(formula: SPFormula, incDec: 'Incremento' | 'Decremento') {
        if (!formula) {
            return `\nCondições de ${incDec} não cadastradas`;
        }
        if (!formula.activate) {
            return `\nCondições de ${incDec} desativadas`;
        }
        let incrementConditions = this.getConditionsDescriptionTooltip(formula.formula_conditions);
        return `\nCondições de ${incDec}\n${incrementConditions}`;
    }

    getSetpointConditionsTooltip() {
        const setpoint = this.axisConfig.output as IncrementSetpoint;
        return `${this.getIncDecTooltip(setpoint.inc_formula, 'Incremento')}\n${this.getIncDecTooltip(
            setpoint.dec_formula,
            'Decremento'
        )}`;
    }

    get cycleDuration() {
        return this.cycleTime * this.numberOfCycles;
    }

    get numberOfCycles() {
        const setpoint = this.axisConfig?.output as IncrementSetpoint;
        return setpoint?.cycles || 1;
    }

    get isSetpoint() {
        return this.setpointValueService.getCycleOutputType() === CycleOutputType.SETPOINT;
    }
}
