export enum CycleStatusEnum {
    bad = 'bad',
    successful = 'successful',
    old = 'old',
    reading_error = 'reading_error',
    division_zero = 'division_zero',
    syntax_error = '=syntax_error',
    invalid_data = 'invalid_data',
    not_valid = 'not_valid',
    unhandled_error = 'unhandled_error',
    limits_error = 'limits_error',
    error_in_dependencies = 'error_in_dependencies',
    unexpected_value = 'unexpected_value',
    invalid_conditions = 'invalid_conditions',
    disabled = 'disabled',
    calculated = 'calculated',
    not_calculated = 'not_calculated',
    waiting = 'waiting',
    cycle_timeout = 'cycle_timeout',
    worker_off = 'worker_off',
    engine_off = 'engine_off',
    quarterback_off = 'quarterback_off',
    scheduler_off = 'scheduler_off',
    process_inapt = 'process_inapt',
    not_written = 'not_written',
    unknown = 'unknown',
    not_existed = 'not_existed',
}

export enum CycleStatusFormattedEnum {
    bad = 'Qualidade ruim',
    successful = 'Sucesso',
    old = 'Antiga',
    reading_error = 'Erro de leitura',
    division_zero = 'Divisão por zero',
    syntax_error = 'Erro de sintaxe',
    invalid_data = 'Valor inválido',
    not_valid = 'Inválido',
    unhandled_error = 'Erro inesperado',
    limits_error = 'Limite inferior maior que o limite superior',
    error_in_dependencies = 'Erro nas dependências',
    unexpected_value = 'Valor inesperado',
    invalid_conditions = 'Condições inválidas',
    disabled = 'Desabilitada',
    calculated = 'Calculado',
    not_calculated = 'Não calculada',
    waiting = 'Aguardando',
    cycle_timeout = 'Tempo de ciclo excedido',
    worker_off = 'Worker desligado',
    engine_off = 'Engine desligado',
    quarterback_off = 'Quarterback desligado',
    scheduler_off = 'Scheduler desligado',
    process_inapt = 'Processo inapto',
    not_written = 'Não calculada',
    unknown = 'Desconhecido',
    not_existed = 'Não cadastrada',
}
