import { Component } from '@angular/core';

@Component({
    selector: 'app-process-inputs-list',
    templateUrl: './process-inputs-list.component.html',
    styleUrls: ['./process-inputs-list.component.scss'],
})
export class ProcessInputsListComponent {
    constructor() {}
}
