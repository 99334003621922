import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { WeightedEntry } from '../../models/views-models/weightedEntry.model';

const API = environment.serverUrl;

@Injectable()
export class WeightedEntryService {
    constructor(private httpClient: HttpClient) {}

    getWeightedEntriesByWeightedId(weightedId: String): Observable<WeightedEntry[]> {
        return this.httpClient
            .get<Response>(`${API}weighted-entry/weighted/${weightedId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const weightedsEntries: WeightedEntry[] = response.data;
                    return weightedsEntries;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
