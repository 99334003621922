import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { ProcessType } from '../../models/views-models/processType.model';

const API = environment.serverUrl;

@Injectable()
export class ProcessTypeService {
    constructor(private httpClient: HttpClient) {}

    getAllProcessTypes(): Observable<ProcessType[]> {
        return this.httpClient.get<Response>(`${API}process-type/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const processTypes: ProcessType[] = response.data;
                return processTypes;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
