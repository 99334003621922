import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Response } from '../../shared/models/response.model';
import { Constants } from 'src/app/shared/utils/constants';
import { environment } from 'src/environments/environment';

const API = environment.serverUrl;

@Injectable()
export class LoginService {
    constructor(private httpClient: HttpClient) {}

    isUserLogged(): boolean {
        return localStorage.getItem('currentUser') ? true : false;
    }

    refreshToken(username: string, token: string): Observable<any> {
        return this.httpClient
            .post<Response>(`${API}auth/refresh-token`, JSON.stringify({ username: username, token: token }), {
                headers: this.getHeaders(),
            })
            .pipe(
                map((response: any) => {
                    const user = response;
                    if (user && user.token) {
                    }
                    return user;
                })
            );
    }

    login(usuario: string, senha: string): Observable<any> {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
        });
        let loginUrl = `${Constants.URL}auth/signin`;

        return this.httpClient
            .post<Response>(loginUrl, JSON.stringify({ username: usuario, password: senha }), { headers: headers })
            .pipe(
                map((response: any) => {
                    const user = response;
                    if (user && user.token) {
                    }
                    return user;
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
        });
        return headers;
    }
}
