export class Views {
    public static views = [
        {
            name: 'Projetos',
            url: '/projetos',
            icon: 'layers',
        },
        {
            name: 'Entradas',
            url: '/entradas',
            icon: 'input_circle',
            source: 'material-symbols-outlined',
        },
        {
            name: 'Saídas',
            url: '/saidas',
            icon: 'output_circle',
            source: 'material-symbols-outlined',
        },
        {
            name: 'Tratamento de Dados',
            url: '/tratamento-de-dados',
            icon: 'timeline',
        },
        {
            name: 'Regras e Ponderadas',
            url: '/regras-e-ponderadas',
            icon: 'grid_on',
        },
        {
            name: 'Acompanhamento de Regras',
            url: '/acompanhamento-de-regras',
            icon: 'bar_chart',
        },
        {
            name: 'Setpoints',
            url: '/setpoints',
            icon: 'toggle_on',
        },
        {
            name: 'Acompanhamento de Saídas',
            url: '/acompanhamento-de-setpoints',
            icon: 'trending_up',
        },

        {
            name: 'Variáveis Calculadas',
            url: '/variaveis-calculadas',
            icon: 'function',
            source: 'material-symbols-outlined',
        },
    ];

    public static controlRules = {
        name: 'Regras e Ponderadas',
        menuName: 'Regras e Ponderadas',
        url: 'regras-e-ponderadas',
    };

    public static saveControlRules = {
        name: 'Regras',
        menuName: 'Regras',
        url: 'salvar-regras',
    };

    public static projects = {
        name: 'Seleção de Projetos',
        menuName: 'Projetos',
        url: 'projetos',
    };

    public static setpointsList = {
        name: 'Setpoints',
        menuName: 'Setpoints',
        url: 'setpoints',
    };

    public static saveSetpoint = {
        name: 'Setpoints',
        menuName: 'Setpoints',
        url: 'salvar-setpoint',
    };

    public static processInputsList = {
        name: 'Entradas',
        menuName: 'Entradas',
        url: 'entradas',
    };

    public static processOutputsList = {
        name: 'Saídas',
        menuName: 'Saídas',
        url: 'saidas',
    };

    public static dataTreatment = {
        name: 'Tratamento de Dados',
        menuName: 'Tratamento de Dados',
        url: 'tratamento-de-dados',
    };

    public static rulesTracking = {
        name: 'Acompanhamento de Regras',
        menuName: 'Acompanhamento de Regras',
        url: 'acompanhamento-de-regras',
    };

    public static setpointsTracking = {
        name: 'Acompanhamento de Saídas',
        menuName: 'Acompanhamento de Saídas',
        url: 'acompanhamento-de-setpoints',
    };

    public static usersList = {
        name: 'Usuários',
        menuName: 'Usuários',
        url: 'usuarios',
    };

    public static equationList = {
        name: 'Variáveis Calculadas',
        menuName: 'Variáveis Calculadas',
        url: 'variaveis-calculadas',
    };

    public static homePage = 'entradas';
}
