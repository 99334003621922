export class SystemConsts {
    public static FUZZY_SET_THREE_OPTIONS = {
        id: '4d2de936-3e21-11eb-b378-0242ac130002',
        description: '3 Funções de Pertinência',
    };

    public static FUZZY_SET_FIVE_OPTIONS = {
        id: '564c638a-3e21-11eb-b378-0242ac130002',
        description: '5 Funções de Pertinência',
    };
}
