import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { Quarterback } from '../../models/views-models/quarterback.model';
import { SystemPar } from '../../models/views-models/systemPar.model';

const API = environment.serverUrl;

@Injectable()
export class SystemParService {
    constructor(private httpClient: HttpClient) {}

    getCollectorTime(): Observable<SystemPar> {
        return this.httpClient.get<Response>(`${API}system/collector-time`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const colectorTime: SystemPar = response.data;
                return colectorTime;
            })
        );
    }

    getQuarterbackSettings(): Observable<Quarterback> {
        return this.httpClient.get<Response>(`${API}system/quarterback-settings`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const quarterback: Quarterback = response.data;
                return quarterback;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }
}
