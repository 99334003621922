<div class="container">
    <ihm-card-header icon="functions" [title]="(isEditing ? 'Editar' : 'Adicionar') + ' Variável Calculada'">
    </ihm-card-header>

    <div class="containerCard">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <span class="label"> Identificação da variável</span>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxFlex="100">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome </mat-label>
                        <input
                            matInput
                            placeholder="Nome da Variável"
                            type="text"
                            formControlName="name"
                            autocomplete="off"
                            data-cy="name"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxFlex="100">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input
                            matInput
                            placeholder="Descrição"
                            type="text"
                            formControlName="description"
                            autocomplete="off"
                            data-cy="description"
                        />
                    </mat-form-field>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div class="containerCard" fxLayout="row" fxLayoutAlign="start center">
                <div class="left-text-area">
                    <span class="label"> Equação da variável</span>
                    <i
                        class="material-icons info-header"
                        matTooltip="a=5+8
                                b=10*6
                                c=a+b

                    "
                        matTooltipPosition="after"
                        >info</i
                    >

                    <i
                        class="material-icons info-header"
                        (click)="prettier = !prettier"
                        matTooltip="Code"
                        matTooltipPosition="after"
                        color="#1976d2"
                        >code</i
                    >
                </div>
                <!-- <div class="textAreaButton">
                    <a mat-button (click)="validadeEquation()">
                        <span class="material-icons"> {{ getIconCheckEquation() }} </span>Verificar Equação</a
                    >
                </div> -->
            </div>
            <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="0px">
                <div class="subCard" fxFlex="100">
                    <mat-form-field appearance="outline" *ngIf="currentProjectService.isBuildingMode()">
                        <mat-label>Equação</mat-label>
                        <textarea
                            #textarea
                            class="md-textarea height-50"
                            matInput
                            placeholder="Equação"
                            formControlName="equation"

                            (click)="getCursor($event.target)"
                            (keyup)="getCursor($event.target)"
                            autocomplete="off"
                            spellcheck="false"
                            rows="6"
                            [mention]="tagOptions"
                            [mentionConfig]="{ triggerChar: '#', maxItems: 8, labelKey: 'name' }"
                            (itemSelected)="selectTag($event)"
                            (closed)="updateTag()"
                        ></textarea>
                        <label
                            *ngIf="
                                formGroup.controls['equation'].hasError('equation') &&
                                formGroup.controls['equation'].touched
                            "
                            class="form-error-msg"
                        >
                            {{ formGroup.controls["equation"].getError("equation") }}
                        </label>
                    </mat-form-field>
                    <pre *ngIf="prettier" class="code" fxFlex="40%" prism [update]="displayEquation"></pre>
                </div>
            </div>

            <div
                class="fuzzy-inputs"
                fxLayout="row"
                fxLayout.xs="column"
                fxLayout.sm="row"
                fxLayoutAlign="start center"
                fxLayoutGap="15px"
            >
                <div fxFlex fxLayoutAlign="start center" class="entry-tag" fxFlex="50">
                    <mat-form-field appearance="outline" class="data-select no-padding">
                        <mat-label>Tag</mat-label>
                        <input type="text" matInput list="tagList" autocomplete="off" formControlName="tagValue" />
                    </mat-form-field>
                    <datalist id="tagList">
                        <option *ngFor="let tag of tagOptions" [value]="tag.name"></option>
                    </datalist>
                </div>
                <div *ngIf="user.permissions.canCreate" fxFlex="50" fxLayoutAlign="start center">
                    <button
                        mat-button
                        class="addTagButton"
                        type="button"
                        (click)="addEquation()"
                        [disabled]="!user.permissions.canUpdate || currentProjectService.isRunningMode()"
                        data-cy="add-entry"
                    >
                        <i class="material-icons"> add_circle </i> Adicionar tag
                    </button>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxFlex>
                    <button
                        mat-button
                        class="whiteButton"
                        [disabled]="!isEditing"
                        *ngIf="user.permissions.canDelete && currentProjectService.isBuildingMode()"
                        type="button"
                        (click)="delete()"
                        data-cy="delete"
                    >
                        <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR EQUAÇÃO
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                <button mat-button class="blueButton" type="submit" *ngIf="user.permissions.canUpdate && currentProjectService.isBuildingMode()" data-cy="save">
                    SALVAR
                </button>
            </div>
        </form>
    </div>
</div>
