import { Component, Input } from '@angular/core';
import { SetpointValuesService } from '../setpoint-values.service';
import { OpenPanelInfo } from 'src/app/shared/models/views-models/sidepanelInfo.model';
import { CycleInfo, CycleTagDependency } from 'src/app/shared/models/views-models/cycleInfo';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { e, string } from 'mathjs';
import { CycleStatusEnum, CycleStatusFormattedEnum } from 'src/app/shared/models/enum/cycleStatus.enum';
import { getCycleOutputTagId } from '../setpoint-models/axis.config';
import { Tag } from 'angular-feather/icons';

@Component({
    selector: 'app-cycle-information-tree',
    templateUrl: './cycle-information-tree.component.html',
    styleUrls: ['./cycle-information-tree.component.scss'],
})
export class CycleInformationTreeComponent {
    treeControl = new NestedTreeControl<CycleTagDependency>((node) => node.dependencies);
    dataSource = new MatTreeNestedDataSource<CycleTagDependency>();
    cycleStatus: string;
    _cycleInfo: CycleInfo;
    _outputStatus: string;

    public _panelConfig: OpenPanelInfo;

    @Input() set panelConfig(value: OpenPanelInfo) {
        // const isToUpdateInfo = value && this._panelConfig?.timestamp != value?.timestamp;
        this._panelConfig = value;
        this._cycleInfo = this._panelConfig.cycleInfo;
        this.loadPanelInfo();
    }

    constructor(public setpointvalueService: SetpointValuesService) {}

    async loadPanelInfo() {
        if (this._panelConfig?.cycleInfo?.dependency?.dependencies) {
            this._setCycleInfoTree(this._panelConfig?.cycleInfo);
            return;
        }

        if (!this._cycleInfo?.id) return;
        let currentCycleInfo = await this.fetchDependencyTree();
        this._setCycleInfoTree(currentCycleInfo);
    }

    private _setCycleInfoTree(cycleInfo: CycleInfo): void {
        this.cycleStatus = cycleInfo?.cycleStatus;
        this.dataSource.data = [cycleInfo?.dependency];
    }

    private async fetchDependencyTree(): Promise<CycleInfo> {
        let outputTagId = this._cycleInfo?.dependency?.tag?.id ?? getCycleOutputTagId(this._panelConfig.output, true);
        if (outputTagId) return this.setpointvalueService.getDependencyTree(outputTagId, this._cycleInfo?.id);
    }

    getIconByDependency(cycleDependency: CycleTagDependency) {
        if (this.isNullOrUndefined(cycleDependency?.status)) return { color: 'black-icon', icon: 'not_interested' };

        if (cycleDependency?.status == CycleStatusEnum.disabled) return { color: 'black-icon', icon: 'stop_circle' };

        if (cycleDependency?.status == CycleStatusEnum.not_written) return { color: 'grey-icon', icon: 'check_circle' };

        if (cycleDependency?.status != CycleStatusEnum.successful || !this._isCycleCalculated()) {
            return { color: 'red-icon', icon: 'cancel' };
        }

        return { color: 'green-icon', icon: 'check_circle' };
    }

    hasChild = (_: number, node: CycleTagDependency) => !!node?.dependencies && node?.dependencies.length > 0;

    formatStatus(tagStatus: string): string {
        this._setOutputTagStatus(tagStatus);
        if (tagStatus === CycleStatusEnum.disabled) return CycleStatusFormattedEnum[tagStatus];
        if (!this._isCycleCalculated()) return CycleStatusFormattedEnum[this.cycleStatus];
        if (this.isNullOrUndefined(tagStatus)) return CycleStatusFormattedEnum.not_existed;
        return CycleStatusFormattedEnum[tagStatus] || CycleStatusFormattedEnum.unknown;
    }

    formatDecimalPlaces(value: string): string {
        if (!this.shouldDispayValue) return;
        if (isNaN(Number(value))) return this._capitalizeFirstLetter(value);
        const formattedValue = Number(value).toFixed(5);
        return formattedValue;
    }

    get shouldDispayValue() {
        return this._isCycleCalculated() && this._isOutputCalculated();
    }

    private _capitalizeFirstLetter(value: string): string {
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }

    private _isCycleCalculated(): boolean {
        return this.cycleStatus === CycleStatusEnum.calculated;
    }

    private _setOutputTagStatus(status: string) {
        this._outputStatus = status;
    }

    private _isOutputCalculated(): boolean {
        return this._outputStatus === CycleStatusEnum.successful;
    }

    private isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }
}
