import { catchError } from 'rxjs/operators';
import { UserService } from 'src/app/shared/service/views-services/user.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Observable, of, map } from 'rxjs';
import { CurrentProjectService } from '../views-services/current-project.service';
import { Views } from '../../utils/views-urls';
import { User } from '../../models/views-models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, public currentProjectService: CurrentProjectService, public userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.verifyPermission();
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        return this.verifyPermission();
    }

    verifyPermission(): Observable<boolean> {
        if (!localStorage.getItem('currentUser')) {
            this.router.navigate(['/login']);
            return of(false);
        }

        return this.userService.getCurrentUser().pipe(catchError(error => {
            this.router.navigate(['/login']);
            return of(false);

        }
    ), map(user => true));

    }


}
