<form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <ihm-card-header data-cy="title" icon="layers" [title]="(isEditing ? 'Editar' : 'Novo') + ' Projeto'">
    </ihm-card-header>
    <div class="container body" fxLayout="column">
        <div class="sub-title">Dados do Projeto</div>
        <mat-form-field appearance="outline">
            <mat-label>Nome do Projeto*</mat-label>
            <input matInput type="text" formControlName="name" data-cy="name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Descrição</mat-label>
            <input matInput type="text" formControlName="description" data-cy="description" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Área</mat-label>
            <input matInput type="text" formControlName="area" data-cy="area" />
        </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px" class="buttons">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <button
                *ngIf="!projectDefault; else defaultProjectWarning"
                mat-button
                class="whiteButton"
                type="button"
                (click)="setDefaultProject()"
            >
                <i class="material-icons">star</i>
                <span> &nbsp;TORNAR PROJETO PADRÃO </span>
            </button>
            <ng-template #defaultProjectWarning>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="default-project">
                    <span> ESTE É O SEU PROJETO PADRÃO </span>
                    <i
                        class="material-icons info"
                        matTooltip="O projeto padrão é aquele que é usado como referência nas demais telas do sistema"
                        >info_outline</i
                    >
                </div>
            </ng-template>
        </div>
        <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
        <button mat-button class="blueButton" type="submit" data-cy="save">SALVAR</button>
    </div>
</form>
