import { Audity } from './audity.model';
import { Tag } from './tag.model';

export class TagValue {
    id: string;
    tag: Tag;
    value: number | string;
    timestamp: string;
    quality: string;
    audity: Audity = new Audity();
}
