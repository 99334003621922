import { IncrementSetpoint } from './incrementSetpoint.model';

export class SpConditionValue {
    incrementSetpoint: IncrementSetpoint;
    name: string;
    tagName: string;
    operator: string;
    value: string;
    valid: boolean;
    timestamp: string;
}
