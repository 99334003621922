import { FuzzyAndWeightedRoutes } from './fuzzy-and-weighted.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { FuzzyAndWeightedComponent } from './fuzzy-and-weighted.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [FuzzyAndWeightedComponent],
    imports: [CommonModule, SharedModule, FlexLayoutModule, RouterModule.forChild(FuzzyAndWeightedRoutes)],
})
export class FuzzyAndWeightedModule {}
