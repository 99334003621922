<mat-card appearance="outlined">
    <mat-card-header>
        <div class="title-home">
            <mat-card-title>Lista de Setpoints</mat-card-title>
            <button
                class="customButton add-button"
                mat-raised-button
                type="button"
                (click)="add()"
                *ngIf="user.permissions.canCreate && currentProjectService.isBuildingMode()"
                data-cy="add"
            >
                ADICIONAR
            </button>
        </div>
    </mat-card-header>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <mat-form-field appearance="outline">
            <mat-label>Buscar Setpoints</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" />
            <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="start center" class="options">
            <span>Filtrar por:</span>
            <div fxLayout="column">
                <span>Ciclos:</span>
                <div fxLayout="row" fxLayoutGap="5px">
                    <button
                        [ngClass]="showAllCycles ? 'mini-toggle' : 'disabled-mini-toggle'"
                        type="button"
                        (click)="changeAllCycles()"
                    >
                        Todos
                    </button>
                    <button
                        *ngFor="let cycle of cycleOptions"
                        [ngClass]="
                            activeCycles[cycleOptions.indexOf(cycle)].active ? 'mini-toggle' : 'disabled-mini-toggle'
                        "
                        type="button"
                        (click)="enableCycle(cycle)"
                    >
                        {{ cycle }}
                    </button>
                </div>
            </div>
        </div>

        <div class="table-container mat-elevation-z8">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-slide-toggle
                            color="primary"
                            [disabled]="currentProjectService.isRunningMode()"
                            (change)="changeSPStatus(element)"
                            disabled="{{ !user.permissions.canUpdate }}"
                            checked="{{ element.active }}"
                        ></mat-slide-toggle>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                    <mat-cell *matCellDef="let element"
                        ><a (click)="selectElement(element)">{{ element.name }}</a></mat-cell
                    >
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="cycles">
                    <mat-header-cell *matHeaderCellDef> Ciclos </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.cycles }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-button (click)="trackSetpoint(element)" [disabled]="currentProjectService.isBuildingMode()">
                            <i-feather name="activity" class="light-blue"></i-feather>
                        </button>
                        <button
                            mat-button
                            (click)="selectElement(element)"
                            *ngIf="user.permissions.canUpdate; else visibilityButton"
                        >
                            <i class="material-icons">edit</i>
                        </button>
                        <button mat-button (click)="deleteElement(element)" *ngIf="user.permissions.canDelete && currentProjectService.isBuildingMode()">
                            <i class="material-icons">delete</i>
                        </button>
                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)">
                                <i class="material-icons">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
</mat-card>
