export function enrichArray() {
    //Array.prototype['first'] = Array.prototype.find(firstFromArray);

    Object.defineProperty(Array.prototype, 'first', {
        value: function () {
            return [].concat(this).find((t) => firstFromArray(t));
        },
    });
}

export function firstFromArray(t: any) {
    return t;
}

export function isNumber(param) {
    return param != undefined && param != null && !isNaN(parseFloat(param));
}
