<mat-card appearance="outlined" class="container">
    <div class="card">
        <app-date-navigator
            [navigatorPlaceholder]="navigatorPlaceholder"
            [tagNames]="tagNames"
            [redirectedTag]="redirectedTag"
            (dateChanges)="getChartData($event)"
        ></app-date-navigator>

        <div class="chart-wrapper" *ngIf="loadChart">
            <mat-divider></mat-divider>
            <div echarts [options]="fuzzyCalculationOption" [merge]="fuzzyCalculationMerge" [initOpts]="initOpts"></div>
        </div>
    </div>
</mat-card>
