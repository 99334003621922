import { Project } from './project.model';

export class ProjectSettings {
    project: Project;
    user: string;

    projectOn: boolean = false;
    projectOnWebapp: boolean = false;
    mvControl: boolean;
    mvControlBeingUsedBySp: boolean;
    watchdog: number;
    cycleTime: number;

    projectOnOpc: string;
    projectStatusOpc: string;
    projectAptOpc: string;
    watchdogOpc: string;

    projectOnOpcServerId: string;
    projectAptOpcServerId: string;
    projectStatusOpcServerId: string;
    watchdogOpcServerId: string;
}
