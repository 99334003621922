import { Tag } from './tag.model';
import { Factor } from './factor.model';
import { SPFormula } from './spFormula.model';

export class IncrementSetpoint {
    id: string;
    name: string;
    description: string;

    new_sp_tag: Tag = new Tag();
    act_sp: Tag = new Tag();
    increment_tag: Tag = new Tag();

    min_limit_tag: Tag = new Tag();
    max_limit_tag: Tag = new Tag();

    pv_tag: Tag = new Tag();
    mv_tag: Tag = new Tag();

    inc_factor: Factor = new Factor();
    dec_factor: Factor = new Factor();

    opc_address: string;
    opc_server_adress: string;
    opc_status: string;
    opc_server_status: string;
    inc_formula = new SPFormula();
    dec_formula = new SPFormula();
    cycles: number;
    sync_inc: boolean;
    active: boolean;
}
