<div class="log-body" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="column">
        <span class="title">Cálculo do Ciclo</span>
    </div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="cycle-info-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <div fxLayout="column" class="padding-10">
                <span class="title">
                    {{ node?.tag?.name ? node?.tag?.name : _panelConfig?.output?.name }}
                    <mat-icon [ngClass]="getIconByDependency(node).color">{{
                        getIconByDependency(node).icon
                    }}</mat-icon></span
                >
                <span *ngIf="shouldDispayValue">Valor: {{ formatDecimalPlaces(node?.tagValue?.value) }}</span>
                <span>Status: {{ formatStatus(node?.status) }}</span>
            </div>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
                <div fxLayout="column">
                    <span>
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                            </mat-icon>
                        </button>

                        <span class="title inner-block">
                            {{ node?.tag?.name }}
                            <mat-icon [ngClass]="getIconByDependency(node).color">{{
                                getIconByDependency(node).icon
                            }}</mat-icon>
                        </span>
                    </span>
                    <span class="value" *ngIf="shouldDispayValue"
                        >Valor: {{ formatDecimalPlaces(node?.tagValue?.value) }}</span
                    >
                    <span class="value">Status: {{ formatStatus(node?.tagValue?.status) }}</span>
                </div>
            </div>

            <div [class.cycle-info-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
</div>
