import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'add-fuzzy-input',
    templateUrl: './add-fuzzy-input.component.html',
    styleUrls: ['./add-fuzzy-input.component.scss'],
})
export class AddFuzzyInputComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
