import { Component, Input, IterableDiffers, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { SetPointService } from 'src/app/shared/service/views-services/setpoint.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { Views } from 'src/app/shared/utils/views-urls';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';


@Component({
    selector: 'app-table-setpoints',
    templateUrl: './table-setpoints.component.html',
    styleUrls: ['./table-setpoints.component.scss'],
})
export class TableSetpointsComponent implements OnInit {
    @Input() user;
    @ViewChild(MatSort) sort: MatSort;

    qbPort: any;
    qbHost: any;
    displayedColumns = ['status', 'name', 'description', 'cycles', 'actions'];

    confirmDelete = {
        component: DeleteDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            singleDelete: true,
        },
    };

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    message = '';
    returnUrl: string;
    dialogRef: any;
    setpoints = [];
    cycleOptions = [];
    filteredSetpoints = [];
    activeCycles = [];
    deleteMessage = MessagesEnum.DeleteMessage;
    isSync = false;
    isAsync = false;
    showAllCycles = true;

    constructor(
        private _iterableDiffers: IterableDiffers,
        private setpointService: SetPointService,
        private systemParService: SystemParService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router,
        public currentProjectService: CurrentProjectService,
        private route: ActivatedRoute
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/salvar-setpoint';
        this.loadData();
        this.systemParService.getQuarterbackSettings().subscribe((settings) => {
            this.qbPort = settings.port;
            this.qbHost = settings.host;
        });
    }

    loadData() {
        this.setpointService.getAllIncrementSetPoints().subscribe((setpoints) => {
            this.setpoints = setpoints.sort((a, b) => a.name.localeCompare(b.name));
            this.filteredSetpoints = this.setpoints;
            this.dataSource = new MatTableDataSource(this.filteredSetpoints);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
            this.dataSource.filterPredicate = (data: IncrementSetpoint, filterValue: string) =>
                data.new_sp_tag.name.trim().toLowerCase().indexOf(filterValue) !== -1;
            this.cycleOptions = Array.from(new Set(setpoints.map((sp) => sp.cycles))).sort(function (a, b) {
                return a - b;
            });
            this.cycleOptions.forEach((cycle) => {
                this.activeCycles.push({ value: cycle, active: false });
            });
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    changeAllCycles() {
        this.showAllCycles = !this.showAllCycles;
        if (this.showAllCycles) {
            this.activeCycles.forEach((cycle) => {
                cycle.active = false;
            });
            this.filteredSetpoints = this.setpoints;
        }
        this.filterByExecutionType();
    }

    enableCycle(cycleValue: any) {
        let index = this.activeCycles.map((cycle) => cycle.value).indexOf(cycleValue);
        if (index != -1 && this.activeCycles[index].active) {
            this.showAllCycles = false;
            this.activeCycles[index] = { value: cycleValue, active: false };
        } else {
            this.activeCycles[index] = { value: cycleValue, active: true };
        }
        this.checkActiveCycles();
        this.filterByExecutionType();
    }

    changeExecutionFilter(type: string) {
        if (type == 'sync') {
            this.isSync = !this.isSync;
        } else {
            this.isAsync = !this.isAsync;
        }
        this.filterByExecutionType();
    }

    filterByExecutionType() {
        if (this.isSync && !this.isAsync) {
            this.filteredSetpoints = this.filteredSetpoints.filter((sp) => sp.sync_inc == true);
        } else if (!this.isSync && this.isAsync) {
            this.filteredSetpoints = this.filteredSetpoints.filter((sp) => sp.sync_inc == false);
        } else if (this.showAllCycles) {
            this.filteredSetpoints = this.setpoints;
        } else {
            this.checkActiveCycles();
        }
        this.dataSource.data = this.filteredSetpoints;
    }

    checkActiveCycles() {
        let isFilterActive = this.activeCycles.find((cycle) => cycle.active == true);
        this.filteredSetpoints = this.setpoints;
        if (isFilterActive) {
            this.activeCycles.forEach((cycle) => {
                if (!cycle.active) {
                    this.filteredSetpoints = this.filteredSetpoints.filter((sp) => sp.cycles != cycle.value);
                }
            });
            this.showAllCycles = false;
        } else {
            this.showAllCycles = true;
        }
    }

    add() {
        this.router.navigate([this.returnUrl], { state: { setpoint: null } });
    }

    deleteElement(element: any) {
        this.openDialog(this.confirmDelete);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.setpointService.deleteIncrementSetPoint(element.new_sp_tag.id).subscribe((dependencies) => {
                    let conf = this.defaultDialog;
                    if (dependencies.length > 0) {
                        conf.width = 'auto';
                        conf.height = 'auto';
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            if (
                                dependencieNames.indexOf(tag.name + '(' + tag.variableType.description + ')' + ', ') ==
                                -1
                            ) {
                                dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                            }
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message = 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames;
                    } else {
                        //TODO: Implement here flag to reload quarterback.
                        conf.data.message = this.deleteMessage;
                    }
                    this.openDialog(conf);
                });
            }
        });
    }

    selectElement(element: IncrementSetpoint) {
        this.router.navigate([this.returnUrl], { fragment: element.id });
    }

    openDialog(data: any) {
        this.dialogRef = this.dialog.open(data.component, {
            panelClass: data.panelClass,
            width: data.width,
            height: data.height,
            data: data.data,
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            if (data.data.message == this.deleteMessage) {
                this.loadData();
            }
        });
    }

    changeSPStatus(setpoint: IncrementSetpoint) {
        setpoint.active = !setpoint.active;
        this.setpointService.updateSetpointStatus(setpoint).subscribe((sp) => {
            if (sp) {
                setpoint = sp;
                this.defaultDialog.data.message = MessagesEnum.SuccessEditMessage;
                this.openDialog(this.defaultDialog);
            }
        });
    }

    trackSetpoint(element: IncrementSetpoint) {
        this.router.navigate([Views.setpointsTracking.url], { fragment: element.id });
    }

}
