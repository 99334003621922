import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectDialogComponent } from 'src/app/shared/components/views-components/project-dialog/project-dialog.component';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { CurrentProjectService } from '../../shared/service/views-services/current-project.service';

@Component({
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
    projectsFound: number = 0;
    projects: Project[];
    dialogRef: any;
    user: any;

    constructor(private projectService: ProjectService, public dialog: MatDialog, public  currentProjectService: CurrentProjectService) {}

    ngOnInit() {
        this.user = getUserData();
        this.loadProjects();
    }

    loadProjects() {
        this.projectService.getAllProjects().subscribe((projects) => {
            let currentProject = this.currentProjectService.getCurrentProject();
            this.projects = projects.map(p=> {
                if(currentProject){
                    p.projectDefault = p.mainProjectId == currentProject.mainProjectId
                }
                return p;
            });
             this.projectsFound =  this.projects.length;
        });
    }

    reloadRequired(projectChanges) {
        this.loadProjects();
    }

    addProject() {
        this.openDialog(null);
        this.dialogRef.afterClosed().subscribe(() => {
            this.loadProjects();
        });
    }

    openDialog(data: any): void {
        this.dialogRef = this.dialog.open(ProjectDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: 'auto',
            height: 'auto',
            maxWidth: '1200px',
            maxHeight: '800px',
            data: data,
        });
    }
}
