<form [formGroup]="matrixForm">
    <table class="customTable">
        <tr>
            <td class="customTd" colspan="1" rowspan="2" data-cy="antecedent-1-header">{{ antecedent1 }}</td>
            <th colspan="11" data-cy="antecedent-2-header">{{ antecedent2 }}</th>
        </tr>
        <tr>
            <th *ngFor="let header of matrixHeader">{{ header }}</th>
        </tr>

        <!-- Very High :: begin -->
        <tr formArrayName="veryHigh" *ngIf="!veryHigh.disabled && veryHigh.controls.length > 0">
            <td class="customTd">Muito Alto</td>
            <td *ngFor="let element of veryHigh.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Very High :: end -->

        <!-- High :: begin -->
        <tr formArrayName="high" *ngIf="!high.disabled && high.controls.length > 0">
            <td class="customTd">Alto</td>
            <td *ngFor="let element of high.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet row-0-column-{{ i }}" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- High :: end -->

        <!-- Normal :: begin -->
        <tr formArrayName="normal" *ngIf="!normal.disabled && normal.controls.length > 0">
            <td class="customTd">Normal</td>
            <td *ngFor="let element of normal.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet row-1-column-{{ i }}" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Normal :: end -->

        <!-- Low :: begin -->
        <tr formArrayName="low" *ngIf="!low.disabled && low.controls.length > 0">
            <td class="customTd">Baixo</td>
            <td *ngFor="let element of low.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet row-2-column-{{ i }}" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Low :: end -->

        <!-- Very Low :: begin -->
        <tr formArrayName="veryLow" *ngIf="!veryLow.disabled && veryLow.controls.length > 0">
            <td class="customTd">Muito Baixo</td>
            <td *ngFor="let element of veryLow.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Very Low :: end -->

        <!-- Very Low :: begin -->
        <tr formArrayName="goingUp" *ngIf="!goingUp.disabled && goingUp.controls.length > 0">
            <td class="customTd">Aumentando</td>
            <td *ngFor="let element of goingUp.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Very Low :: end -->

        <!-- Very Low :: begin -->
        <tr formArrayName="stable" *ngIf="!stable.disabled && stable.controls.length > 0">
            <td class="customTd">Estável</td>
            <td *ngFor="let element of stable.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Very Low :: end -->

        <!-- Very Low :: begin -->
        <tr formArrayName="fallingDown" *ngIf="!fallingDown.disabled && fallingDown.controls.length > 0">
            <td class="customTd">Diminuindo</td>
            <td *ngFor="let element of fallingDown.controls; let i = index" formGroupName="{{ i }}">
                <select class="form-control outFuzzySet" formControlName="value">
                    <option *ngFor="let item of outFuzzyValues" [value]="item">{{ item }}</option>
                </select>
            </td>
        </tr>
        <!-- Very Low :: end -->
    </table>
</form>
