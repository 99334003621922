<div class="container customFont">
    <div fxLayout="column" fxLayoutAlign="start">
        <form [formGroup]="matrixForm">
            <div fxLayout="column">
                <div fxFlex="50%" fxLayoutAlign="center center">
                    <app-matrix
                        *ngIf="matrixAvailable"
                        [matrix]="matrix"
                        [matrixForm]="matrixForm"
                        [fuzzySet]="fuzzySet"
                        [antecedents]="antecedents"
                        data-cy="matrix"
                        [ngClass]="{'disabled-matrix': isMatrixDisabled}"
                    ></app-matrix>
                </div>
            </div>
        </form>
    </div>
</div>
