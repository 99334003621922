import { Component, EventEmitter, Input, IterableDiffers, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Equation } from 'src/app/shared/models/views-models/equation.model';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { ProcessType } from 'src/app/shared/models/views-models/processType.model';
import { EquationService } from 'src/app/shared/service/views-services/equation.service';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { ProcessTypeService } from 'src/app/shared/service/views-services/processType.service';
import { formatDate } from 'src/app/shared/utils/date.utils';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { EquationAddEditComponent } from './equation-add-edit/equation-add-edit.component';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-calculated-equations',
    templateUrl: './calculated-equations.component.html',
    styleUrls: ['./calculated-equations.component.scss'],
})
export class CalculatedEquationsComponent implements OnInit {
    @Input() table?: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Output() updateData = new EventEmitter();

    user: any;
    qbPort: any;
    qbHost: any;

    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    lastUpdate: string;
    message = '';
    displayedColumns = ['select', 'name', 'description', 'value', 'timestamp', 'actions'];
    shortColumns = ['name', 'description', 'value', 'timestamp', 'actions'];
    dialogRef: any;
    processList: Process[];
    filteredInputs: Equation[];
    processTypes: ProcessType[];
    searchText: string;
    showAllTypes = true;
    activeTypes = [];
    selectedList = [];
    deleteMessage = MessagesEnum.DeleteMessage;

    confirmDelete = {
        component: DeleteDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            singleDelete: true,
        },
    };

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };
    equations: Array<Equation>;

    constructor(
        private _iterableDiffers: IterableDiffers,
        public snackBar: MatSnackBar,
        private processTypeService: ProcessTypeService,
        private processService: ProcessInputService,
        private service: EquationService,
        public currentProjectService: CurrentProjectService,
        public dialog: MatDialog
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.loadData();
        this.user = getUserData();
    }

    markElement(process) {
        let index = this.selectedList.indexOf(process.id);
        if (index == -1) {
            this.selectedList.push(process.id);
        } else {
            this.selectedList.splice(index, 1);
        }
    }

    checkStatus(process) {
        let index = this.selectedList.indexOf(process.id);
        return index == -1 ? false : true;
    }

    deleteSelectedElements() {
        const conf = this.defaultDialog;
        let conflictMessage = 'Conflito ao deletar.';
        var hasConflict = false;
        this.confirmDelete.data.singleDelete = false;

        this.openAlertDialog(this.confirmDelete);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.selectedList.forEach((process, index) => {
                    this.processService.deleteProcess(process).subscribe((dependencies) => {
                        if (dependencies.length > 0) {
                            hasConflict = true;
                            let processTag = this.processList.find((tag) => tag.id == process);
                            var dependencieNames = '';
                            dependencies.forEach((tag) => {
                                if (
                                    dependencieNames.indexOf(
                                        tag.name + '(' + tag.variableType.description + ')' + ', '
                                    ) == -1
                                ) {
                                    dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                                }
                            });
                            dependencieNames = dependencieNames.slice(0, -2) + '.';
                            conflictMessage +=
                                ' A entrada ' +
                                processTag.name +
                                ' é usada como entrada em: ' +
                                dependencieNames +
                                '\n';
                        }

                        if (this.selectedList.length == index + 1) {
                            if (!hasConflict) {
                                conf.data.message = this.deleteMessage;
                            } else {
                                conf.data.message = conflictMessage;
                            }
                            this.openAlertDialog(conf);
                            this.dialogRef.afterClosed().subscribe((result) => {
                                this.selectedList = [];
                                this.loadData();
                            });
                        }
                    });
                });
            }
        });
    }

    getTimestamp(timestamp) {
        return formatDate(timestamp);
    }

    loadData() {
        this.processTypeService.getAllProcessTypes().subscribe((processTypes) => {
            this.processTypes = processTypes
                .filter((process) => ![VariableTypeEnum.EQUATION.valueOf()].includes(process.name))
                .sort((a, b) => a.description.localeCompare(b.description));
            this.processTypes.forEach((type) => {
                this.activeTypes.push({ value: type, active: false });
            });
        });
        this.loadDatasource();
    }

    loadDatasource() {
        this.service.getAll().subscribe((equations) => {
            this.equations = equations;
            this.filteredInputs = this.equations;
            this.dataSource = new MatTableDataSource(this.equations);
            this.checkActiveTypes();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = (data: { name: string }, filterValue: string) =>
                data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
            if (this.searchText) {
                this.applyFilter(this.searchText);
            }
        });

        this.lastUpdate = new Date().toLocaleString();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.searchText = filterValue;
    }

    add() {
        this.openDialog(null);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.processList.push(result);
                this.checkActiveTypes();
            }
        });
    }

    openAlertDialog(options: any) {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            maxWidth: '720px',
            height: options.height,
            data: options.data,
        });
    }

    delete(equation: Equation) {
        const conf = this.defaultDialog;
        this.openAlertDialog(this.confirmDelete);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.service.delete(equation.id).subscribe(
                    (deleted) => {
                        if (deleted.error) {
                            conf.data.message = `${deleted.error}. ${deleted.data.map((t) => t.name).join(';')}`;
                            this.openAlertDialog(conf);
                            this.loadDatasource();
                        } else {
                            conf.data.message = this.deleteMessage;
                            this.openAlertDialog(conf);
                            this.loadDatasource();
                        }
                    },
                    () => {
                        conf.data.message = MessagesEnum.Exception;
                        this.openAlertDialog(conf);
                        this.loadDatasource();
                    }
                );
            }
        });
    }

    selectElement(element: Equation) {
        let eq = this.equations.find((item) => item.id == element.id);
        let eqIndex = this.equations.indexOf(eq);
        let equation = Object.assign({}, this.equations[eqIndex]);
        this.openDialog(equation);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.equations[eqIndex] = result;
                this.checkActiveTypes();
            }
        });
    }

    openDialog(data: any) {
        this.dialogRef = this.dialog.open(EquationAddEditComponent, {
            panelClass: 'custom-dialog-container',
            width: '75vw',
            height: 'auto',
            data: data,
        });
        this.dialogRef.afterClosed().subscribe((input) => {
            this.loadDatasource();
        });
    }

    changeAllTypes() {
        this.activeTypes.forEach((type) => {
            type.active = false;
        });
        this.showAllTypes = true;
        this.filteredInputs = this.equations;
        this.dataSource.data = this.filteredInputs.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
    }

    enableType(type) {
        let index = this.activeTypes.map((type) => type.value).indexOf(type);
        if (index != -1 && this.activeTypes[index].active) {
            this.showAllTypes = false;
            this.activeTypes[index] = { value: type, active: false };
        } else {
            this.activeTypes[index] = { value: type, active: true };
        }
        this.checkActiveTypes();
    }

    checkActiveTypes() {
        this.filteredInputs = this.equations;
        let isFilterActive = this.activeTypes.find((type) => type.active == true);
        if (isFilterActive) {
            this.activeTypes.forEach((type) => {
                if (!type.active) {
                    this.filteredInputs = this.filteredInputs.filter((input) => input.name != type.value.name);
                }
            });
            this.showAllTypes = false;
        } else {
            this.showAllTypes = true;
        }
        this.dataSource.data = this.filteredInputs.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
    }

    getTagValue(tagValue) {
        if (this.isInt(tagValue) || this.isBool(tagValue)) {
            return tagValue;
        }
        return parseFloat(tagValue).toFixed(4);
    }

    isBool(tag) {
        try {
            return `${tag}`.toLowerCase() == 'true' || `${tag}`.toLowerCase() == 'false';
        } catch (e) {
            return false;
        }
    }

    isInt(n) {
        try {
            n = Number(n);
            return n % 1 === 0;
        } catch (e) {
            return false;
        }
    }
}
