import { Project } from './project.model';

export class ProjectStatus {
    project: Project = new Project();

    projectOn: boolean = false;
    projectOnWebapp: boolean = false;
    projectApt: boolean;
    engineStatus: boolean;
    quarterbackStatus: boolean;
    processStatus: boolean;
    lastRunAt: String;
    workerStatus: boolean;
    projectOnRunning: boolean;
}
