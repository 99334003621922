<div class="log-body" fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="column">
        <span class="title">Timestamp: {{ formartTimestamp(cycleInfo?.cycleTimestamp) }}</span>
        <span class="title">Tempo de execução: {{ cycleInfo?.cycleRuntime }}</span>
    </div>
    <div><mat-divider></mat-divider></div>
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon [ngClass]="cycleInfo?.quarterbackStatus ? 'green-icon' : 'red-icon'">{{
                cycleInfo?.quarterbackStatus ? "check_circle" : "cancel"
            }}</mat-icon
            ><span>Status da coleta de dados</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon [ngClass]="cycleInfo?.engineStatus ? 'green-icon' : 'red-icon'">{{
                cycleInfo?.engineStatus ? "check_circle" : "cancel"
            }}</mat-icon
            ><span>Status do Tratamento de Dados</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon [ngClass]="cycleInfo?.workerStatus ? 'green-icon' : 'red-icon'">{{
                cycleInfo?.workerStatus ? "check_circle" : "cancel"
            }}</mat-icon
            ><span>Status dos Cálculos</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <mat-icon [ngClass]="cycleInfo?.processStatus ? 'green-icon' : 'red-icon'">{{
                cycleInfo?.processStatus ? "check_circle" : "cancel"
            }}</mat-icon
            ><span>Status do Processo</span>
        </div>
    </div>
    <div><mat-divider></mat-divider></div>
    <div fxLayout="column" *ngIf="log && isSetpoint">
        <span class="title">Frequência de Atuação</span>
        <span>Ciclos: {{ log.cycles }}</span>
    </div>
    <div *ngIf="log && isSetpoint"><mat-divider></mat-divider></div>
    <div fxLayout="column" *ngIf="log">
        <div fxLayout="column">
            <span class="title" *ngIf="isSetpoint"
                >Permissão para {{ log.increment < 0 ? "decrementar" : "incrementar" }}</span
            >
            <span class="title" *ngIf="!isSetpoint">Status</span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <span> - {{ panelTitle }} Ligado: {{ log.setpointOn ? "True" : "False" }} </span>
                <mat-icon [ngClass]="log.setpointOn ? 'green-icon' : 'red-icon'">{{
                    log.setpointOn ? "check_circle" : "cancel"
                }}</mat-icon>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" *ngIf="isSetpoint">
                <span> - Ciclo de execução: {{ isExecutionCycle ? "True" : "False" }} </span>
                <mat-icon [ngClass]="isExecutionCycle ? 'green-icon' : 'red-icon'">{{
                    isExecutionCycle ? "check_circle" : "cancel"
                }}</mat-icon>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <span> - Condições </span>
                <span *ngIf="isSetpoint"> de {{ log.increment < 0 ? "decremento" : "incremento" }}: </span>
                <span>
                    {{ getIsIncDecValid(log.conditionsValid) }}
                </span>
                <mat-icon [ngClass]="log.conditionsValid ? 'green-icon' : 'red-icon'">{{
                    getConditionSymbol(log.conditionsValid)
                }}</mat-icon>
            </div>
        </div>
        <div fxLayout="column" *ngIf="log.conditionsActive && log.equation && isSetpoint; else noEquation">
            <br />
            <span>Equação: {{ log.equation }}</span>
            <br />
            <span>Condições:</span>
            <div *ngFor="let condition of log.conditionValues">
                <div fxLayout="row">
                    <span>{{ condition.name }}:&nbsp;</span>
                    <span>{{ condition.tagName }}&nbsp;</span>
                    <span>{{ condition.operator }}&nbsp;</span>
                    <span>{{ condition.value }}&nbsp;</span>
                    <span>{{ getIsConditionValid(condition.valid) }}</span>
                </div>
            </div>
        </div>
        <ng-template #noEquation>
            <span *ngIf="!log.equation && isSetpoint; else conditionsOff">Nenhuma condição cadastrada</span>
        </ng-template>
        <ng-template #conditionsOff>
            <div *ngIf="isSetpoint">
                <span>Condições desativadas</span>
            </div>
        </ng-template>
    </div>
    <div *ngIf="log"><mat-divider></mat-divider></div>
    <div fxLayout="column" *ngIf="log">
        <span class="title">Cálculo do {{ panelTitle }}</span>
        <span>{{ panelTitle }} atual: {{ formartNumber(log.currentValue) }}</span>
        <span *ngIf="isSetpoint">Consequente: {{ formartNumber(log.fuzzyValue) }}</span>
        <span *ngIf="isSetpoint">Fator: {{ formartNumber(log.factor) }}</span>
        <span *ngIf="isSetpoint"
            >{{ log.increment >= 0 ? "Incremento" : "Decremento" }}: {{ formartNumber(log.increment) }}</span
        >
        <span *ngIf="isSetpoint">Novo Setpoint: {{ formartNumber(log.newValue) }}</span>
    </div>
    <div><mat-divider></mat-divider></div>
    <div fxLayout="column" *ngIf="log && isSetpoint">
        <span class="title">Limites de atuação</span>
        <span>Mínimo: {{ log.minLimit }}</span>
        <span>Máximo: {{ log.maxLimit }}</span>
    </div>
</div>
