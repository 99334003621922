import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SetpointsRoutes } from './setpoints.routing';
import { SetpointsComponent } from './setpoints.component';

@NgModule({
    declarations: [SetpointsComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(SetpointsRoutes)],
})
export class SetpointsModule {}
