import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../../models/views-models/tag.model';
import { Response } from '../../models/response.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const API = environment.serverUrl;

@Injectable()
export class TagService {
    constructor(private httpClient: HttpClient) {}

    getAllTagsByType(type: string): Observable<Tag[]> {
        return this.httpClient.get<Response>(`${API}tag/all/type/${type}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newTag: Tag[] = response.data;
                return newTag;
            })
        );
    }

    getTag(id: string): Observable<Tag> {
        return this.httpClient.get<Response>(`${API}tag/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tag: Tag = response.data;
                return tag;
            })
        );
    }

    getTagByName(tagName: string): Observable<Tag> {
        return this.httpClient.get<Response>(`${API}tag/name/${tagName}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tag: Tag = response.data;
                return tag;
            })
        );
    }

    getTagDependencies(tagId: string): Observable<Tag[]> {
        return this.httpClient
            .get<Response>(`${API}tag/tag-dependencies/${tagId}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const tag: Tag[] = response.data;
                    return tag;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
