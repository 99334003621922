import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EChartsOption, LegendComponentOption, LineSeriesOption, SeriesOption, TooltipComponentOption } from 'echarts';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { DatetimeInterval } from 'src/app/shared/models/views-models/dateInterval.model';
import { OutputConditionValue } from 'src/app/shared/models/views-models/outputConditionValue';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { SetpointCalculation } from 'src/app/shared/models/views-models/setpointCalculation.model';
import { SpTagValue } from 'src/app/shared/models/views-models/spTagValue.model';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { formatDateObj, formatToServer } from 'src/app/shared/utils/date.utils';
import {
    AxisConfig,
    ChartClickEvent,
    CycleOutputType,
    TagAxis,
    getCycleOutputTagId,
    getCycleOutputType,
} from '../setpoint-models/axis.config';
import {
    ChartConfig,
    ChartTypeMapper,
    OutputSeries,
    SetpointSeries,
    normalizeValue,
} from '../setpoint-models/setpoint-chart.config';
import {
    DataIntervalType,
    createChartSerieOption,
    getDefaultChartOptions,
    getDefaultMergeOptions,
    getStatusColorFromCycleInfo,
    getStatusLabelFromCycleInfo,
    variableTypesDataInterval,
} from '../setpoint-models/setpoint-chart.options';
import { SetpointValuesService, TagsValues } from '../setpoint-values.service';

@Component({
    selector: 'app-setpoint-chart',
    templateUrl: './setpoint-chart.component.html',
    styleUrls: ['./setpoint-chart.component.scss'],
})
export class SetpointChartComponent {
    private _axisConfig: AxisConfig;
    private _startDatetime: string;
    private _endDatetime: string;

    private chartConfig: ChartConfig;
    private chartMappers: Array<ChartTypeMapper>;
    private cycleOutputType: CycleOutputType;

    chartInitOpts = {
        height: 640,
    };
    chartMergeOptions: EChartsOption;
    chartOptions: EChartsOption;
    loadChart: boolean = false;
    collectorTime: number;
    cycleTime: number;

    private _$eventChartClick: BehaviorSubject<ChartClickEvent> = new BehaviorSubject(null);

    @Input() set axisConfig(value: AxisConfig) {
        this._axisConfig = value;
        this.loadCycleInfoChart();
    }

    @Input() set dateInterval(value: DatetimeInterval) {
        this._startDatetime = value.startDatetime;
        this._endDatetime = value.endDatetime;
        this.loadCycleInfoChart();
    }

    @Output() selectCycle = new EventEmitter<ChartClickEvent>();

    constructor(
        private setPointValueService: SetpointValuesService,
        private systemParService: SystemParService,
        private projectService: ProjectService
    ) {
        this.systemParService.getCollectorTime().subscribe((c) => (this.collectorTime = c.value));
        this.projectService.getCycleTime().subscribe((c) => (this.cycleTime = c));
    }

    resetChartParams() {
        this.chartConfig = new ChartConfig();
        this.setChartMappers();
        this.setDefaultMergeOptions();
        this.setDefaultChartOptions(this.cycleOutputType);
    }

    setChartMappers() {
        this.cycleOutputType = getCycleOutputType(this._axisConfig.output);
        this.chartMappers = this.chartConfig.getChartMappers(this.cycleOutputType);
        if (!this.isOutputProcess()) return;
        this.filterMappers();
    }

    filterMappers() {
        if (!this.hasOutputProcessCondition())
            this.chartMappers = this.chartMappers.filter((mapper) => mapper.serie !== 'Condição');
    }

    isOutputProcessOn(): boolean {
        return this.outputProcess.status;
    }

    hasOutputProcessCondition() {
        return this.outputProcess.conditionId;
    }

    get outputProcess() {
        return this._axisConfig.output as OutputProcess;
    }

    isOutputProcess() {
        return this.cycleOutputType == CycleOutputType.OUTPUT;
    }

    ngOnInit(): void {
        this.resetChartParams();
        this.observeChangeChartParams();
        this._$eventChartClick
            .pipe(
                debounceTime(300),
                filter((f) => Boolean(f))
            )
            .subscribe((eventClick) => {
                this.selectCycle.emit(eventClick);
            });
    }

    hasVariablesChanged(value: AxisConfig) {
        if (value.tags.length !== this._axisConfig?.tags?.length) {
            return true;
        }

        return value.tags.some((tag, i) => {
            return tag.id !== this._axisConfig?.tags[i]?.id;
        });
    }

    observeChangeChartParams() {
        this.setPointValueService.$observeValuesLoaded.subscribe((isLoaded) => {
            this.loadChart = isLoaded;
            if (!isLoaded) return;
            if (this.cycleOutputType == CycleOutputType.SETPOINT) {
                this.updateDataSetpointCalculation(
                    this.setPointValueService.getSeriesValues<SetpointCalculation>(SetpointCalculation),
                    this.setPointValueService.getSeriesValues<SpTagValue>(SpTagValue)
                );
            }

            if (this.cycleOutputType == CycleOutputType.OUTPUT && this.outputProcess.status) {
                this.updateProcessOutputValues(
                    this.setPointValueService.getSeriesValues<OutputProcess>(OutputProcess),
                    this.setPointValueService.getSeriesValues<OutputConditionValue>(OutputConditionValue)
                );
            }

            this.updateDefaultData();
        });
    }

    async loadCycleInfoChart() {
        const isValidParameters = Boolean(this._axisConfig && this._startDatetime && this._endDatetime);
        if (!isValidParameters) {
            return;
        }
        this.resetChartParams();
        this._updateXAxisLimits(this._startDatetime, this._endDatetime);
        await this.setPointValueService.loadDataFromAxisConfig(
            this._axisConfig,
            this._startDatetime,
            this._endDatetime
        );
    }

    private _updateXAxisLimits(startDatetime: string, endDatetime: string) {
        this.chartMergeOptions.xAxis = [
            { min: startDatetime, max: endDatetime },
            { min: startDatetime, max: endDatetime },
        ];
    }

    updateDefaultData() {
        this.updateDataTagValue(this.setPointValueService.getSeriesValues<TagsValues>(TagsValues));
        this.updateDataCycleInfo(this.setPointValueService.getSeriesValues<CycleInfo>(CycleInfo));
        this.updateTootip();
    }

    updateProcessOutputValues(
        processOutputValues: OutputProcess[],
        processOutputConditionValues: OutputConditionValue[]
    ) {
        this.updateDataChart(processOutputValues, 'Saída');
        if (processOutputConditionValues) this.updateDataChart(processOutputConditionValues, 'Condição');
    }

    updateDataCycleInfo(cycleInfo: CycleInfo[]) {
        if (cycleInfo.length > 0) {
            const firstCycle = cycleInfo.first();
            this.updateDataChart(
                [
                    {
                        ...firstCycle,
                        cycleTimestamp: formatToServer(new Date(this._startDatetime).toISOString()),
                        mock: true,
                    },
                    ...cycleInfo,
                    {
                        ...firstCycle,
                        cycleTimestamp: formatToServer(new Date(this._endDatetime).toISOString()),
                        mock: true,
                    },
                ],
                'CycleInfo'
            );
            this.updateMarkLine(cycleInfo);
        } else {
            this.updateDataChart(
                [
                    { cycleTimestamp: new Date(this._startDatetime), cycleStatus: 'error' },
                    { cycleTimestamp: new Date(this._endDatetime), cycleStatus: 'error' },
                ],
                'CycleInfo'
            );
        }
    }

    updateDataSetpointCalculation(spCalculation: SetpointCalculation[], spTagValue: SpTagValue[]) {
        this.updateDataChart(spCalculation, 'Incremento');
        this.updateDataChart(spTagValue, 'Setpoint');
        this.updateDataChart(spCalculation, 'Limite_Inf');
        this.updateDataChart(spCalculation, 'Limite_Sup');
    }

    updateDataTagValue(tagsValues: TagValue[][]) {
        tagsValues?.forEach((tagValues) => {
            if (tagValues.length > 0) {
                const tagName = tagValues.first()?.tag?.name;
                this.updateDataChart(tagValues, tagName as SetpointSeries);
            }
        });
    }

    updateMarkLine(cycleInfo: CycleInfo[]) {
        let cycleInfoSerie = this.getChartSerieOption('CycleInfo');

        let markPoints = cycleInfo.map((c, index) => ({
            name: `markpoint-${index}`,
            coord: [c.cycleTimestamp, 0],
            symbol: 'rect',
            symbolSize: 14,
            itemStyle: {
                color: getStatusColorFromCycleInfo(c),
            },
        }));

        cycleInfoSerie.markPoint = {
            data: markPoints,
        };
    }

    getSerieValues(date: Date) {
        return (this.chartMergeOptions.series as Array<SeriesOption>).map((serie) => {
            const seriesName = serie.name as any;
            let seriesValue = (serie.data as Array<Array<any>>)?.find(
                ([d, v]) => new Date(d).getTime() == date.getTime()
            )?.[1];

            if (seriesName == CycleOutputType.SETPOINT || seriesName == 'Saída') {
                const cycleInfo = this.setPointValueService.getSerieValueByTimestamp<CycleInfo>(CycleInfo, date);
                seriesValue = cycleInfo?.dependency?.tagValue?.value;
            }

            return {
                value: seriesValue,
                serie: this.chartConfig.getChartMap(seriesName)?.label || serie.name,
                option: this.getChartSerieOption(seriesName, 'chart'),
                //quality: quality
            };
        });
    }

    private _getSeriesInfo(serieValue) {
        const serieLegendButton = `<span style="display:inline-block;margin-right:4px;border-radius: 10px; width: 10px; height: 10px;background-color:${serieValue.option.color};"></span>`;
        const seriesName = {
            Limite_Inf: 'Limite inf.',
            Limite_Sup: 'Limite sup.',
        };

        const style = serieValue.quality == 'Bad' ? '<span  class="red-text">' : '<span>';
        const serieName = `${style} ${seriesName[serieValue.option.name] || serieValue.option.name} </span>`;
        return `${serieLegendButton} ${serieName}  ${normalizeValue(serieValue.value)}`;
    }

    private _getFormattedDate(serieDate) {
        return `<div class="text-block"><b>${formatDateObj(serieDate)}</b></div>`;
    }

    private _getOutputStatus(serieDate) {
        const cycleInfo = this.setPointValueService.getSerieValueByTimestamp<CycleInfo>(CycleInfo, serieDate);
        const cycleInfoStatusLabel = getStatusLabelFromCycleInfo(cycleInfo);
        return `<div class="text-block">Saída: ${cycleInfoStatusLabel}</div> <span class="divider"></span><div class="text-block"></div>`;
    }

    private _isMockValue(serieDate: Date) {
        const cycleInfo = this.setPointValueService.getSerieValueByTimestamp<CycleInfo>(CycleInfo, serieDate);
        return !cycleInfo || cycleInfo?.mock;
    }

    private _getCycleInfoTooltip(serie) {
        const serieDate = new Date(serie.first().value.first());
        if (this._isMockValue(serieDate)) {
            return '';
        }

        const formattedDate = this._getFormattedDate(serieDate);
        const outputStatus = this._getOutputStatus(serieDate);
        const seriesInfo = this.getSerieValues(serieDate)
            .filter((s) => s.option.name != 'CycleInfo')
            .map((serieValue) => this._getSeriesInfo(serieValue))
            .join('<br />');
        return `${formattedDate} ${outputStatus} ${seriesInfo}`;
    }

    private _getValuesTooltip(serie: any) {
        const serieDate = new Date(serie.first().value.first());
        const formattedDate = this._getFormattedDate(serieDate);

        const seriesInfo = (this.chartOptions.series as Array<SeriesOption>)
            .concat(this.chartMergeOptions.series as Array<SeriesOption>)
            .map((serie) => {
                const seriesName = serie.name as any;
                let seriesValue = (serie.data as Array<Array<any>>)?.find(
                    ([d, v]) => new Date(d).getTime() == serieDate.getTime()
                )?.[1];

                const tagValue = this.setPointValueService.getTagValuesBySeriesNameAndDate(seriesName, serieDate);
                return {
                    value: seriesValue,
                    serie: this.chartConfig.getChartMap(seriesName)?.label || serie.name,
                    option: this.getChartSerieOption(seriesName, 'chart'),
                    quality: tagValue?.quality || 'Good',
                };
            })
            .filter((s) => s.value !== undefined && s.option.name != 'CycleInfo')
            .map((serieValue) => this._getSeriesInfo(serieValue))
            .join('<br />');

        return `${formattedDate}  ${seriesInfo}`;
    }

    updateTootip() {
        let tooltips = this.chartMergeOptions.tooltip as Array<TooltipComponentOption>;
        tooltips.forEach((tooltip) => {
            tooltip.formatter = (serie) => {
                if (serie.first()?.seriesName == 'CycleInfo' || serie.seriesName == 'CycleInfo') {
                    return this._getCycleInfoTooltip(serie);
                }
                return this._getValuesTooltip(serie);
            };
        });
    }

    getChartSerieOption(
        chartType: SetpointSeries | OutputSeries,
        optionType: 'chart' | 'merge' = 'merge'
    ): LineSeriesOption {
        if (optionType == 'merge')
            return (this.chartMergeOptions.series as Array<LineSeriesOption>).find(
                (chartSerie) => chartSerie.name == chartType
            );

        return (this.chartOptions.series as Array<LineSeriesOption>).find((chartSerie) => chartSerie.name == chartType);
    }

    _getChartIntervalInSeconds(intervalType: DataIntervalType, data: Array<any>) {
        if (intervalType == DataIntervalType.OPC_COLLECTOR) {
            return this.collectorTime;
        }

        if (intervalType == DataIntervalType.CYCLE_TIME) {
            return this.cycleTime;
        }

        if (intervalType == DataIntervalType.FROM_TAG_VALUE) {
            const variable_type = data?.first()?.tag?.type;
            if (variableTypesDataInterval.CYCLE_TIME.indexOf(variable_type) != -1) return this.cycleTime;

            if (variableTypesDataInterval.OPC_COLLECTOR.indexOf(variable_type) != -1) return this.collectorTime;
        }

        return this.cycleTime;
    }

    _getFilledChartData(data: Array<any>, chartType) {
        let filledChartData = [];
        const chartInterval = this.chartConfig.getChartMap(chartType).intervalType;
        const chartMapper = this.chartConfig.getChartTypeMap(chartType);
        const chartData = data?.map(chartMapper);

        const firstItem = chartData?.first();
        const secondItem = chartData?.[1];

        if (firstItem && secondItem && firstItem[0] && secondItem[0]) {
            const timeIntervalSeconds = this._getChartIntervalInSeconds(chartInterval, data);

            let lastData = firstItem;
            for (const item of chartData) {
                let diff = (new Date(item[0]).getTime() - new Date(lastData[0]).getTime()) / 1000;
                let exec = 0;
                while (diff > timeIntervalSeconds * 3 && exec < 100 && chartType != 'CycleInfo') {
                    let date = new Date(formatToServer(lastData[0]));
                    date.setSeconds(date.getSeconds() + timeIntervalSeconds);

                    filledChartData.push([formatToServer(date.toISOString()), null]);
                    lastData = [formatToServer(date.toISOString()), null];
                    diff = (new Date(item[0]).getTime() - new Date(lastData[0]).getTime()) / 1000;
                    exec++;
                }
                lastData = item;
                filledChartData.push(item);
            }
        }

        return filledChartData;
    }

    updateDataChart(data: Array<any>, chartType: SetpointSeries | OutputSeries, axisTag: TagAxis = null) {
        let chartSerie = this.getChartSerieOption(chartType);
        if (!chartSerie) {
            const chartSize = (this.chartOptions.series as Array<LineSeriesOption>).length;
            const yAxisIndex = data[0]?.tag?.axis;
            chartSerie = createChartSerieOption(chartType, chartSize, yAxisIndex);
            (this.chartOptions.series as Array<LineSeriesOption>).push(chartSerie);
            if (!this.isOutputProcess() || chartType !== 'CycleInfo') {
                let legendOption = (this.chartOptions.legend as Array<LegendComponentOption>).first();
                legendOption.data.push(chartType);
                legendOption.selected[chartType] = true;
            }
        }

        chartSerie.data = this._getFilledChartData(data, chartType);
        this.chartMappers.push(this.chartConfig.getChartMap(chartType));
    }

    setDefaultMergeOptions() {
        this.chartMergeOptions = getDefaultMergeOptions(this.chartMappers, this._startDatetime, this._endDatetime);
    }

    setDefaultChartOptions(type: string) {
        this.chartOptions = getDefaultChartOptions(this.chartMappers, type);
    }

    /* Chart Events**/
    onClickChartLegend($event: any) {
        const isChangedLimits = $event.type == 'legendselectchanged' && $event.name == 'Limite_Inf';
        if (isChangedLimits) {
            let mergeOptions = { ...this.chartMergeOptions };
            this.chartMergeOptions = null;
            let legends = mergeOptions.legend as Array<LegendComponentOption>;
            legends?.forEach((legend) => {
                legend.selected['Limite_Sup'] = $event.selected.Limite_Inf;
            });
            mergeOptions.legend = legends;
            this.chartMergeOptions = { ...mergeOptions };
        }
    }

    async onClickChartEvent($event: any, isDoubleClick = false) {
        const selectedTimestamp = $event?.data.coord?.first();
        const cycleInfo = await this.setPointValueService.getSerieValueByTimestamp<CycleInfo>(
            CycleInfo,
            selectedTimestamp
        );
        let cycleInfoWithTreeDependency;
        let setpointTagId = cycleInfo?.dependency?.tag?.id ?? getCycleOutputTagId(this._axisConfig.output, true);
        if (setpointTagId) {
            cycleInfoWithTreeDependency = await this.setPointValueService.getDependencyTree(
                setpointTagId,
                cycleInfo?.id
            );
        }
        this._$eventChartClick.next({
            cycleInfo: cycleInfoWithTreeDependency ?? cycleInfo,
            isDoubleClick: isDoubleClick,
        });
    }
}
