import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../../service/navigation/navigation.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit {
    @Input('items') public items: any[] = [];
    @Input('hasIconMenu') public hasIconMenu: boolean;
    @Input('iconMenuTitle') public iconMenuTitle: string;

    constructor(private navigationService: NavigationService) {
        this.items = this.navigationService.defaultMenu;
    }
    ngOnInit() {}

    // Only for demo purpose
    addMenuItem() {
        this.items.push({
            name: 'ITEM',
            type: 'dropDown',
            tooltip: 'Item',
            icon: 'done',
            state: 'material',
            sub: [
                { name: 'SUBITEM', state: 'cards' },
                { name: 'SUBITEM', state: 'buttons' },
            ],
        });
    }
}
