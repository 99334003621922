import { Role } from './role.model';

export class User {
    id: string;
    name: string;
    username: string;
    password: string;
    email: string;
    company: string;
    sector: string;
    registry: string;
    role: Role = new Role();
    origin: string;
}
