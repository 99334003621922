import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { saveAs } from 'file-saver';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { LocalStorageService } from 'src/app/shared/service/views-services/localstorage.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { formatDateObj } from 'src/app/shared/utils/date.utils';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-history-sider',
    templateUrl: './history-sider.component.html',
    styleUrls: ['./history-sider.component.scss'],
})
export class HistorySiderComponent implements OnInit {
    constructor(
        private projectService: ProjectService,
        private lsService: LocalStorageService,
        private currentProjectService: CurrentProjectService,
        public dialog: MatDialog
    ) {}
    versions: Array<Project>;
    dialogRef: any;
    ngOnInit(): void {
        this.updateProjects();
    }

    updateProjects() {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                this.versions = versions
                    .map((v) => {
                        let currentProject = this.currentProjectService.getCurrentProject();
                        if (currentProject) {
                            v.defaultVersion = currentProject.id == v.id;
                        }
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');
            });
        }
    }

    getVersionType(project: Project) {
        return project?.versionType.substring(0, 1).toUpperCase() + project?.versionType.substring(1).toLowerCase();
    }

    getDialogRef(conf: any) {
        return this.dialog.open(conf.component, {
            panelClass: conf.panelClass,
            minWidth: conf.minWidth ? conf.minWidth : 'auto',
            height: 'auto',
            data: conf.data,
        });
    }

    apply(drawer: MatDrawer, project: Project) {
        this.projectService.applyVersion(project).subscribe((isVersionapplied) => {
            if (isVersionapplied) {
                drawer.close();
                this.lsService.reset(project);
                this.lsService.triggerLocalStorageChanges(project);
                this.currentProjectService.setCurrentProject(project);
                this.currentProjectService.goReload();
            }
        });
    }

    removeVersion(drawer: MatDrawer, project: Project) {
        let conf = {
            component: ConfirmDialogComponent,
            panelClass: 'pop-up-dialog-container',
            minWidth: 'auto',
            data: {
                title: 'Excluir a versão projeto',
                message: 'Deseja excluir a versão V' + project.version + '?',
                cancelAvailable: true,
                confirmButton: 'Remover',
            },
        };

        this.getDialogRef(conf)
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.projectService.deleteProjectVersion(project).subscribe((isVersionRemoved) => {
                        if (isVersionRemoved) {
                            drawer.close();
                            conf.data.title = '';
                            conf.data.cancelAvailable = false;
                            conf.data.confirmButton = '';
                            conf.data.message = MessagesEnum.DeleteMessage;
                            this.getDialogRef(conf);
                        }
                    });
                }
            });
    }

    download(project: Project) {
        this.projectService.getProjectDownload(project).subscribe((data) => {
            if (data) {
                let blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
                saveAs(blob, `${project.name}_${project.versionType}.json`);
            }
        });
    }

    restore(drawer: MatDrawer) {
        let conf = {
            component: ConfirmDialogComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                title: '',
                message: MessagesEnum.restoreMessage,
                cancelAvailable: false,
                confirmButton: '',
            },
        };
        this.projectService.restoreProject().subscribe((data) => {
            if (data) {
                drawer.close();
                this.openDialog(conf);
                this.dialogRef.afterClosed().subscribe(() => {
                    this.currentProjectService.goHome();
                });
            }
        });
    }

    isRemoveButtonDisabled(version: Project) {
        return version?.defaultVersion || version?.versionType == 'RUNNING';
    }

    getRemoveButtonLabel(version: Project) {
        if (version?.defaultVersion) {
            return 'Versão default';
        }

        if (version?.versionType == 'RUNNING') {
            return 'Versão Running';
        }

        return 'Remover';
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            minWidth: options.minWidth ? options.minWidth : 'auto',
            height: 'auto',
            data: options.data,
        });
    }

    onFileSelected(event: any, drawer: MatDrawer): void {
        const file: File = event.target.files[0];
        let conf = {
            component: ConfirmDialogComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                title: '',
                message: MessagesEnum.uploadMessage,
                cancelAvailable: false,
                confirmButton: '',
            },
        };
        this.projectService.upload(file).subscribe((uploadedProject: Project) => {
            if (uploadedProject) {
                drawer.close();
                this.openDialog(conf);
                this.dialogRef.afterClosed().subscribe(() => {
                    this.currentProjectService
                        .getDefaultVersionFromServer(uploadedProject, 'BUILDING')
                        .subscribe((currentProject) => {
                            this.currentProjectService.setCurrentProject(currentProject);
                            this.currentProjectService.goHome();
                        });
                });
            }
        });
    }

    isUploadDisabled() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType === 'RUNNING';
    }

    isHaveVersions() {
        return this.versions.length > 1;
    }
}
