import { MatrixValues } from './matrixValues.model';

export class Matrix {
    outFuzzySet: number;
    veryHigh: MatrixValues[] = [];
    high: MatrixValues[] = [];
    normal: MatrixValues[] = [];
    low: MatrixValues[] = [];
    veryLow: MatrixValues[] = [];
    goingUp: MatrixValues[] = [];
    stable: MatrixValues[] = [];
    fallingDown: MatrixValues[] = [];
}
