import { SetpointsComponent } from './setpoints.component';
import { Routes } from '@angular/router';

export const SetpointsRoutes: Routes = [
    {
        path: '',
        component: SetpointsComponent,
        data: {
            title: 'Setpoints',
        },
    },
];
