import { Audity } from './audity.model';
import { Tag } from './tag.model';
import { Unit } from './unit.model';
import { ProcessType } from './processType.model';

export class Process {
    id: string;
    unit: Unit = new Unit();
    audity: Audity = new Audity();
    tag: Tag = new Tag();
    process_type: ProcessType = new ProcessType();
    is_output: boolean;
    name: string;
    description: string;
    opc_address: string;
    value: number;
    quality: string;
    oldData: boolean;
    tagMin: Tag = new Tag();
    tagMax: Tag = new Tag();
    opcServerId: string;
}
