import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { SetPointService } from 'src/app/shared/service/views-services/setpoint.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { getCycleOutputTagId } from '../setpoint-models/axis.config';
import {
    AxisConfig,
    AxisList,
    getCycleOutputTagName,
    AxisType,
    getCycleOutputType,
    CycleOutputType,
} from '../setpoint-models/axis.config';
export interface TagList {
    variable: string;
    tags: Tag[];
}

export const filter = (opt: Tag[], value: string): Tag[] => {
    const filterValue = value.toLowerCase();
    return opt.filter((item) => item.name.toLowerCase().includes(filterValue));
};

export const variableTypes = {
    fuzzy: 'Regras',
    process: 'Entradas',
    equation: 'Variáveis calculadas',
    weighted: 'Ponderadas',
    calculated: 'Variáveis Tratadas',
    setpoint: 'Setpoints',
    manipulated_variable: 'Variáveis Manipuladas',
};

@Component({
    selector: 'app-setpoints-tracking-settings',
    templateUrl: './setpoints-tracking-settings.component.html',
    styleUrls: ['./setpoints-tracking-settings.component.scss'],
})
export class SetpointsTrackingSettingsComponent implements OnInit {
    fuzzyForm: FormGroup;
    removable: boolean = true;
    selectable: boolean = true;
    secondaryAxis: Tag[] = [];
    mainAxis: Tag[] = [];
    mainAxisOptions: TagList[];
    secondaryAxisOptions: TagList[];
    mvList: Process[];
    setpointsList: IncrementSetpoint[];
    isAddPvMvTags: Boolean = true;
    setpoint: IncrementSetpoint;

    chartFrom = this._formBuilder.group({
        filterMainAxis: '',
        filterSecondaryAxis: '',
        toggleAutoScale: this.axisConfig.autoScale,
        mainAxisY1: [{ value: this.axisConfig.mainLimits.min, disabled: this.axisConfig.autoScale }],
        mainAxisY2: [{ value: this.axisConfig.mainLimits.max, disabled: this.axisConfig.autoScale }],
        secondaryAxisY1: [{ value: this.axisConfig.secondLimits.min, disabled: this.axisConfig.autoScale }],
        secondaryAxisY2: [{ value: this.axisConfig.secondLimits.max, disabled: this.axisConfig.autoScale }],
    });
    listMainAxis: TagList[];
    listSecondaryAxis: TagList[];
    exceptionAxis = MessagesEnum.ExceptionAxis;
    includesAxis = MessagesEnum.IncludesAxis;
    erroMessage = MessagesEnum.ErroMessage;
    limiteAxisErro = MessagesEnum.LimiteAxisErro;
    setPointDefault = MessagesEnum.SetPointDefault;
    dialogRefMsg: any;

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private _formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<SetpointsTrackingSettingsComponent>,
        private tagService: TagService,
        @Inject(MAT_DIALOG_DATA)
        public axisConfig: AxisConfig,
        public dialog: MatDialog,
        private setpointService: SetPointService
    ) {}

    ngOnInit() {
        this.loadSelectAndInputsData();
        const formFilterArray = ['filterMainAxis', 'filterSecondaryAxis'];
        const filterOptions = {
            filterMainAxis: 'mainAxisOptions',
            filterSecondaryAxis: 'secondaryAxisOptions',
        };
        formFilterArray.forEach((filterAxis) => {
            this.chartFrom
                .get(filterAxis)!
                .valueChanges.subscribe(
                    (value) => (this[filterOptions[filterAxis]] = this._filterGroup(value || '', filterAxis))
                );
        });
        this.chartFrom.get('toggleAutoScale')!.valueChanges.subscribe((isDisabled) => {
            const formArray = ['mainAxisY1', 'mainAxisY2', 'secondaryAxisY1', 'secondaryAxisY2'];
            if (isDisabled) {
                formArray.forEach((form) => this.chartFrom.get(form).disable());
            } else {
                formArray.forEach((form) => this.chartFrom.get(form).enable());
            }
        });
    }

    createAutoComplete() {
        this.mainAxisOptions = this.listMainAxis;
        this.secondaryAxisOptions = this.listSecondaryAxis;
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        const chartFromObject = this.chartFrom.getRawValue();
        if (this.isValidateCreateAxisConfig(chartFromObject)) {
            const axisConfig = this.createAxisConfig(chartFromObject);
            return this.dialogRef.close(axisConfig);
        }

        const conf = this.defaultDialog;
        conf.data.message = this.erroMessage;
        return this.openDialog(conf);
    }

    _getVariableTypeFromTagId(tagId): VariableTypeEnum {
        const foundType =
            this.listMainAxis.find((t) => t.tags.find((tag) => tag.id == tagId)) ||
            this.listSecondaryAxis.find((t) => t.tags.find((tag) => tag.id == tagId));
        const entries = Object.entries(variableTypes).find((t) => t[1] == foundType.variable);
        const vtEnum = Object.entries(VariableTypeEnum).find((t) => t[1] == entries[0]);
        return VariableTypeEnum[vtEnum[0]];
    }

    createAxisConfig(chartFromObject) {
        const mainAxis = this.mainAxis.map((t) => ({
            axis: 'first' as AxisType,
            type: this._getVariableTypeFromTagId(t.id),
            setpoint: this._getSetpointFromTag(t),
            ...t,
        }));

        const secondaryAxis = this.secondaryAxis.map((t) => ({
            axis: 'second' as AxisType,
            type: this._getVariableTypeFromTagId(t.id),
            setpoint: this._getSetpointFromTag(t),
            ...t,
        }));

        let axisConfig: AxisConfig = {
            autoScale: chartFromObject.toggleAutoScale,
            output: this.axisConfig.output,
            tags: mainAxis.concat(secondaryAxis),
            mainLimits: {
                min: chartFromObject.mainAxisY1,
                max: chartFromObject.mainAxisY2,
            },
            secondLimits: {
                min: chartFromObject.secondaryAxisY1,
                max: chartFromObject.secondaryAxisY2,
            },
        };

        return axisConfig;
    }
    private _getSetpointFromTag(tag: Tag): any {
        return this.setpointsList.find((sp) => getCycleOutputTagId(sp) == tag.id);
    }

    isValidateCreateAxisConfig(chartFromObject) {
        return (
            chartFromObject.toggleAutoScale ||
            chartFromObject.mainAxisY1 < chartFromObject.mainAxisY2 ||
            chartFromObject.secondaryAxisY1 < chartFromObject.secondaryAxisY2
        );
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
    }

    private _filterGroup(value: string, filterName: string): TagList[] {
        const list = filterName == 'filterMainAxis' ? this.listMainAxis : this.listSecondaryAxis;
        if (value) {
            return list
                .map((group) => ({ variable: group.variable, tags: filter(group.tags, value) }))
                .filter((group) => group.tags.length > 0);
        }

        return list;
    }

    checkForErrors(tag: Tag, isMainAxis: boolean): string | null {
        const axis = isMainAxis ? this.mainAxis : this.secondaryAxis;
        const axisAux = !isMainAxis ? this.mainAxis : this.secondaryAxis;
        const axisSize = axis.length + axisAux.length;

        if (tag.name === getCycleOutputTagName(this.axisConfig.output)) {
            return this.setPointDefault;
        }

        if (axis.some((e) => e.id === tag.id)) {
            return this.includesAxis;
        }

        if (axisAux.some((e) => e.id === tag.id)) {
            return this.exceptionAxis;
        }

        if (axisSize >= 7) {
            return this.limiteAxisErro;
        }

        return null;
    }

    addMainAxis(tag: Tag): void {
        const conf = this.defaultDialog;
        const error = this.checkForErrors(tag, true);
        if (error) {
            conf.data.message = error;
            return this.openDialog(conf);
        }
        this.mainAxis.push(tag);
    }

    addSecondaryAxis(tag: Tag): void {
        const conf = this.defaultDialog;
        const error = this.checkForErrors(tag, false);
        if (error) {
            conf.data.message = error;
            return this.openDialog(conf);
        }

        this.secondaryAxis.push(tag);

        this.secondaryAxis = [...new Set(this.secondaryAxis)];
    }

    removeMainAxis(tag: Tag): void {
        const index = this.mainAxis.indexOf(tag);

        if (index >= 0) {
            this.mainAxis.splice(index, 1);
        }
    }

    removeSecondaryAxis(tag: Tag): void {
        const index = this.secondaryAxis.indexOf(tag);

        if (index >= 0) {
            this.secondaryAxis.splice(index, 1);
        }
    }

    private _isToAddPvTag(setpoint: IncrementSetpoint): boolean {
        if (getCycleOutputType(setpoint) != CycleOutputType.SETPOINT || !Boolean(setpoint.pv_tag)) {
            return false;
        }
        return this.axisConfig?.tags?.some((t) => t.id == setpoint.pv_tag.id);
    }

    private _isToAddMVTag(setpoint: IncrementSetpoint): boolean {
        if (getCycleOutputType(setpoint) != CycleOutputType.SETPOINT || !Boolean(setpoint.mv_tag)) {
            return false;
        }
        return this.axisConfig?.tags?.some((t) => t.id == setpoint.mv_tag.id);
    }

    addPvToAxis(setpoint: IncrementSetpoint): void {
        if (this._isToAddPvTag(setpoint)) {
            this.addMainAxis(setpoint.pv_tag);
        }
    }

    addMvToAxis(setpoint: IncrementSetpoint): void {
        if (this._isToAddMVTag(setpoint)) {
            this.addSecondaryAxis(setpoint.mv_tag);
        }
    }

    async loadSelectAndInputsData() {
        const observables: Array<Observable<Tag[]>> = [
            this.tagService.getAllTagsByType(VariableTypeEnum.FUZZY),
            this.tagService.getAllTagsByType(VariableTypeEnum.PROCESS),
            this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION),
            this.tagService.getAllTagsByType(VariableTypeEnum.WEIGHTED),
            this.tagService.getAllTagsByType(VariableTypeEnum.CALCULATED),
            this.tagService.getAllTagsByType(VariableTypeEnum.SETPOINT),
            this.tagService.getAllTagsByType(VariableTypeEnum.MANIPULATED_VARIABLE),
        ];

        let tagNames;
        const setpointOptions = async (): Promise<IncrementSetpoint[]> => {
            let allSetpoints = this.setpointService.getAllIncrementSetPoints().toPromise();
            allSetpoints.then((response) => {
                this.setpointsList = response;
                tagNames = response.map((sp) => sp.new_sp_tag.name);
            });
            return allSetpoints;
        };
        await Promise.all([setpointOptions()]);

        this.setpoint = this.setpointsList.find(
            (sp) => sp.new_sp_tag.name == getCycleOutputTagName(this.axisConfig.output)
        );

        this.addPvToAxis(this.setpoint);

        this.addMvToAxis(this.setpoint);

        combineLatest(observables)
            .pipe(map((matrixTags) => matrixTags.filter((tagArray) => tagArray.length > 0).map(handleTags)))
            .subscribe((tagsByVariable) => {
                const set = [];
                for (const names of tagNames) {
                    for (const tagList of Object.values(tagsByVariable)) {
                        const matchingTags = tagList.tags.filter((tag) => tag.name === names);
                        set.push(...matchingTags);
                        tagList.tags = tagList.tags.filter((tag) => tag.name !== names);
                    }
                }
                const setpointVariable = variableTypes['setpoint'];
                const setpointTags = { variable: setpointVariable, tags: set };
                this.listMainAxis = [...Object.values(tagsByVariable), setpointTags];
                this.listSecondaryAxis = [...Object.values(tagsByVariable), setpointTags];
                this.createAutoComplete();
            });

        const addToAxis = (tag: Tag, axisType: AxisType) => {
            if (axisType === 'first') {
                this.mainAxis.push({ ...tag });
            }
            if (axisType === 'second') {
                this.secondaryAxis.push({ ...tag });
            }
        };

        const handleTags = (tagArray: Tag[]): { variable: string; tags: Tag[] } => {
            tagArray.forEach((tag) => {
                if (tag.name === getCycleOutputTagName(this.axisConfig.output)) {
                    this.mainAxis.unshift({ ...tag, isDisabled: true });
                }
                if (this.axisConfig.tags) {
                    this.axisConfig.tags.forEach((tagAxis) => {
                        if (tag.name === tagAxis.name && tag.name !== getCycleOutputTagName(this.axisConfig.output)) {
                            addToAxis(tag, tagAxis.axis);
                        }
                    });
                }
            });

            return { variable: variableTypes[tagArray.first().variableType.name], tags: tagArray };
        };
    }
}
