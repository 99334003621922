import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getUserData } from 'src/app/shared/utils/userRole';
import { LoginService } from 'src/app/views/login/login.service';
import { MessagesEnum } from '../../models/enum/messages.enum';
import { Project } from '../../models/views-models/project.model';
import { ProjectSettings } from '../../models/views-models/projectSettings.model';
import { ProjectStatus } from '../../models/views-models/projectStatus.model';
import { LayoutService } from '../../service/layout/layout.service';
import { ThemeService } from '../../service/theme/theme.service';
import { CurrentProjectService } from '../../service/views-services/current-project.service';
import { LocalStorageService } from '../../service/views-services/localstorage.service';
import { ProjectService } from '../../service/views-services/project.service';
import { UserService } from '../../service/views-services/user.service';
import { formatDateObj } from '../../utils/date.utils';
import { Views } from '../../utils/views-urls';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { AdminSettingsComponent } from '../views-components/admin-settings/admin-settings.component';
import { ProjectSettingsComponent } from '../views-components/project-settings/project-settings.component';
import { UserDialogComponent } from '../views-components/user-dialog/user-dialog.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() sidenav: MatSidenav;

    public egretThemes;
    public layoutConf: any;
    userAuthenticated: any;

    pageTitle: string;
    currentProject: string;
    dialogRef: any;
    user: any;
    currentUser: any;

    projects: Project[];
    project: Project;
    projectStatus: ProjectStatus = new ProjectStatus();
    projectSettings: ProjectSettings = new ProjectSettings();
    isProjectOn: boolean = false;
    isProjectApt: boolean = false;
    subs: Array<Subscription> = [];

    showFiller = false;
    projectVersions: Project[];
    isDeploing: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private themeService: ThemeService,
        private loginService: LoginService,
        private projectService: ProjectService,
        private userService: UserService,
        private lsService: LocalStorageService,
        private layout: LayoutService,
        public dialog: MatDialog,
        public currentProjectService: CurrentProjectService,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.updateDefaultProject(false);

        this.subs.push(
            this.currentProjectService.observeCurrentProject().subscribe((project) => {
                if (this.project?.id != project?.id) {
                    this.getSessionData();
                    this.validadeReloadQuaterback();
                }
            })
        );
        this.getSessionData();
        this.pageTitle = this.route.snapshot.firstChild.data['title'];
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.subs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.router.navigated = false;
                }
            })
        );
        this.validadeReloadQuaterback();
    }

    validadeReloadQuaterback() {
        let reloadQuaterback = this.lsService.getQuaterbackReload();
        if (reloadQuaterback && reloadQuaterback.reload) {
            let lastDateUpdate = new Date(reloadQuaterback.data);
            lastDateUpdate.setSeconds(lastDateUpdate.getSeconds() + 10);
            if (lastDateUpdate < new Date()) {
                this.reloadQuaterback();
                this.markQuaterbackAsNotReload();
            }
        }
    }

    getAllProjects() {
        this.subs.push(
            this.projectService.getAllProjects().subscribe((projects) => {
                this.projects = projects;
                if (projects.length == 0) {
                    this.lsService.clean(this.project);
                }
            })
        );
    }

    getSessionData() {
        this.user = getUserData();

        if (this.validateCurrentProject()) {
            const currentProject = this.currentProjectService.getCurrentProject();

            this.currentProject = currentProject.name;
            this.project = currentProject;
        }
    }

    /**
     * Validate whether project is present in User object. If not, search the the default project
     */
    validateCurrentProject() {
        if (!this.currentProjectService.hasCurrentProject()) {
            this.updateDefaultProject(true);
            return false;
        }
        return true;
    }

    updateDefaultProject(forceUpdate: boolean) {
        if (this.lsService.hasDefaultProject()) {
            const currentProject = this.lsService.getDefaultProject();

            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                const projectVersions = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');

                const defaultProject =
                    projectVersions.find((p) => p.versionType == currentProject.versionType) || currentProject;

                this.project = defaultProject;
                if (forceUpdate || !this.currentProjectService.hasCurrentProject()) {
                    this.updateProjectDefault(defaultProject);
                }
                this.getProjectVersions();
            });
        } else {
            const subscribed = this.projectService.getDefaultProject().subscribe((project) => {
                this.project = project;
                if (forceUpdate) {
                    this.updateProjectDefault(project);
                }
                this.getProjectVersions();
            });

            this.subs.push(subscribed);
        }
    }

    getProjectStatus() {
        const runningProject = this.projectVersions?.find((p) => p?.versionType == 'RUNNING');
        if (runningProject && runningProject.id) {
            this.subs.push(
                this.projectService.getProjectStatus(runningProject.id).subscribe((response) => {
                    this.projectStatus = response;
                })
            );
        }
    }

    selectProject(project: Project) {
        project.projectDefault = true;
        this.subs.push(
            this.projectService.setProjectDefault(project).subscribe((response) => {
                this.project = response;
                this.updateProjectDefault(response);
                this.currentProjectService.goReload();
            })
        );
    }

    updateProjectDefault(project: Project) {
        this.currentProject = this.project.name;
        this.lsService.reset(this.project);
        this.lsService.triggerLocalStorageChanges(project);
        this.currentProjectService.setCurrentProject(project);
    }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
    }

    deploySettings() {
        let conf = {
            component: ConfirmDialogComponent,
            panelClass: 'pop-up-dialog-container',
            data: {
                title: 'Confirmação de Deploy',
                message: MessagesEnum.confirmDeployMessage + this.currentProject + '?',
                cancelAvailable: true,
                confirmButton: 'DEPLOY',
            },
        };
        this.openDialog(conf);

        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.startDeploy();
                this.projectService.deploySettings().subscribe({
                    next: (system) => {
                        if (system) {
                            this.markQuaterbackAsReload();
                            this.projectService.reloadQuaterback().subscribe((isReload) => {
                                conf.data.title = '';
                                conf.data.cancelAvailable = false;
                                conf.data.confirmButton = '';
                                conf.data.message = isReload
                                    ? MessagesEnum.deployMessage
                                    : MessagesEnum.quaterbackError;
                                this.openDialog(conf);
                            });
                        }
                        this.endDeploy();
                        this.updateDeployProjects();
                    },
                    error: (err) => {
                        this.endDeploy();
                        conf.data.title = '';
                        conf.data.cancelAvailable = false;
                        conf.data.confirmButton = '';
                        conf.data.message = 'Erro ao realizar deploy';
                        console.error(err);
                        this.openDialog(conf);
                    },
                });
            }
        });
    }

    updateDeployProjects() {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                let buildingProject = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .find((v) => v?.versionType == 'BUILDING');

                this.currentProjectService.setCurrentProject(buildingProject);
                this.currentProjectService.goReload();
            });
        }
    }

    startDeploy() {
        this.isDeploing = true;
    }

    endDeploy() {
        this.isDeploing = false;
    }
    markQuaterbackAsReload() {
        this.lsService.setQuaterbackReload({
            reload: true,
            data: new Date(),
        });
    }

    markQuaterbackAsNotReload() {
        this.lsService.setQuaterbackReload({
            reload: false,
            data: new Date(),
        });
    }

    reloadQuaterback() {
        this.projectService.reloadQuaterback().subscribe((_) => {});
    }

    adminSettings() {
        let conf = {
            component: AdminSettingsComponent,
            data: '',
            panelClass: 'adminSettingsModal',
        };
        this.openDialog(conf);
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            minWidth: options.minWidth ? options.minWidth : 'auto',
            height: 'auto',
            data: options.data,
        });
    }

    navigateHome() {
        this.router.navigate(['entradas']);
    }

    changeTheme(theme) {
        this.themeService.changeTheme(this.renderer, theme);
    }

    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
            });
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed',
        });
    }

    onLogout() {
        this.loginService.logout();
        window.location.assign('/login');
    }

    openUserSettings() {
        let userDialog = {
            component: UserDialogComponent,
            minWidth: '700px',
            data: '',
        };
        this.userService.getUserByUsername(this.user.username).subscribe((user) => {
            if (user && this.user && user.username == this.user.username) {
                this.currentUser = user;
                userDialog.data = this.currentUser;
                this.openDialog(userDialog);
            }
        });
    }

    editProjectSettings() {
        const currentProject = this.currentProjectService.getCurrentProject();
        this.projectService.getProjectSettings(currentProject.id).subscribe((response) => {
            this.projectSettings = response;
            let conf = {
                component: ProjectSettingsComponent,
                panelClass: 'custom-dialog-container',
                data: this.projectSettings,
            };
            this.openDialog(conf);
        });
    }

    navigateToUsers() {
        this.router.navigate([Views.usersList.url]);
    }

    isDeployDisabled() {
        const project = this.currentProjectService.getCurrentProject();
        return this.projectVersions?.length > 1 && project?.versionType == 'RUNNING';
    }

    isBuilding() {
        const project = this.currentProjectService.getCurrentProject();
        return this.projectVersions?.length > 1 && project?.versionType == 'BUILDING';
    }

    getProjectVersions() {
        const currentProject = this.currentProjectService.getCurrentProject();
        if (currentProject && currentProject.mainProjectId) {
            this.projectService.getProjectVersions(currentProject.mainProjectId).subscribe((versions) => {
                this.projectVersions = versions
                    .map((v) => {
                        return { ...v, created: formatDateObj(new Date(v.created)) };
                    })
                    .filter((v) => v?.versionType == 'BUILDING' || v?.versionType == 'RUNNING');

                this.getProjectStatus();
            });
        }
    }
}
