import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../service/navigation/navigation.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit, OnDestroy, AfterViewInit {
    private sidebarPS: PerfectScrollbar;
    private menuItemsSub: Subscription;

    public menuItems: any[];

    constructor(private navService: NavigationService) {}

    ngOnInit() {
        this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
            this.menuItems = menuItem.filter((item) => item.type !== 'icon' && item.type !== 'separator');
        });
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.sidebarPS = new PerfectScrollbar('#sidebar-top-scroll-area', {
                suppressScrollX: true,
            });
        });
    }
    ngOnDestroy() {
        if (this.sidebarPS) {
            this.sidebarPS.destroy();
        }
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }
}
