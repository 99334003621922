export enum MessagesEnum {
    SuccessMessage = 'Dados registrados com sucesso!',
    SuccessEditMessage = 'Dados atualizados com sucesso!',
    DeleteMessage = 'Dados deletados com sucesso!',
    FailureNameMessage = 'Este nome já está um uso',
    invalidFormMessage = 'Preencha todos os dados',
    defaultDeleteMessage = 'Deseja excluir este item permanentemente?',
    manyDeleteMessage = 'Deseja excluir estes itens permanentemente?',
    restrictNameMessage = 'Os prefixos GRD e AVG são reservados ao sistema, por favor escolha outro nome',
    equationAdded = 'Equação adicionada ao setpoint',
    conditionsAdded = 'Condições adicionadas ao setpoint',
    invalidLimits = 'O valor máximo deve ser maior que o mínimo',
    invalidParameters = 'Parâmetros inválidos, verifique os valores de mínimo, máximo e delta',
    intNumberRequired = 'O valor para o campo ciclos deve ser um número inteiro',
    noDataFound = 'Não foram encontrados resultados para esta variável neste intervalo de tempo',
    invalidDate = 'Intervalo de tempo inválido',
    formulaEquationRequired = 'É necessário o preenchimento do campo equação',
    invalidRule = 'A Regra de controle inserida não existe.',
    invalidSP = 'O setpoint inserido não existe.',
    deployMessage = 'Deploy realizado com sucesso!',
    quaterbackError = 'Deploy realizado, mas o quaterback não foi reiniciado!',

    confirmDeployMessage = 'Você confirma o deploy no projeto ',
    existsMV = 'Não é possível desativar o controle por MV, pois está sendo usada como TAG atual nos setpoints.',
    fuzzySetChangeMessage = 'Ao alterar o conjunto Fuzzy de um antecedente ou inserir/remover uma variável GRD, a Matriz de Controle anterior será zerada e será necessário que salve a alteração para criar uma nova matriz',
    conditionsDeleted = 'Condições removidas do setpoint',
    passwordMatchFail = 'As senhas digitadas não conferem',
    passwordChangeConfirm = 'A senha será alterada, deseja continuar?',
    changeProjectStatus = 'A mudança de estado do projeto ocorrerá após deploy',
    emptyConditions = 'Não existem condições cadastradas para esse setpoint',
    invalidEquation = 'Equação inválida, revise os operadores e operandos',
    Exception = 'Erro Desconhecido',
    OpcInUse = 'Este servidor OPC não pode ser deletado, pois, está associado a entradas do sistema',
    RemoveFailed = 'Erro ao tentar deletar servidor',
    ExceptionAxis = 'Não pode ter váriaveis iguais nos dois eixos!',
    IncludesAxis = 'Já está referenciado a este eixo',
    ErroMessage = 'Erro ao registrar dados!',
    LimiteAxisErro = 'Limite de variáveis atigindo!',
    SetPointDefault = 'Este Setpoint é o padrão',
    restoreMessage = 'Restauração realizada com sucesso!',
    uploadMessage = 'Upload realizada com sucesso!',
}
