import { CurveType } from 'src/app/shared/models/views-models/curveType.model';
import { Process } from './process.model';
import { FuzzySet } from './fuzzySet.model';
import { Tag } from './tag.model';

export class Antecedent {
    id: string;
    tag: Tag = new Tag();
    fuzzySet: FuzzySet = new FuzzySet();
    curveType: CurveType = new CurveType();
    top: number;
}
