<mat-card appearance="outlined" class="container">
    <app-date-navigator
        [navigatorPlaceholder]="navigatorPlaceholder"
        [tagNames]="tagNames"
        [redirectedTag]="selectedTagName"
        (dateChanges)="getChartData($event)"
    ></app-date-navigator>
</mat-card>
<div class="chart-wrapper" *ngIf="loadChart">
    <div *ngIf="isMinimized" class="info-parameters">
        <h1>Filtro</h1>
        <mat-label [ngClass]="{ 'label-active': isFilterActive, 'label-inactive': !isFilterActive }">
            Alfa:
            <span>{{ alpha }}</span>
        </mat-label>
        <mat-label [ngClass]="{ 'label-active': isFilterActive, 'label-inactive': !isFilterActive }">
            Máx. Fuzzy:
            <span>{{ max }}</span>
        </mat-label>
        <mat-label [ngClass]="{ 'label-active': isFilterActive, 'label-inactive': !isFilterActive }">
            Min. Fuzzy:
            <span>{{ min }}</span>
        </mat-label>
        <div class="footer-container">
            <mat-divider></mat-divider>
        </div>
        <h1>Gradiente</h1>
        <mat-label [ngClass]="{ 'label-active': isGradientActive, 'label-inactive': !isGradientActive }">
            Delta (seg):
            <span>{{ delta }}</span>
        </mat-label>
        <mat-label [ngClass]="{ 'label-active': isGradientActive, 'label-inactive': !isGradientActive }">
            Var. Max:
            <span>{{ max_variation }}</span>
        </mat-label>
        <div class="footer-container">
            <mat-divider></mat-divider>
        </div>
        <h1>Desvio Padrão</h1>
        <mat-label
            [ngClass]="{ 'label-active': isStandardDeviationActive, 'label-inactive': !isStandardDeviationActive }"
        >
            Delta (seg):
            <span>{{ delta_std }}</span>
        </mat-label>
        <mat-label
            [ngClass]="{ 'label-active': isStandardDeviationActive, 'label-inactive': !isStandardDeviationActive }"
        >
            Máx. Fuzzy:
            <span>{{ max_std }}</span>
        </mat-label>
        <mat-label
            [ngClass]="{ 'label-active': isStandardDeviationActive, 'label-inactive': !isStandardDeviationActive }"
        >
            Min. Fuzzy:
            <span>{{ min_std }}</span>
        </mat-label>
    </div>
    <button *ngIf="isMinimized && isShow()" mat-fab class="blue-button-chart" (click)="isToExtend(true)">
        <mat-icon class="icon-chart">tune</mat-icon>
    </button>
    <form [formGroup]="dataTreatmentForm">
        <div *ngIf="!isMinimized" class="info-parameters-extend">
            <div class="top-container">
                <h1>Filtro</h1>
            </div>
            <div class="bottom-container">
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Alfa</mat-label>
                    <input matInput type="number" step=".01" min="0" max="1" formControlName="alpha" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Max. Fuzzy</mat-label>
                    <input matInput type="number" formControlName="max" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Min. Fuzzy</mat-label>
                    <input matInput type="number" formControlName="min" />
                </mat-form-field>
            </div>
            <div class="footer-container">
                <mat-divider></mat-divider>
            </div>
            <div class="top-container">
                <h1>Gradiente</h1>
            </div>
            <div class="bottom-container">
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Delta (seg)</mat-label>
                    <input matInput type="number" step="1" min="1" formControlName="delta" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Variação Max.</mat-label>
                    <input matInput type="number" min="0" formControlName="max_variation" />
                </mat-form-field>
            </div>
            <div class="footer-container">
                <mat-divider></mat-divider>
            </div>
            <div class="top-container">
                <h1>Desvio Padrão</h1>
            </div>
            <div class="bottom-container">
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Delta (seg)</mat-label>
                    <input matInput type="number" formControlName="delta_std" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Max. Fuzzy</mat-label>
                    <input matInput type="number" formControlName="max_std" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="date no-padding input-number">
                    <mat-label>Min. Fuzzy</mat-label>
                    <input matInput type="number" formControlName="min_std" />
                </mat-form-field>
            </div>
            <div class="footer-container">
                <mat-divider></mat-divider>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
                <button mat-button class="cancel-button" type="button" (click)="isToExtend(false)">CANCELAR</button>
                <button mat-button class="simulate-button" type="button" *ngIf="!isSimulated" (click)="simulateChart()">
                    SIMULAR
                </button>
                <button mat-button class="restore-button" type="button" *ngIf="isSimulated" (click)="resetParameters()">
                    <mat-icon class="restore-icon">settings_backup_restore</mat-icon>
                    RESTAURAR
                </button>
                <button
                    mat-button
                    [disabled]="!isSimulated"
                    class="save-button"
                    type="button"
                    (click)="isToExtend(false)"
                    (click)="saveParameters()"
                >
                    SALVAR
                </button>
            </div>
        </div>
    </form>
    <div
        *ngIf="isDataPresent"
        echarts
        [options]="chartOption"
        [merge]="mergeOptions"
        [ngClass]="{ 'chart-container': isMinimized, 'chart-container-minimized': !isMinimized }"
        [initOpts]="initOpts"
    ></div>
    <div class="chart-text no-content" *ngIf="!isDataPresent">
        <div>Sem dados coletados para o período</div>
    </div>
</div>
