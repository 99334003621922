import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { BehaviorSubject } from 'rxjs';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { CurveType } from 'src/app/shared/models/views-models/curveType.model';
import { Fuzzy } from 'src/app/shared/models/views-models/fuzzy.model';
import { FuzzySet } from 'src/app/shared/models/views-models/fuzzySet.model';
import { Matrix } from 'src/app/shared/models/views-models/matrix.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
import { CurveTypeService } from 'src/app/shared/service/views-services/curveType.service';
import { FuzzyService } from 'src/app/shared/service/views-services/fuzzy.service';
import { FuzzySetService } from 'src/app/shared/service/views-services/fuzzySet.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../shared/components/dialogs/delete-dialog/delete-dialog.component';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { Point } from 'src/app/shared/models/internal/point';
import { Line } from 'src/app/shared/models/internal/line';

@Component({
    selector: 'app-add-control-rule',
    templateUrl: './add-control-rule.component.html',
    styleUrls: ['./add-control-rule.component.scss'],
})
export class AddControlRuleComponent implements OnInit {
    private bodyPS: PerfectScrollbar;
    successMessage = MessagesEnum.SuccessMessage;
    alertMessage = 'Favor preencher todos os dados dos antecedentes';
    failureMessage = MessagesEnum.FailureNameMessage;
    deleteMessage = MessagesEnum.DeleteMessage;
    invalidLimits = MessagesEnum.invalidLimits;

    fuzzyForm: UntypedFormGroup;
    isLoaded = false;

    fuzzy: Fuzzy = new Fuzzy();
    tag: Tag = new Tag();
    isEditing = false;
    user: any;
    matrixAvailable = false;
    dialogRefMsg: any;

    tags: Array<Tag> = [];
    processTags = [];
    antecedentsNames = [];
    fuzzySets: FuzzySet[];
    curveTypes: CurveType[];
    data: Fuzzy;
    selectedRuleId: string;

    _$fuzzy: BehaviorSubject<Fuzzy> = new BehaviorSubject<Fuzzy>(new Fuzzy());
    showMaxLabel = false;
    showMinLabel = false;
    matrix = new Matrix();

    expanded: boolean = false;

    outFuzzySets = [
        { description: '3 Funções de Pertinência', value: 3 },
        { description: '5 Funções de Pertinência', value: 5 },
        { description: '7 Funções de Pertinência', value: 7 },
    ];

    conf = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    baseColors = [
        '#BBBBBB',
        '#ffac00',
        '#fc3d55',
        '#009fd7',
        '#7B00FF',
        '#00D99D',
        '#350094',
        '#006bd3',
        '#c70160',
        '#ff692c',
        '#666666',
    ];

    antecedente1Option = {
        animation: false,
        tooltip: {
            show: true,
            trigger: 'axis',
            valueFormatter: (value) => parseFloat(value.toFixed(2)),
        },
        xAxis: {
            name: 'x',
            type: 'value',
            min: 'dataMin',
            max: 'dataMax',
            triggerEvent: true,
        },
        yAxis: {
            name: 'y',
        },
    };

    antecedente2Option = {
        animation: false,
        tooltip: {
            show: true,
            trigger: 'axis',
            valueFormatter: (value) => value.toFixed(2),
        },
        xAxis: {
            name: 'x',
            type: 'value',
            min: 'dataMin',
            max: 'dataMax',
        },
        yAxis: {
            name: 'y',
        },
    };

    consequenteOption = {
        animation: false,
        tooltip: {
            show: true,
            trigger: 'axis',
            valueFormatter: (value) => value.toFixed(2),
        },
        xAxis: {
            name: 'x',
            type: 'value',
            min: 'dataMin',
            max: 'dataMax',
        },
        yAxis: {
            name: 'y',
        },
    };

    initOpts = {
        height: 400,
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private fuzzyService: FuzzyService,
        private tagService: TagService,
        private fuzzySetService: FuzzySetService,
        private curveTypeService: CurveTypeService,
        private activatedRouter: ActivatedRoute,
        private currentProjectService: CurrentProjectService,
        private router: Router
    ) {}

    async ngOnInit() {
        this.user = getUserData();
        this.initForm();
        this.selectedRuleId = this.activatedRouter.snapshot?.fragment;
        if (this.selectedRuleId) {
            this.data = await this.fuzzyService.getFuzzy(this.selectedRuleId).toPromise();
        }
        await this.getCurveOptions();
        this.getTags();
        this.loadScroll();
        this.fuzzyForm
            .get('antecedente1')
            .valueChanges.subscribe(() =>
                this.makeChart(this.fuzzyForm.get('antecedente1').getRawValue(), 'antecedente1Option')
            );
        this.fuzzyForm
            .get('antecedente2')
            .valueChanges.subscribe(() =>
                this.makeChart(this.fuzzyForm.get('antecedente2').getRawValue(), 'antecedente2Option')
            );
        this.fuzzyForm
            .get('min')
            .valueChanges.subscribe(() => this.makeChart(this.fuzzyForm.getRawValue(), 'consequenteOption'));
        this.fuzzyForm
            .get('max')
            .valueChanges.subscribe(() => this.makeChart(this.fuzzyForm.getRawValue(), 'consequenteOption'));
        this.fuzzyForm
            .get('top')
            .valueChanges.subscribe(() => this.makeChart(this.fuzzyForm.getRawValue(), 'consequenteOption'));
        this.fuzzyForm
            .get('matrix')
            .get('outFuzzySet')
            .valueChanges.subscribe(() => this.makeChart(this.fuzzyForm.getRawValue(), 'consequenteOption'));
    }

    initForm() {
        this.fuzzyForm = this.formBuilder.group({
            inputName: [null, [Validators.required, Validators.minLength(1)]],
            inputDesc: [null, [Validators.required, Validators.minLength(1)]],
            min: [{ value: '-100', disabled: false }, Validators.required],
            max: [{ value: '100', disabled: false }, Validators.required],
            curveType: [{ value: '', disabled: true }, Validators.required],
            top: [{ value: 0, disabled: true }, [Validators.required, Validators.max(100), Validators.min(0)]],
            antecedente1: this.formBuilder.group({
                dataType: [null],
                variableType: [null],
                fuzzySet: [null, Validators.required],
                curveType: [{ value: '', disabled: true }, Validators.required],
                entryName: [null, Validators.required],
                top: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
                min: [{ value: '', disabled: false }, Validators.required],
                max: [{ value: '', disabled: false }, Validators.required],
            }),
            antecedente2: this.formBuilder.group({
                dataType: [null],
                variableType: [null],
                fuzzySet: [null, Validators.required],
                curveType: [{ value: '', disabled: true }, Validators.required],
                entryName: [null, Validators.required],
                top: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
                min: [{ value: '', disabled: false }, Validators.required],
                max: [{ value: '', disabled: false }, Validators.required],
            }),
            matrix: this.formBuilder.group({
                outFuzzySet: [5, Validators.required],
                veryHigh: this.formBuilder.array([]),
                high: this.formBuilder.array([]),
                normal: this.formBuilder.array([]),
                low: this.formBuilder.array([]),
                veryLow: this.formBuilder.array([]),
                goingUp: this.formBuilder.array([]),
                stable: this.formBuilder.array([]),
                fallingDown: this.formBuilder.array([]),
            }),
        });
        if (!this.user.permissions.canUpdate || this.isShowForm()) {
            this.fuzzyForm.disable();
        }
    }

    getFuzzy() {
        return this.fuzzy;
    }

    async getCurveOptions() {
        await this.fuzzySetService.findAllFuzzySets().subscribe((fuzzys) => {
            this.fuzzySets = fuzzys;
            this.makeChart(this.fuzzyForm.getRawValue(), 'consequenteOption');
        });
        await this.curveTypeService.findAllCurveTypes().subscribe((curves) => {
            this.curveTypes = curves;
            this.setDefaultFields();
        });
    }

    async getTags() {
        const waitForFuzzy = async (): Promise<Tag[]> => {
            let fuzzyList = this.tagService.getAllTagsByType(VariableTypeEnum.FUZZY).toPromise();
            fuzzyList.then((fuzzy) => this.tags.push(...fuzzy));
            return fuzzyList;
        };
        const waitForWeighted = async (): Promise<Tag[]> => {
            let weightedList = this.tagService.getAllTagsByType(VariableTypeEnum.WEIGHTED).toPromise();
            weightedList.then((weighted) => this.tags.push(...weighted));
            return weightedList;
        };
        const waitForCalculated = async (): Promise<Tag[]> => {
            let calculatedList = this.tagService.getAllTagsByType(VariableTypeEnum.CALCULATED).toPromise();
            calculatedList.then((calculated) => this.tags.push(...calculated));
            return calculatedList;
        };

        const waitForEquations = async (): Promise<Tag[]> => {
            let equationList = this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION).toPromise();
            equationList.then((equation) => this.tags.push(...equation));
            return equationList;
        };
        const waitForProcess = async (): Promise<Tag[]> => {
            let processList = this.tagService.getAllTagsByType(VariableTypeEnum.PROCESS).toPromise();
            processList.then((process) => (this.processTags = process));
            return processList;
        };
        await Promise.all([
            waitForFuzzy(),
            waitForWeighted(),
            waitForCalculated(),
            waitForProcess(),
            waitForEquations(),
        ]);
        if (this.data) {
            this.createEditForm(this.data);
            this.initMatrix();
        } else {
            this.getAntecedentsNames();
            this.matrixAvailable = true;
        }
    }

    loadScroll() {
        if (this.bodyPS) {
            this.bodyPS.destroy();
        }
        this.bodyPS = new PerfectScrollbar('.container', {
            suppressScrollX: true,
        });
    }

    makeChart(value, merge) {
        const min = value.min !== '' ? +value.min : 0;
        const max = value.max !== '' ? +value.max : 100;
        const top = value.top !== null ? value.top / 100 : 0.5;
        const fuzzySet = value.fuzzySet !== null ? this.findValueFuzzy(value.fuzzySet) : 3;
        const outFuzzySet = this.fuzzyForm.get('matrix').get('outFuzzySet').value;
        // console.log('fuzzySet', fuzzySet);
        // console.log('outFuzzySet', outFuzzySet);
        // console.log('merge', merge);
        merge === 'consequenteOption'
            ? this.makeSets(min, max, outFuzzySet, top, merge)
            : this.makeSets(min, max, fuzzySet, top, merge);
    }

    findValueFuzzy(id) {
        let value;
        this.fuzzySets.find((fuzzySet) => {
            if (fuzzySet.id === id) value = fuzzySet.description === '5 Funções de Pertinência' ? 5 : 3;
        });
        return value;
    }

    makeSets(min, max, numberOfSets, baseRatio, merge) {
        const antecedente1 = this.fuzzyForm.get('antecedente1').getRawValue();
        const antecedente2 = this.fuzzyForm.get('antecedente2').getRawValue();
        const fiveValorHeader = ['Muito Alto', 'Alto', 'Normal', 'Baixo', 'Muito Baixo'];
        const threeValorHeader = ['Alto', 'Normal', 'Baixo'];
        const threeTendenceHeader = ['Aumentando', 'Estável', 'Diminuindo'];

        let names = [];

        if (merge !== 'consequenteOption') {
            if (numberOfSets === 5) {
                names = fiveValorHeader;
            }

            if (numberOfSets === 3) {
                names =
                    (merge === 'antecedente1Option' &&
                        antecedente1.entryName &&
                        antecedente1.entryName.startsWith(VariableTypeEnum.GRD)) ||
                    (merge === 'antecedente2Option' &&
                        antecedente2.entryName &&
                        antecedente2.entryName.startsWith(VariableTypeEnum.GRD))
                        ? threeTendenceHeader
                        : threeValorHeader;
            }
        } else {
            switch (numberOfSets) {
                case 3:
                    names = ['+', '0', '-'];
                    break;
                case 5:
                    names = ['++', '+', '0', '-', '--'];
                    break;
                case 7:
                    names = ['+++', '++', '+', '0', '-', '--', '---'];
                    break;
                case 9:
                    names = ['++++', '+++', '++', '+', '0', '-', '--', '---', '----'];
                    break;
                default:
                    names = ['n/a'];
                    break;
            }
        }

        const colors = this.baseColors.slice((11 - numberOfSets) / 2, (numberOfSets - 11) / 2 + 11);
        let lines = this.getLinesCoordToDraw(min, max, numberOfSets, baseRatio, colors, names);
        const series = this.getSeries(lines);
        this.shareData(colors, series, merge);
    }

    getLinesCoordToDraw(min, max, qtdeSets, baseRatio, colors, names) {
        if (!this.isChartParamsValid(min, max, baseRatio)) return [];

        const range = Math.abs(max - min);
        const topTrapezoid = Math.abs(range * baseRatio);
        const qtdeCompleteTriangles = this.getQtdeCompleteTriangles(qtdeSets);
        const delta = this.getDeltaValue(range, qtdeSets, topTrapezoid);

        const linesPartialTriangles = this.drawPartialTriangle(min, max, delta, names);
        const linesCentralTrapezoid = this.drawCentralTrapezoid(topTrapezoid, min, max, delta, qtdeSets, names);
        const linesCompleteTriangle = this.drawCompleteTriangle(
            qtdeCompleteTriangles,
            min,
            max,
            delta,
            topTrapezoid,
            qtdeSets,
            names
        );

        return [
            [linesPartialTriangles[0]],
            [linesPartialTriangles[1]],
            linesCentralTrapezoid,
            ...linesCompleteTriangle,
        ];
    }

    isChartParamsValid(min, max, baseRatio) {
        return min < max && baseRatio >= 0 && baseRatio <= 1;
    }

    getQtdeCompleteTriangles(numberOfSets) {
        const numPartialTriangles = 2;
        const numTrapezoid = 1;
        return numberOfSets - numPartialTriangles - numTrapezoid;
    }

    getDeltaValue(range, numberOfSets, topTrapezoid) {
        const triangleBase = (2 * (range - topTrapezoid)) / (numberOfSets - 1);
        return triangleBase / 2;
    }

    drawCompleteTriangle(quantity, min, max, delta, topTrapezoid, numberOfSets, names) {
        const trianglesBeforeTrapezoid = quantity / 2;
        const baseTriangle = delta * 2;

        let lines = Array<Array<Line>>();
        let trianglesDrawn = 0;
        let offsetTrapezoid = 0;
        let skipNameTrapezoid = 0;

        for (let indexTriangle = 0; indexTriangle < quantity; indexTriangle++) {
            if (trianglesDrawn == trianglesBeforeTrapezoid) {
                offsetTrapezoid = topTrapezoid;
                if (numberOfSets === 7) {
                    offsetTrapezoid = baseTriangle;
                }
                skipNameTrapezoid = 1;
            }

            //LeftPoint
            const xLeftPointTriangle =
                min + (baseTriangle / trianglesBeforeTrapezoid) * indexTriangle + offsetTrapezoid;
            const yLeftPointTriangle = 0;

            //CentralPoint
            const xCentralPointTriangle = xLeftPointTriangle + delta;
            const yCentralPointTriangle = 1;

            //RightPoint
            const xRightPointTriangle = xCentralPointTriangle + delta;
            const yRightPointTriangle = 0;

            const p1 = new Point(xLeftPointTriangle, yLeftPointTriangle);
            const p2 = new Point(xCentralPointTriangle, yCentralPointTriangle);
            const p3 = new Point(xRightPointTriangle, yRightPointTriangle);

            const nameTriangle = names[numberOfSets - 2 - indexTriangle - skipNameTrapezoid];

            lines[indexTriangle] = new Array<Line>();
            lines[indexTriangle].push(new Line(p1, p2, nameTriangle));
            lines[indexTriangle].push(new Line(p2, p3, nameTriangle));

            trianglesDrawn++;
            offsetTrapezoid = 0;
        }

        return lines;
    }

    drawPartialTriangle(min, max, delta, names) {
        let lines = Array<Line>();

        const p1FirstPartialTriangle = new Point(min, 1);
        const p2FirstPartialTriangle = new Point(min + delta, 0);
        const nameFirstPartialTriangle = names[names.length - 1];

        const p1LastPartialTriangle = new Point(max - delta, 0);
        const p2LastPartialTriangle = new Point(max, 1);
        const nameLastPartialTriangle = names[0];

        lines.push(new Line(p1FirstPartialTriangle, p2FirstPartialTriangle, nameFirstPartialTriangle));
        lines.push(new Line(p1LastPartialTriangle, p2LastPartialTriangle, nameLastPartialTriangle));

        return lines;
    }

    drawCentralTrapezoid(topTrapezoid, min, max, delta, numberOfSets, names) {
        let lines = Array<Line>();

        let xMiddleTrapezoid = min + Math.abs(max - min) / 2;

        let p1LeftSideTrapezoid = new Point(xMiddleTrapezoid - topTrapezoid / 2 - delta, 0);
        let p2LeftSideTrapezoid = new Point(xMiddleTrapezoid - topTrapezoid / 2, 1);

        let p1TopTrapezoid = new Point(xMiddleTrapezoid - topTrapezoid / 2, 1);
        let p2TopTrapezoid = new Point(xMiddleTrapezoid + topTrapezoid / 2, 1);

        let p1RightSideTrapezoid = new Point(xMiddleTrapezoid + topTrapezoid / 2, 1);
        let p2RightSideTrapezoid = new Point(xMiddleTrapezoid + topTrapezoid / 2 + delta, 0);

        let nameTrapezoid = names[(numberOfSets - 1) / 2];

        if (topTrapezoid != 0) {
            lines.push(new Line(p1LeftSideTrapezoid, p2LeftSideTrapezoid, nameTrapezoid));
            lines.push(new Line(p1TopTrapezoid, p2TopTrapezoid, nameTrapezoid));
            lines.push(new Line(p1RightSideTrapezoid, p2RightSideTrapezoid, nameTrapezoid));
        } else {
            lines.push(new Line(p1LeftSideTrapezoid, p2LeftSideTrapezoid, nameTrapezoid));
            lines.push(new Line(p1RightSideTrapezoid, p2RightSideTrapezoid, nameTrapezoid));
        }

        return lines;
    }

    getSeries(lines: Array<Array<Line>>) {
        return lines.map((l) => {
            return {
                name: l[0].name,
                type: 'line',
                symbol: 'none',
                data: this.combine(l),
            };
        });
    }

    onSubmit() {
        this.transformDataToFuzzy();
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');
        let ant1Tag = this.processTags.find((tag) => tag.name == antecedente1.get('entryName').value.slice(4));
        let ant2Tag = this.processTags.find((tag) => tag.name == antecedente2.get('entryName').value.slice(4));

        this.fuzzyForm.markAllAsTouched();
        antecedente2.markAllAsTouched();
        antecedente1.markAllAsTouched();
        if (this.validateForm() && this.user.permissions.canUpdate) {
            if (!this.matrixAvailable && this.isEditing) {
                this.conf.width = '45%';
                this.conf.data.message = MessagesEnum.fuzzySetChangeMessage;
                this.openDialog(this.conf);
                this.dialogRefMsg.afterClosed().subscribe(() => {
                    this.conf.width = 'auto';
                    this.saveControlRule();
                });
            } else {
                this.saveControlRule();
            }
        } else {
            if (
                (antecedente1['controls']['max'].invalid || antecedente1['controls']['min'].invalid) &&
                (antecedente2['controls']['max'].invalid || antecedente2['controls']['min'].invalid)
            ) {
                this.conf.height = 'auto';
                this.conf.width = '40%';
                this.conf.data.message =
                    `Verifique os valores de mínimo e máximo dos antecedentes.
                              Antecedente 1 - Valor minímo: ` +
                    ant1Tag.min +
                    `, valor máximo: ` +
                    ant1Tag.max +
                    '.' +
                    ` Antecedente 2 - Valor minímo: ` +
                    ant2Tag.min +
                    `, valor máximo: ` +
                    ant2Tag.max +
                    `.`;
            } else if (antecedente1['controls']['max'].invalid || antecedente1['controls']['min'].invalid) {
                this.conf.height = 'auto';
                this.conf.width = '40%';
                if (antecedente1.get('max').value < antecedente1.get('min').value) {
                    this.conf.data.message = 'Antecedente 1 - ' + this.invalidLimits;
                } else {
                    this.conf.data.message =
                        `Verifique os valores de mínimo e máximo do Antecedente 1.
                              Valor minímo: ` +
                        ant1Tag.min +
                        `, valor máximo: ` +
                        ant1Tag.max;
                }
            } else if (antecedente2['controls']['max'].invalid || antecedente2['controls']['min'].invalid) {
                this.conf.height = 'auto';
                this.conf.width = '40%';
                if (antecedente2.get('max').value < antecedente2.get('min').value) {
                    this.conf.data.message = 'Antecedente 2 - ' + this.invalidLimits;
                } else {
                    this.conf.data.message =
                        `Verifique os valores de mínimo e máximo do Antecedente 2.
                              Valor minímo: ` +
                        ant2Tag.min +
                        `, valor máximo: ` +
                        ant2Tag.max;
                }
            } else {
                this.conf.data.message = this.alertMessage;
            }
            this.conf.data.message = 'Preencha todos os campos';
            this.openDialog(this.conf);
        }
    }

    saveControlRule() {
        if (!this.isEditing) {
            this.fuzzyService.addFuzzy(this.fuzzy).subscribe((fuzzy) => {
                if (fuzzy) {
                    this.fuzzy = fuzzy;
                    this._$fuzzy.next(fuzzy);
                    this.conf.data.message = this.successMessage;
                    this.defineEditingMode();
                } else {
                    this.conf.data.message = this.failureMessage;
                }
                this.openDialog(this.conf);
            });
        } else if (this.isEditing) {
            this.fuzzyService.updateFuzzy(this.fuzzy).subscribe((response) => {
                if (response.fuzzy) {
                    this.fuzzy = response.fuzzy;
                    this._$fuzzy.next(response.fuzzy);
                    this.defineEditingMode();
                    this.conf.data.message = this.successMessage;
                } else {
                    this.conf.data.message = response.error || this.failureMessage;
                }
                this.openDialog(this.conf);
            });
        }
    }

    createEditForm(fuzzy: Fuzzy) {
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');
        this.fuzzy = fuzzy;
        this._$fuzzy.next(fuzzy);
        this.defineEditingMode();
        this.matrixAvailable = true;

        this.fuzzyForm.patchValue({
            top: fuzzy.top,
            inputName: fuzzy.tag.name,
            inputDesc: fuzzy.tag.description,
            min: fuzzy.tag.min,
            max: fuzzy.tag.max,
        });

        antecedente2.patchValue({
            entryId: fuzzy.antecedent2.tag.id,
            entryName: fuzzy.antecedent2.tag.name,
            variableType: fuzzy.antecedent2.tag.variableType.name,
            curveType: fuzzy.antecedent2.curveType.id,
            fuzzySet: fuzzy.antecedent2.fuzzySet.id,
            top: fuzzy.antecedent2.top,
            min: fuzzy.antecedent2.tag.min,
            max: fuzzy.antecedent2.tag.max,
        });

        antecedente1.patchValue({
            entryId: fuzzy.antecedent1.tag.id,
            entryName: fuzzy.antecedent1.tag.name,
            variableType: fuzzy.antecedent1.tag.variableType.name,
            curveType: fuzzy.antecedent1.curveType.id,
            fuzzySet: fuzzy.antecedent1.fuzzySet.id,
            top: fuzzy.antecedent1.top,
            min: fuzzy.antecedent1.tag.min,
            max: fuzzy.antecedent1.tag.max,
        });

        this.getAntecedentsNames();
        this.isLoaded = true;

        if (fuzzy.antecedent2.tag.variableType.name == VariableTypeEnum.PROCESS) {
            antecedente2.get('min').enable();
            antecedente2.get('max').enable();
        }

        if (fuzzy.antecedent1.tag.variableType.name == VariableTypeEnum.PROCESS) {
            antecedente1.get('min').enable();
            antecedente1.get('max').enable();
        }
    }

    transformDataToFuzzy() {
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');
        const matrix = this.fuzzyForm.get('matrix').getRawValue();
        const fuzzyFormData = this.fuzzyForm.getRawValue();
        const antecedent2FormData = antecedente2.getRawValue();
        const antecedent1FormData = antecedente1.getRawValue();
        this.fuzzy.tag.name = fuzzyFormData.inputName;
        this.fuzzy.tag.description = fuzzyFormData.inputDesc;
        this.fuzzy.tag.min = fuzzyFormData.min;
        this.fuzzy.tag.max = fuzzyFormData.max;

        this.fuzzy.antecedent2.tag.id = antecedent2FormData.entryId;
        this.fuzzy.antecedent1.tag.id = antecedent1FormData.entryId;

        this.fuzzy.antecedent2.curveType.id = antecedent2FormData.curveType;
        if (this.fuzzySets !== undefined) {
            this.fuzzy.antecedent2.fuzzySet = this.fuzzySets.find(
                (fuzzySet) => fuzzySet.id === antecedent2FormData.fuzzySet
            );
        }
        this.fuzzy.antecedent2.top = antecedent2FormData.top;
        this.validateTagName(antecedente2);
        this.fuzzy.antecedent2.tag.name = antecedent2FormData.entryName;
        this.fuzzy.antecedent2.tag.max = antecedent2FormData.max;
        this.fuzzy.antecedent2.tag.min = antecedent2FormData.min;

        this.fuzzy.antecedent1.curveType.id = antecedent1FormData.curveType;
        if (this.fuzzySets !== undefined) {
            this.fuzzy.antecedent1.fuzzySet = this.fuzzySets.find(
                (fuzzySet) => fuzzySet.id === antecedent1FormData.fuzzySet
            );
        }
        this.fuzzy.antecedent1.top = antecedent1FormData.top;
        this.validateTagName(antecedente1);
        this.fuzzy.antecedent1.tag.name = antecedent1FormData.entryName;
        this.fuzzy.antecedent1.tag.max = antecedent1FormData.max;
        this.fuzzy.antecedent1.tag.min = antecedent1FormData.min;
        this.fuzzy.outFuzzySet = matrix.outFuzzySet;
        this.fuzzy.veryHigh = matrix.veryHigh;
        this.fuzzy.high = matrix.high;
        this.fuzzy.normal = matrix.normal;
        this.fuzzy.low = matrix.low;
        this.fuzzy.veryLow = matrix.veryLow;
        this.fuzzy.goingUp = matrix.goingUp;
        this.fuzzy.stable = matrix.stable;
        this.fuzzy.fallingDown = matrix.fallingDown;
    }

    getAntecedentsNames() {
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');
        this.antecedentsNames = new Array(2);
        const antecedent1FormData = antecedente1.getRawValue();
        this.antecedentsNames[0] = antecedent1FormData.entryName !== null ? antecedent1FormData.entryName : 'Tag';
        const antecedent2FormData = antecedente2.getRawValue();
        this.antecedentsNames[1] = antecedent2FormData.entryName !== null ? antecedent2FormData.entryName : 'Tag';
    }

    validateTagName(form: any) {
        let tagName = form.get('entryName').value;
        if (!this.tags.find((tag) => tag.name == tagName)) {
            form.patchValue({ entryName: '' });
        }
    }

    validateForm(): boolean {
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');
        const matrix = this.fuzzyForm.get('matrix');
        const isFuzzyFormValid = this.fuzzyForm.valid;
        const isAntecedente1FormValid = antecedente1.valid;
        const isAntecedente2FormValid = antecedente2.valid;
        const isMatrixFormValid = matrix.valid;

        if (isFuzzyFormValid && isAntecedente1FormValid && isAntecedente2FormValid && isMatrixFormValid) {
            const isAntecedente1Invalid = this.isAntecedenteInvalid(antecedente1);
            const isAntecedente2Invalid = this.isAntecedenteInvalid(antecedente2);

            return !(isAntecedente1Invalid || isAntecedente2Invalid);
        }

        return false;
    }

    private isAntecedenteInvalid(form): boolean {
        const min = form.get('min').value;
        const max = form.get('max').value;

        return min === 0 && max === 0;
    }

    defineEditingMode() {
        const antecedente1 = this.fuzzyForm.get('antecedente1');
        const antecedente2 = this.fuzzyForm.get('antecedente2');

        this.isEditing = true;
        antecedente2.patchValue({
            isEditing: true,
        });

        antecedente1.patchValue({
            isEditing: true,
        });
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (options.data.message && options.data.message == this.successMessage && !this.matrixAvailable) {
                this.matrixAvailable = true;
            } else if (
                options.data.message &&
                (options.data.message == this.successMessage || options.data.message == this.deleteMessage)
            ) {
                this.router.navigate(['/regras-e-ponderadas']);
            }
        });
    }

    deleteFuzzy() {
        const confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        this.openDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.fuzzyService.deleteFuzzy(this.fuzzy.id).subscribe((dependencies) => {
                    if (dependencies.length > 0) {
                        this.conf.width = 'auto';
                        this.conf.height = 'auto';
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            dependencieNames += tag.name + ', ';
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        this.conf.data.message =
                            'Conflito ao deletar. Esta ponderada é usada como entrada em: ' + dependencieNames;
                        this.openDialog(this.conf);
                    } else {
                        this.conf.data.message = this.deleteMessage;
                        this.openDialog(this.conf);
                    }
                });
            }
        });
    }

    feedbackReceiver() {
        this.transformDataToFuzzy();
        this._$fuzzy.next(this.fuzzy);
    }

    close() {
        this.router.navigate(['/regras-e-ponderadas']);
    }

    initMatrix() {
        this.fuzzyService.getFuzzy(this.data.id).subscribe((data) => {
            if (data) {
                this.matrix = {
                    outFuzzySet: data.outFuzzySet,
                    veryHigh: data.veryHigh,
                    high: data.high,
                    normal: data.normal,
                    low: data.low,
                    veryLow: data.veryLow,
                    goingUp: data.goingUp,
                    stable: data.stable,
                    fallingDown: data.fallingDown,
                };
                this.isEditing = true;
                this.updateMatrix(this.matrix);
            }
        });
    }

    updateMatrix(matrix: Matrix) {
        const matrixForm = this.fuzzyForm.get('matrix');
        matrixForm.patchValue({
            outFuzzySet: matrix.outFuzzySet,
        });
    }

    setDefaultFields() {
        this.fuzzyForm.patchValue({
            curveType: this.curveTypes[0].id,
        });
    }

    toggleForm() {
        this.expanded = !this.expanded;
    }

    combine(lines: Array<Line>): Array<any> {
        let concatLines = lines.reduce(
            (accumulator, currentValue) =>
                accumulator.concat(currentValue.getData().filter((f) => !accumulator.some((p) => p[0] == f[0]))),
            []
        );

        return concatLines;
    }

    shareData(colors, sets, merge) {
        const data = {
            color: colors,
            series: sets,
        };

        this[merge] = { ...this[merge], ...data };
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType !== 'BUILDING';
    }
}
