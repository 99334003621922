export function getUserData() {
    
    const OWNER = 'ROLE_OWNER';
    const ADMIN = 'ROLE_ADMIN';
    const MANTAINER = 'ROLE_MANTAINER';
    const USER = 'ROLE_USER';

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let role = currentUser.role;
    let canCreate = role == OWNER || role == ADMIN ? true : false;
    let canRead = true;
    let canUpdate = role == OWNER || role == ADMIN || role == MANTAINER ? true : false;
    let canDelete = role == OWNER || role == ADMIN ? true : false;

    let user = {
        username: currentUser.username,
        name: currentUser.name,
        isSuperUser: role == OWNER ? true : false,
        permissions: {
            canCreate: canCreate,
            canRead: canRead,
            canUpdate: canUpdate,
            canDelete: canDelete,
            ldapAuthorization: currentUser.ldapAuthorization,
        },
        //currentProject: currentUser.defaultProject,
    };

    return user;
}
