<div class="container">
    <div fxFlexFill fxLayout="column">
        <div *ngIf="confirmTitle" fxFlex class="title">
            <p>{{ confirmTitle }}</p>
        </div>
        <div fxFlex class="message" data-cy="pop-up">
            <p>{{ data.message }}</p>
            <p>{{ data.message_second }}</p>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
            <button *ngIf="data.cancelAvailable" mat-button class="cancelDelete" type="button" (click)="cancel()">
                CANCELAR
            </button>
            <button mat-button class="blueButton" type="button" (click)="close()" data-cy="ok">
                {{ confirmButton }}
            </button>
        </div>
    </div>
</div>
