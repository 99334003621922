import { Point } from './point';

export class Line {
    pointA: Point;
    pointB: Point;
    size: number = 1000;
    name: string;

    constructor(pointA: Point, pointB: Point, name: string) {
        this.pointA = pointA;
        this.pointB = pointB;
        this.name = name;
    }

    generate(): Array<Point> {
        let incValueX = Math.abs(this.pointB.x - this.pointA.x) / this.size;
        let incValueY = (this.pointB.y - this.pointA.y) / this.size;
        let points = [];
        for (let pointPosition = 0; pointPosition <= this.size; pointPosition++) {
            let incPoint = new Point(
                this.pointA.x + pointPosition * incValueX,
                this.pointA.y + pointPosition * incValueY
            );
            points.push(incPoint);
        }
        return points;
    }

    getData = () => this.generate().map((p) => [p.x, p.y]);
}
