<div class="container">
    <ihm-card-header icon="trending_up" [title]="'Configuração do gráfico'"> </ihm-card-header>

    <div class="container-card">
        <form [formGroup]="chartFrom">
            <div fxFill>
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="VARIÁVEL">
                        <div class="padding">
                            <div class="div-inline-block" fxLayoutAlign="start center">
                                <span class="span-subtitle"> Adicionar nova variável </span>
                            </div>
                            <div class="div-flex-row">
                                <div class="container-axis">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Variável do Eixo Principal</mat-label>
                                        <mat-icon matPrefix>search</mat-icon>
                                        <input
                                            type="text"
                                            matInput
                                            formControlName="filterMainAxis"
                                            [matAutocomplete]="autoMainAxis"
                                        />
                                        <mat-autocomplete #autoMainAxis="matAutocomplete">
                                            <mat-optgroup
                                                *ngFor="let group of mainAxisOptions"
                                                [label]="group.variable"
                                            >
                                                <mat-option
                                                    *ngFor="let tag of group.tags"
                                                    [value]="tag.name"
                                                    (click)="addMainAxis(tag)"
                                                >
                                                    {{ tag.name }}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div class="div-border-axis">
                                        <mat-label class="span-subtitle">Eixo Principal</mat-label>
                                        <div class="div-chip">
                                            <mat-chip-listbox>
                                                <mat-chip-option
                                                    *ngFor="let axis of mainAxis"
                                                    [disabled]="axis.isDisabled"
                                                    [selectable]="selectable"
                                                    [removable]="removable"
                                                    (removed)="removeMainAxis(axis)"
                                                    class="mat-chip"
                                                >
                                                    {{ axis.name }}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip-option>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-axis">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Variável do Eixo Secundário</mat-label>
                                        <mat-icon matPrefix>search</mat-icon>
                                        <input
                                            type="text"
                                            matInput
                                            formControlName="filterSecondaryAxis"
                                            [matAutocomplete]="autoSecondaryAxis"
                                        />
                                        <mat-autocomplete #autoSecondaryAxis="matAutocomplete">
                                            <mat-optgroup
                                                [label]="group.variable"
                                                *ngFor="let group of secondaryAxisOptions"
                                            >
                                                <mat-option
                                                    *ngFor="let tag of group.tags"
                                                    [value]="tag.name"
                                                    (click)="addSecondaryAxis(tag)"
                                                >
                                                    {{ tag.name }}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div class="div-border-axis">
                                        <mat-label class="span-subtitle">Eixo Secundário</mat-label>
                                        <div class="div-chip">
                                            <mat-chip-listbox>
                                                <mat-chip-option
                                                    *ngFor="let axis of secondaryAxis"
                                                    [disabled]="axis.isDisabled"
                                                    [selectable]="selectable"
                                                    [removable]="removable"
                                                    (removed)="removeSecondaryAxis(axis)"
                                                    class="mat-chip"
                                                >
                                                    {{ axis.name }}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip-option>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                            <button mat-button class="cancelButton" type="button" (click)="cancel()">CANCELAR</button>
                            <button mat-button class="blueButton" type="button" (click)="save()">APLICAR</button>
                        </div>
                    </mat-tab>
                    <mat-tab label="ESCALA">
                        <div class="padding">
                            <div class="div-inline-block" fxLayoutAlign="start center">
                                <mat-slide-toggle color="primary" formControlName="toggleAutoScale">
                                    <span class="span-autoscale"> Autoscale </span>
                                </mat-slide-toggle>
                            </div>
                            <div class="div-flex-column">
                                <div class="div-inline-block">
                                    <span class="span-title">Eixo Principal:</span>
                                    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="margin-top">
                                        <span>Utilizar valores de</span>
                                        <input class="input-number" type="number" formControlName="mainAxisY1" />
                                        <span>a</span>
                                        <input class="input-number" type="number" formControlName="mainAxisY2" />
                                        <span>para eixo Y do gráfico.</span>
                                    </div>
                                </div>
                                <div class="div-inline-block">
                                    <span class="span-title">Eixo Secundário:</span>
                                    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="margin-top">
                                        <span>Utilizar valores de</span>
                                        <input class="input-number" type="number" formControlName="secondaryAxisY1" />
                                        <span>a</span>
                                        <input class="input-number" type="number" formControlName="secondaryAxisY2" />
                                        <span>para eixo Y do gráfico.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                            <button mat-button class="cancelButton" type="button" (click)="cancel()">CANCELAR</button>
                            <button mat-button class="blueButton" type="button" (click)="save()">APLICAR</button>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </form>
    </div>
</div>
