import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurveType } from '../../models/views-models/curveType.model';
import { Response } from '../../models/response.model';

const API = environment.serverUrl;

@Injectable()
export class CurveTypeService {
    constructor(private httpClient: HttpClient) {}

    findAllCurveTypes(): Observable<CurveType[]> {
        return this.httpClient.get<Response>(`${API}curve-type/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const curveTypes: CurveType[] = response.data;
                return curveTypes;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
