import { EChartsOption, LegendComponentOption, LineSeriesOption, SeriesOption, TooltipComponentOption } from 'echarts';
import { SetpointSeries, ChartTypeMapper, chartTypeEquals, OutputSeries } from './setpoint-chart.config';
import { TagAxis } from './axis.config';
import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { CycleStatusEnum } from 'src/app/shared/models/enum/cycleStatus.enum';

const chartColors = [
    '#36A1EB',
    '#855CF8',
    '#B7B7A4',
    '#E4E4DE',
    '#00D99D',
    '#350094',
    '#C4C5BA',
    '#1B1B1B',
    '#595f39',
    '#0F2143',
    '#43572E',
    '#354E56',
    '#8B6212',
    '#023E8A',
    '#283618',
    '#B7B7A4',
    '#D4D4D4',
];

const cycleInfoStatusColor = {
    successful: '#00cb92',
    error: '#FF0000',
    waiting: '#AAAAAA',
    disabled: '#191C1A',
    not_existed: '#191C1A',
    not_written: '#AAAAAA',
};

const cycleInfoStatusLabel = {
    successful:
        '<b>Calculada</b></br> <div class="text-block"> <i class="material-icons-outlined tooltip-icon green-icon"> check_circle</i> Cálculos realizados <br/> com <b> sucesso. </b> </div>',
    not_calculated:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Tempo do ciclo anterior <br/> excedido. </span>',
    worker_off:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Cálculos inapto.</span>',
    engine_off:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Tratamento de dados inapto. </span>',
    quarterback_off:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Coleta de dados inapto. </span>',
    process_inapt:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Processo inapto. </span>',
    unknown:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Erro desconhecido.</span>',
    limits_error:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Erro de <b> configuração dos <br/> limites de atuação</b>.</span>',
    cycle_timeout:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Tempo do ciclo anterior <br/> excedido. </span>',
    error_in_dependencies:
        '<b>Não Calculada</b></br>  <div class="text-block">  <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Erro de cálculo nas <br/> dependências. </span>',
    disabled:
        '<b>Desabilitada</b></br> <div class="text-block"> <i class="material-icons tooltip-icon black-icon"> stop_circle</i> Saída <b>desabilitada</b> <br/> para o ciclo. <b></div>',
    not_written:
        '<b>Não calculada</b></br> <div class="text-block"> <i class="material-icons-outlined tooltip-icon green-icon"> check_circle</i>Saída configurada para <br/><b>não ser calculada</b> <br/> &#160 &#160 nesse ciclo.</div>',
    unexpected_value:
        '<b>Não Escrito</b></br> <div class="text-block"> <span  class="red-text"> <i class="material-icons tooltip-icon red-icon"> warning</i> Condição não possui valor esperado. </span>',
    not_existed:
        '<b>Não cadastrada</b></br> </br><i class="material-icons tooltip-icon black-icon"> not_interested</i> Saída ainda <b>não <br/>cadastrada</b> <br/> &#160 &#160 neste ciclo.',
};

export enum DataIntervalType {
    OPC_COLLECTOR = 'opc_collector',
    CYCLE_TIME = 'cycle_time',
    FROM_TAG_VALUE = 'from_tag_value',
}

export const variableTypesDataInterval = {
    OPC_COLLECTOR: ['process', 'calculated', 'setpoint'],
    CYCLE_TIME: ['fuzzy', 'equation'],
};

export const setpointChartDefaultOptions: EChartsOption = {
    xAxis: [
        {
            type: 'time',
            gridIndex: 0,
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        {
            type: 'time',
            gridIndex: 1,
        },
    ],
    yAxis: [
        {
            name: 'CycleInfo',
            type: 'value',
            nameTextStyle: {
                align: 'left',
            },
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
            gridIndex: 0,
        },
        {
            name: 'Eixo Principal',
            type: 'value',
            splitLine: {
                show: true,
            },
            gridIndex: 1,
        },
        {
            name: 'Eixo Secundário',
            type: 'value',
            nameTextStyle: {
                align: 'right',
            },
            splitLine: {
                show: false,
            },
            gridIndex: 1,
        },
    ],
    series: [
        {
            name: 'Setpoint',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            silent: false,
            connectNulls: true,
        },
        {
            name: 'Incremento',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 2,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            silent: false,
            connectNulls: true,
        },

        {
            name: 'Limite_Inf',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            lineStyle: {
                type: 'dashed',
            },
            silent: false,
            color: '#AAAAAA',
            connectNulls: true,
        },
        {
            name: 'Limite_Sup',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            lineStyle: {
                type: 'dashed',
            },
            silent: false,
            color: '#AAAAAA',
            connectNulls: true,
        },
        {
            name: 'CycleInfo',
            data: [],
            type: 'line',
            symbol: 'none',
            symbolSize: 6,
            yAxisIndex: 0,
            xAxisIndex: 0,
            color: 'gray',
        },
    ],

    grid: [
        {
            top: 0,
            bottom: 0,
            left: 50,
            right: 50,
            height: 60,
        },
        {
            top: 110,
            bottom: 30,
            left: 50,
            right: 50,
            height: 400,
        },
    ],

    tooltip: {
        trigger: 'axis',
    },

    toolbox: {
        feature: {
            dataZoom: { yAxisIndex: 'none' },
            saveAsImage: {
                title: 'Salvar',
            },
        },
        right: 40,
        top: 12,
    },

    dataZoom: [
        {
            type: 'inside',
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2],
            filterMode: 'none',
        },
        {
            type: 'inside',
            start: 0,
            end: 100,
            yAxisIndex: [0, 1, 2],
            filterMode: 'none',
        },
    ],
};

export const outputChartDefaultOptions: EChartsOption = {
    xAxis: [
        {
            type: 'time',
            gridIndex: 0,
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        {
            type: 'time',
            gridIndex: 1,
        },
    ],
    yAxis: [
        {
            name: 'CycleInfo',
            type: 'value',
            nameTextStyle: {
                align: 'left',
            },
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
            gridIndex: 0,
        },
        {
            name: 'Eixo Principal',
            type: 'value',
            splitLine: {
                show: true,
            },
            gridIndex: 1,
        },
        {
            name: 'Eixo Secundário',
            type: 'value',
            nameTextStyle: {
                align: 'right',
            },
            splitLine: {
                show: false,
            },
            gridIndex: 1,
        },
    ],
    series: [
        {
            name: 'Saída',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            silent: false,
            connectNulls: true,
        },
        {
            name: 'Condição',
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            silent: false,
            connectNulls: true,
        },
        {
            name: 'CycleInfo',
            data: [],
            type: 'line',
            symbol: 'none',
            symbolSize: 6,
            yAxisIndex: 0,
            xAxisIndex: 0,
            color: 'gray',
            connectNulls: true,
        },
    ],

    tooltip: {
        trigger: 'axis',
    },

    grid: [
        {
            top: 0,
            bottom: 0,
            left: 50,
            right: 50,
            height: 60,
        },
        {
            top: 110,
            bottom: 30,
            left: 50,
            right: 50,
            height: 400,
        },
    ],

    toolbox: {
        feature: {
            dataZoom: { yAxisIndex: 'none' },
            saveAsImage: {
                title: 'Salvar',
            },
        },
        right: 40,
        top: 12,
    },

    dataZoom: [
        {
            type: 'inside',
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2],
        },
        {
            type: 'inside',
            start: 0,
            end: 100,
            yAxisIndex: [0, 1, 2],
        },
    ],
};

function _getTooltipFromChartTags(chartTags: Array<ChartTypeMapper>): TooltipComponentOption[] {
    return [
        {
            trigger: 'axis',
        },
        {
            trigger: 'axis',
        },
    ];
}

function _getLegendFromChartTags(chartTags: Array<ChartTypeMapper>, type: string): LegendComponentOption[] {
    const isNotLimitMapper = (mapper: ChartTypeMapper) => mapper.serie !== 'Limite_Sup';
    const isNotCycleinfo = (mapper: ChartTypeMapper) => mapper.serie !== 'CycleInfo';
    const filteredChartTags: string[] = chartTags
        .filter(isNotLimitMapper)
        .filter(isNotCycleinfo)
        .map((mapper) => mapper.serie);
    const selectedTags = filteredChartTags.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
    }, {});

    const legend: LegendComponentOption = {
        data: filteredChartTags,
        selected: selectedTags,
        formatter: (chartSerie) =>
            chartTags.find((chartTag) => chartTag.serie == chartSerie && chartSerie !== 'CycleInfo').label,
    };

    if (type == 'Setpoint') {
        const LimiteSupLegend: LegendComponentOption = {
            data: ['Limite_Sup'],
            show: false,
        };
        return [legend, LimiteSupLegend];
    }

    return [legend];
}

function _getChartSeries(chartTags: ChartTypeMapper[], chartDefaultOptions: EChartsOption): SeriesOption[] {
    return chartTags.map((chartTag, indexChartTag) => {
        let chartSerieOption = (chartDefaultOptions.series as Array<SeriesOption>).find((s) =>
            chartTypeEquals(chartTag.serie, s.name)
        );

        if (chartSerieOption) {
            if (!chartSerieOption.color) {
                chartSerieOption.color = chartColors[indexChartTag % chartColors.length];
            }

            return chartSerieOption;
        }

        return {
            name: chartTag.serie,
            data: [],
            type: 'line',
            showSymbol: false,
            step: 'end',
            yAxisIndex: 1,
            xAxisIndex: 1,
            tooltip: {
                show: true,
            },
            color: chartColors[indexChartTag % chartColors.length],
            connectNulls: true,
        };
    });
}

export function getDefaultMergeOptions(chartTags: Array<ChartTypeMapper>, start: string, end: string): EChartsOption {
    return {
        series: chartTags.map((chartTag) => ({ name: chartTag.serie, data: [] })),
        // legend: [{ selected: {} }, { selected: {} }],
        tooltip: _getTooltipFromChartTags(chartTags),
        xAxis: [
            { min: start, max: end },
            { min: start, max: end },
        ],
    };
}

export function getDefaultChartOptions(chartTags: Array<ChartTypeMapper>, type: string): EChartsOption {
    let chartDefaultOptions = type == 'Setpoint' ? setpointChartDefaultOptions : outputChartDefaultOptions;
    return {
        ...chartDefaultOptions,
        color: chartColors,
        legend: _getLegendFromChartTags(chartTags, type),
        tooltip: _getTooltipFromChartTags(chartTags),
        series: _getChartSeries(chartTags, chartDefaultOptions),
    };
}

export function createChartSerieOption(
    type: SetpointSeries | OutputSeries,
    index: number,
    yAxisIndex: string = 'second'
): LineSeriesOption {
    return {
        name: type,
        data: [],
        type: 'line',
        showSymbol: false,
        step: 'end',
        yAxisIndex: yAxisIndex === 'first' ? 1 : 2,
        xAxisIndex: 1,
        tooltip: {
            show: true,
            trigger: 'axis',
        },
        silent: false,
        color: chartColors[index % chartColors.length],
        connectNulls: true,
    };
}

export function getStatusColorFromCycleInfo(cycleInfo: CycleInfo) {
    if (isNullOrUndefined(cycleInfo?.dependency)) {
        return cycleInfoStatusColor[CycleStatusEnum.not_existed];
    }
    if (cycleInfo?.dependency?.status === CycleStatusEnum.disabled)
        return cycleInfoStatusColor[CycleStatusEnum.disabled];
    let status = cycleInfo?.dependency?.status || 'error';
    if (cycleInfo?.cycleStatus != 'calculated') {
        status = 'error';
    }
    const color = cycleInfoStatusColor[status] || cycleInfoStatusColor['error'];
    return color;
}

export function getStatusLabelFromCycleInfo(cycleInfo: CycleInfo) {
    if (isNullOrUndefined(cycleInfo?.dependency)) {
        return cycleInfoStatusLabel[CycleStatusEnum.not_existed];
    }
    if (cycleInfo?.dependency?.status === CycleStatusEnum.disabled)
        return cycleInfoStatusLabel[CycleStatusEnum.disabled];
    let status = getCycleInfoStatus(cycleInfo);
    if (cycleInfoStatusLabel[status]) {
        return cycleInfoStatusLabel[status];
    }
    status = cycleInfo?.dependency?.status || CycleStatusEnum.unknown;
    return cycleInfoStatusLabel[status] || cycleInfoStatusLabel[CycleStatusEnum.unknown];
}

function getCycleInfoStatus(cycleInfo: CycleInfo) {
    if (cycleInfo?.cycleStatus !== CycleStatusEnum.not_calculated) return cycleInfo?.cycleStatus;
    if (!cycleInfo?.engineStatus) return CycleStatusEnum.engine_off;
    if (!cycleInfo?.workerStatus) return CycleStatusEnum.worker_off;
    if (!cycleInfo?.quarterbackStatus) return CycleStatusEnum.quarterback_off;
    if (!cycleInfo?.processStatus) return CycleStatusEnum.process_inapt;
}

function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}
