import { MessageService } from './../../shared/service/message/message.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Views } from 'src/app/shared/utils/views-urls';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    color = '#f3f3f3';
    loginForm: UntypedFormGroup;

    loginSubscription: Subscription = new Subscription();

    message = '';

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
        public snackBar: MatSnackBar,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.loginForm = new UntypedFormGroup({
            username: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        });
    }

    ngOnDestroy(): void {
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }

    signin() {
        const signinData = this.loginForm.value;
        this.loginSubscription = this.loginService.login(signinData.username, signinData.password).subscribe(
            () => {
                this.router.navigate([Views.projects.url]);
            },
            (error) => {
                console.error(error)
                if (error && error.status === 401) {
                    this.message = `Usuário ou senha inválidos!`;
                } else {
                    this.message = `Erro interno no servidor! Favor tentar novamente mais tarde!`;
                }
                this.messageService.setMessage(this.message);
                this.snackBar.open(this.message, `Fechar`, this.messageService.getConfigMessage());
            }
        );
    }
}
