import { Injectable } from '@angular/core';
import { Category } from '../../models/views-models/category.model';
import { VariableTypeEnum } from '../../models/enum/variableType.enum';

@Injectable()
export class CategoriesService {
    constructor() {}

    listAllCategories(): Category[] {
        const categories: Category[] = [
            { name: VariableTypeEnum.FUZZY, description: 'Fuzzy' },
            { name: VariableTypeEnum.WEIGHTED, description: 'Ponderada' },
            { name: VariableTypeEnum.CALCULATED, description: 'Processo' },
            { name: VariableTypeEnum.EQUATION, description: 'Calculada' },
        ];
        return categories;
    }
}
