<div
    class="sidenav-container"
    fxLayout="column"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between"
    [@onSideNavChange]="sideNavState ? 'open' : 'close'"
>
    <div>
        <div class="menu-header">
            <div [@animateText]="linkText ? 'hide' : 'show'" class="sidenav-section">
                <button mat-stroked-button (click)="onSidenavToggle()">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-icon>menu</mat-icon>
                    </div>
                </button>
            </div>
            <div [@animateText]="linkText ? 'show' : 'hide'">
                <div fxLayout="row" fxLayoutGap="10px" class="header-info">
                    <div class="user-info" fxFlex>
                        <div class="username">
                            {{ user.name.length > 20 ? user.name.substring(0, 20) + "..." : user.name }}
                        </div>
                        <div class="email">
                            {{ role }}
                        </div>
                    </div>
                    <div fxFlex="35px">
                        <button mat-icon-button (click)="onSidenavToggle()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-divider class="over-div"></mat-divider>
            </div>
        </div>
        <div class="buttons sidenav-section" fxLayout="column" fxLayoutGap="8px">
            <div *ngFor="let view of views">
                <button
                    *ngIf="view.source != 'material-symbols-outlined'"
                    (click)="navigateTo(view.url)"
                    [ngClass]="isActive(view.name)"
                    mat-stroked-button
                    type="button"
                >
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-icon>{{ view.icon }}</mat-icon>
                        <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                    </div>
                </button>

                <button
                    *ngIf="view.source == 'material-symbols-outlined'"
                    (click)="navigateTo(view.url)"
                    [ngClass]="isActive(view.name)"
                    mat-stroked-button
                    type="button"
                >
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-icon fontSet="material-symbols-outlined">{{ view.icon }}</mat-icon>
                        <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">{{ view.name }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div class="sidenav-section">
        <div fxLayout="column" fxLayoutGap="10px">
            <div [@animateText]="linkText ? 'show' : 'hide'">
                <mat-divider class="over-div settings-space"></mat-divider>
                <span class="settings">Configurações</span>
            </div>
            <button (click)="openSettings()" mat-stroked-button type="button">
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                    <mat-icon>settings</mat-icon>
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">Sistema</span>
                </div>
            </button>
            <button
                *ngIf="user.permissions.canUpdate"
                (click)="navigateToUsers()"
                [ngClass]="isActive('Usuários')"
                mat-stroked-button
                type="button"
            >
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                    <mat-icon>group_add</mat-icon>
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">Controle de Usuários</span>
                </div>
            </button>
            <button (click)="openUserSettings()" mat-stroked-button type="button">
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                    <mat-icon>lock</mat-icon>
                    <span [@animateText]="linkText ? 'show' : 'hide'" class="menu-name">Minha Conta</span>
                </div>
            </button>
        </div>
    </div>
</div>
