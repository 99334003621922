import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/components/dialogs/delete-dialog/delete-dialog.component';
import { UserDialogComponent } from 'src/app/shared/components/views-components/user-dialog/user-dialog.component';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { RolesEnum } from 'src/app/shared/models/enum/roles.enum';
import { UserService } from 'src/app/shared/service/views-services/user.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { Views } from 'src/app/shared/utils/views-urls';

@Component({
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;

    user: any;
    dialogRef: any;
    displayedColumns = ['name', 'company', 'role', 'username', 'origin', 'actions'];
    dataSource: MatTableDataSource<Object>;
    users = [];

    userDialog = {
        component: UserDialogComponent,
        width: 'auto',
        minWidth: '700px',
        height: 'auto',
        data: '',
    };

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(private userService: UserService, public dialog: MatDialog, private router: Router) {}

    ngOnInit() {
        this.user = getUserData();
        this.loadData();
        if (!this.user.permissions.canUpdate) {
            this.router.navigate([Views.processInputsList.url]);
        }
    }

    loadData() {
        this.userService.getAllUsers().subscribe((users) => {
            let filteredUsers = this.filterUsers(users);
            this.users = [];
            filteredUsers.forEach((user) => {
                this.users.push({
                    name: user.name.length > 20 ? user.name.substring(0, 20) + '...' : user.name,
                    company:
                        user.length && user.company.length > 20 ? user.company.substring(0, 20) + '...' : user.company,
                    role: this.translatePermission(user.role.name),
                    username: user.username.length > 21 ? user.username.substring(0, 21) + '...' : user.username,
                    origin: user.origin == 'NATIVE' ? 'NATIVO' : user.origin,
                    object: user,
                });
            });
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
        });
    }

    filterUsers(users) {
        let filteredUsers;
        if (this.user.isSuperUser) {
            return users;
        }
        filteredUsers = users.filter((user) => user.role.name != RolesEnum.ROLE_OWNER);
        if (!this.user.permissions.canCreate) {
            filteredUsers = filteredUsers.filter((user) => user.role.name != RolesEnum.ROLE_ADMIN);
        }
        if (!this.user.permissions.canUpdate) {
            filteredUsers = [];
        }
        return filteredUsers;
    }

    translatePermission(role) {
        let permission = RolesEnum.roles.find((r) => r.role == role);
        return permission.description;
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            minWidth: options.minWidth,
            height: options.height,
            data: options.data,
        });
        this.dialogRef.afterClosed().subscribe((data) => {
            if (data || this.defaultDialog.data.message == MessagesEnum.DeleteMessage) {
                this.loadData();
            }
        });
    }

    add() {
        this.userDialog.data = null;
        this.openDialog(this.userDialog);
    }

    selectElement(user) {
        this.userDialog.data = user.object;
        this.openDialog(this.userDialog);
    }

    deleteElement(user) {
        let confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        let userData = user.object;
        this.openDialog(confirmDelete);
        this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.userService.deleteUser(userData.username).subscribe(() => {
                    this.defaultDialog.data.message = MessagesEnum.DeleteMessage;
                    this.openDialog(this.defaultDialog);
                });
            }
        });
    }
}
