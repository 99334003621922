import { Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { Views } from 'src/app/shared/utils/views-urls';
export const LoginRoutes: Routes = [
    {
        path: '',
        redirectTo: Views.homePage,
        pathMatch: 'full',
    },
    {
        path: '',
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: { title: 'Login' },
            },
        ],
    },
];
