import { Component, Input } from '@angular/core';

@Component({
    selector: 'ihm-card-header',
    templateUrl: './ihm-card-header.component.html',
    styleUrls: ['./ihm-card-header.component.scss'],
})
export class IhmCardHeaderComponent {
    /**
     * State change inputs: decorators for ihm-card-header transfer data.
     * Generalized inputs for every "card-headers" IHM Google Angular material project.
     */
    @Input()
    icon: string; // Icon for header component.

    @Input()
    subtitle: string; // Subtile for header component.

    @Input()
    title: string; // Title for header component.

    @Input()
    info: string; //Tooltip Info
}
