export enum VariableTypeEnum {
    FUZZY = 'fuzzy',
    WEIGHTED = 'weighted',
    PROCESS = 'process',
    CONTROL_RULE = 'control_rule',
    CONSTANT = 'constant',
    SYSTEM = 'system',
    SP_RULE_STRUCTURE = 'sp_rule_structure',
    PARAMETER = 'parameter',
    CALCULATED = 'calculated',
    SETPOINT = 'setpoint',
    VIRTUAL_SETPOINT = 'virtual_setpoint',
    SETPOINT_STATUS = 'setpoint_status',
    ANALOGIC = 'analogic',
    DIGITAL = 'digital',
    MANIPULATED_VARIABLE = 'manipulated_variable',
    CLS_ID = 'ClsID',
    PROG_ID = 'ProgID',
    LIMIT = 'limit',
    AVG = 'AVG_',
    AVG_NO = 'AVG_NO_',
    GRD = 'GRD_',
    STD = 'STD_',
    EQUATION = 'equation',
    OUTPUT = 'output',
}
