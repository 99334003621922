import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Process } from 'src/app/shared/models/views-models/process.model';
import { SPFormula } from 'src/app/shared/models/views-models/spFormula.model';
import { SPFormulaCondition } from 'src/app/shared/models/views-models/spFormulaCondition.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { infix2postfix } from 'src/app/shared/utils/rpn';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-formula-dialog',
    templateUrl: './formula-dialog.component.html',
    styleUrls: ['./formula-dialog.component.scss'],
})
export class FormulaDialogComponent implements OnInit {
    conditionsAdded = MessagesEnum.conditionsAdded;
    equationAdded = MessagesEnum.equationAdded;
    deleteMessage = MessagesEnum.DeleteMessage;
    failureNameMessage = MessagesEnum.FailureNameMessage;
    invalidFormMessage = MessagesEnum.invalidFormMessage;
    intNumberRequired = MessagesEnum.intNumberRequired;
    invalidEquation = MessagesEnum.invalidEquation;

    formulaForm: UntypedFormGroup;
    conditionForm: UntypedFormGroup;
    conditions: UntypedFormArray;
    user: any;
    conditionList = [];
    previousTagName = [];
    previousConditionValue = [];
    previousConditions = [];

    formula: SPFormula = new SPFormula();
    conditionsArray: SPFormulaCondition[] = [];

    isEditing = false;
    disableArrow: boolean;
    dialogRefMsg: any;
    cycleTime: any;
    title: String;
    cursorStart = -1;
    cursorEnd = -1;
    validConditions = true;
    activeTab = 0;
    tabIndex = 0;

    valueOptions = [];
    tagNames = [];
    processOptions = [];
    conditionsStatus = [];
    formulaEquation = '';
    datalist = 'conditionValueList';

    defaultDialog = {
        component: ConfirmDialogComponent,
        panelClass: 'pop-up-dialog-container',
        width: 'auto',
        height: 'auto',
        data: {
            message: '',
        },
    };
    noConditions: boolean;
    validEquation: boolean;

    constructor(
        private systemService: SystemParService,
        private tagService: TagService,
        private processService: ProcessInputService,
        private projectService: ProjectService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        public currentProjectService: CurrentProjectService,

        private dialogRef: MatDialogRef<FormulaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.user = getUserData();
        this.projectService.getCycleTime().subscribe((cycleTime) => {
            this.cycleTime = cycleTime;
        });
        this.formula = this.data.formula;
        this.initForm();
        this.loadData();
        this.setTitle();
        this.createEditForm(this.formula);
        if (!this.user.permissions.canUpdate || this.isShowForm()) {
            this.formulaForm.disable();
        }
    }

    getInputType(index) {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        let isConst = this.conditions.value[index].constRadio;
        this.enableList(isConst);
        if (isConst == 'true') {
            this.disableArrow = true;
            return 'number';
        } else {
            this.disableArrow = false;
            return 'text';
        }
    }

    setTitle() {
        if (this.formula.inc_dec == 'inc') {
            this.title = 'Condições de Incremento';
        } else {
            this.title = 'Condições de Decremento';
        }
    }

    clearConditionValue(index) {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        this.conditions.controls[index].patchValue({ equation_value: '' });
    }

    enableList(isConst) {
        if (isConst != 'true') {
            this.datalist = 'conditionValueList';
        } else {
            this.datalist = '';
        }
    }

    async loadData() {
        const waitForCalculated = async (): Promise<Tag[]> => {
            let calculatedList = this.tagService.getAllTagsByType(VariableTypeEnum.CALCULATED).toPromise();
            calculatedList.then((calculated) => {
                calculated.forEach((calculated) => {
                    this.tagNames.push(calculated.name);
                });
            });
            return calculatedList;
        };
        const waitForSP = async (): Promise<Process[]> => {
            let setpointList = this.processService.getAllProcessesByType(VariableTypeEnum.SETPOINT).toPromise();
            setpointList.then((sp) => {
                sp.forEach((sp) => {
                    this.tagNames.push(sp.tag.name);
                });
            });
            return setpointList;
        };
        const waitForMv = async (): Promise<Process[]> => {
            let mvList = this.processService.getAllProcessesByType(VariableTypeEnum.MANIPULATED_VARIABLE).toPromise();
            mvList.then((mv) => {
                mv.forEach((mv) => {
                    this.tagNames.push(mv.tag.name);
                });
            });
            return mvList;
        };
        const waitForDigital = async (): Promise<Process[]> => {
            let digitalList = this.processService.getAllProcessesByType(VariableTypeEnum.DIGITAL).toPromise();
            digitalList.then((digital) => {
                digital.forEach((digital) => {
                    this.tagNames.push(digital.tag.name);
                });
            });
            return digitalList;
        };
        const waitForLimits = async (): Promise<Process[]> => {
            let limitList = this.processService.getAllProcessesByType(VariableTypeEnum.LIMIT).toPromise();
            limitList.then((limits) => {
                this.valueOptions = limits.map((limit) => limit.tag.name);
            });
            return limitList;
        };

        const waitForEquations = async (): Promise<Tag[]> => {
            let equationList = this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION).toPromise();
            equationList.then((equations) => {
                equations.forEach((equation) => {
                    this.tagNames.push(equation.name);
                });
            });

            return equationList;
        };

        await Promise.all([
            waitForCalculated(),
            waitForSP(),
            waitForMv(),
            waitForDigital(),
            waitForLimits(),
            waitForEquations(),
        ]);
        this.tagNames = this.tagNames.sort((a, b) => a.localeCompare(b));
        this.valueOptions.push(...this.tagNames);
        this.valueOptions = this.valueOptions.sort((a, b) => a.localeCompare(b));
    }

    initForm() {
        this.formulaForm = this.formBuilder.group({
            conditions: this.formBuilder.array([]),
            equation: [null, [Validators.required]],
            conditionSelect: [],
        });
    }

    getCursor(textArea) {
        this.cursorStart = textArea.selectionStart;
        this.cursorEnd = textArea.selectionEnd;
    }

    createCondition() {
        return this.formBuilder.group({
            id: [],
            conditionName: [null, [Validators.required]],
            constRadio: [null, [Validators.required]],
            tag_name: [null, [Validators.required]],
            equation_operator: [null, [Validators.required]],
            equation_value: [null, [Validators.required]],
            cycles: [null],
        });
    }

    validateFormulaData() {
        this.validConditions = true;
        this.noConditions = false;
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        if (this.conditions.length == 0) {
            this.validConditions = false;
            this.noConditions = true;
        } else {
            if (this.conditions.length == 1) {
                let condition = this.conditions.controls[0].get('conditionName').value;
                this.formulaForm.patchValue({ equation: '(' + condition + ') ' });
            }
            for (let condition of this.conditions.controls) {
                let isConst = condition.get('constRadio').value;
                let index = this.conditions.value.indexOf(condition.value);
                if (isConst == 'false') {
                    let equation_value_tag;
                    equation_value_tag = condition.get('equation_value').value;
                    if (this.valueOptions.indexOf(equation_value_tag) == -1) {
                        condition.get('equation_value').patchValue('');
                    }
                }
                let tag_name = condition.get('tag_name').value;
                if (this.tagNames.indexOf(tag_name) == -1) {
                    condition.get('tag_name').patchValue('');
                }
                if (!condition.value.equation_operator) {
                    this.conditionsStatus[index].valid = false;
                } else {
                    this.conditionsStatus[index].valid = true;
                }
                if (condition.invalid) {
                    this.validConditions = false;
                }
            }
        }
    }

    nextConditionName(nextNumber) {
        let conditionNames = this.conditions.value
            .map((condition) => condition.conditionName)
            .filter((name) => name.startsWith('C'));
        let nextPosition = nextNumber > 9 ? nextNumber.toString() : '0' + nextNumber.toString();
        let nextConditionName = 'C' + nextPosition;
        if (!conditionNames.find((name) => name == nextConditionName)) {
            return nextConditionName;
        } else {
            return this.nextConditionName(nextNumber + 1);
        }
    }

    addCondition(): void {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        this.conditionForm = this.createCondition();
        let nextConditionName = this.nextConditionName(this.conditions.length + 1);
        this.conditionForm.patchValue({
            conditionName: nextConditionName,
            constRadio: 'true',
            cycles: 0,
        });
        this.previousTagName.push('');
        this.previousConditionValue.push('');
        this.conditions.push(this.conditionForm);
        this.conditionsStatus.push({ valid: true });
    }

    removeCondition(index): void {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        this.previousTagName.splice(index, 1);
        this.previousConditionValue.splice(index, 1);
        const conf = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: 'Ao deletar uma condição, a equação será zerada',
            },
        };
        this.openDialog(conf);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.conditions.removeAt(index);
                this.clearText();
            }
        });
    }

    resetOperator(index) {
        this.conditionsStatus[index].valid = true;
    }

    writeEquation(expression: string): void {
        let form = this.formulaForm.getRawValue();
        this.formulaEquation = form.equation ? form.equation : '';
        this.cursorStart = this.cursorStart == -1 ? this.formulaEquation.length : this.cursorStart;
        this.cursorEnd = this.cursorEnd == -1 ? this.formulaEquation.length : this.cursorEnd;
        this.formulaEquation = [
            this.formulaEquation.slice(0, this.cursorStart),
            expression,
            this.formulaEquation.slice(this.cursorEnd),
        ].join('');
        this.formulaForm.patchValue({
            equation: this.formulaEquation,
        });
        this.cursorStart += expression.length;
        this.cursorEnd = this.cursorStart;
    }

    writeCondition() {
        let form = this.formulaForm.getRawValue();
        if (form.conditionSelect) {
            this.writeEquation('(' + form.conditionSelect + ') ');
        }
    }

    calculateTime(index) {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        let cycle = this.conditions.value[index].cycles;
        return cycle * this.cycleTime;
    }

    createEditForm(formula: SPFormula) {
        this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
        this.formulaForm.patchValue({
            equation: formula.formula,
        });

        if (formula.formula_conditions.length > 0) {
            formula.formula_conditions.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
            formula.formula_conditions.forEach((condition) => {
                let isConst;
                let equation_value;
                if (condition.equation_value != null) {
                    equation_value = condition.equation_value;
                    isConst = 'true';
                } else {
                    equation_value = condition.equation_value_tag.name.startsWith(VariableTypeEnum.AVG_NO)
                        ? condition.equation_value_tag.name.replace(VariableTypeEnum.AVG_NO, VariableTypeEnum.AVG)
                        : condition.equation_value_tag.name;
                    isConst = 'false';
                }

                this.conditionForm = this.createCondition();
                this.conditionForm.patchValue({
                    id: condition.id,
                    conditionName: condition.name,
                    tag_name: condition.tag.name.startsWith(VariableTypeEnum.AVG_NO)
                        ? condition.tag.name.replace(VariableTypeEnum.AVG_NO, VariableTypeEnum.AVG)
                        : condition.tag.name,
                    equation_operator: condition.equation_operator,
                    cycles: condition.cycles,
                    equation_value: equation_value,
                    constRadio: isConst,
                });

                this.conditions.push(this.conditionForm);
                this.conditionsStatus.push({ valid: true });
            });
        } else {
            this.addCondition();
        }
        this.previousConditions = this.formulaForm.get('conditions').value;
    }

    deleteFormula() {
        const conf = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        this.openDialog(conf);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                let conf = this.defaultDialog;
                conf.data.message = MessagesEnum.conditionsDeleted;
                this.openDialog(conf);
            }
        });
    }

    onSubmit() {
        let conf = this.defaultDialog;
        let invalidCycle = this.conditions.value.find((condition) => Number.isInteger(condition.cycles));
        let equation = this.formulaForm.get('equation').value;
        this.validateFormulaData();
        if (this.validConditions) {
            this.validateEquation(equation);
        }
        if (this.validConditions && (!equation || this.validEquation) && this.tabIndex == 0) {
            this.setFormDataToObject();
            conf.data.message = this.conditionsAdded;
        } else if (this.formulaForm.valid && equation && this.validEquation) {
            this.setFormDataToObject();
            conf.data.message = this.equationAdded;
        } else if (!this.validEquation) {
            conf.data.message = this.invalidEquation;
        } else if (this.noConditions) {
            conf.data.message = MessagesEnum.conditionsDeleted;
        } else if (!invalidCycle) {
            conf.data.message = this.intNumberRequired;
        } else {
            if (!equation) {
                this.formulaForm.controls['equation'].setErrors({ incorrect: true });
            }
            conf.data.message = this.invalidFormMessage;
        }
        this.openDialog(conf);
    }

    validateEquation(equation: string) {
        try {
            let postfix = infix2postfix(equation);
            this.validEquation = true;
        } catch (e) {
            this.validEquation = false;
        }
    }

    setFormDataToObject() {
        const formulaFormData = this.formulaForm.getRawValue();
        const conditionsList = formulaFormData.conditions;

        this.formula.activate = this.data.active;
        this.formula.formula = formulaFormData.equation;
        this.formula.formula_conditions = this.insertconditions(conditionsList);
    }

    insertconditions(conditions: any) {
        var conditionsArray: SPFormulaCondition[] = [];
        for (let condition of conditions) {
            let newCondition: SPFormulaCondition = new SPFormulaCondition();

            newCondition.name = condition.conditionName;
            newCondition.id = condition.id;
            newCondition.tag.name = condition.tag_name.startsWith(VariableTypeEnum.AVG)
                ? condition.tag_name.replace(VariableTypeEnum.AVG, VariableTypeEnum.AVG_NO)
                : condition.tag_name;
            newCondition.equation_operator = condition.equation_operator;
            newCondition.cycles = condition.cycles;

            if (condition.constRadio == 'true') {
                newCondition.equation_value = condition.equation_value;
                newCondition.equation_value_tag = null;
            } else {
                newCondition.equation_value_tag.name = condition.equation_value.startsWith(VariableTypeEnum.AVG)
                    ? condition.equation_value.replace(VariableTypeEnum.AVG, VariableTypeEnum.AVG_NO)
                    : condition.equation_value;
            }

            conditionsArray.push(newCondition);
        }
        return conditionsArray;
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        let response = {
            formula: this.formula,
        };

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (
                (options.data.message == this.conditionsAdded && this.conditions.length == 1) ||
                options.data.message == this.equationAdded
            ) {
                this.dialogRef.close(response);
            } else if (options.data.message == this.conditionsAdded && this.conditions.length > 1) {
                this.activeTab = 1;
            } else if (options.data.message == MessagesEnum.conditionsDeleted) {
                let deleteResponse = {
                    delete: true,
                };
                this.dialogRef.close(deleteResponse);
            }
        });
    }

    getControls() {
        return (this.formulaForm.get('conditions') as UntypedFormArray).controls;
    }

    close() {
        this.dialogRef.close();
    }

    getConditions() {
        this.tabIndex = this.tabIndex == 0 ? 1 : 0;
        if (this.tabIndex == 1) {
            this.conditions = this.formulaForm.get('conditions') as UntypedFormArray;
            let conditionsValues = this.conditions.controls;
            this.checkFormulaChanges();

            this.conditionList = [];
            conditionsValues.forEach((condition) => {
                if (condition.valid) {
                    this.conditionList.push(condition.value);
                }
            });
            this.previousConditions = this.formulaForm.get('conditions').value;
        }
        this.activeTab = this.tabIndex;
    }

    checkFormulaChanges() {
        let equation = this.formulaForm.controls['equation'];
        let formula = equation.value;
        let conditionsValues = this.conditions.value;

        if (formula) {
            conditionsValues.forEach((condition, index) => {
                let previousConditionName = this.previousConditions[index]
                    ? this.previousConditions[index].conditionName
                    : null;
                let currentConditionName = condition.conditionName;
                if (previousConditionName && previousConditionName != currentConditionName) {
                    formula = formula.replaceAll(previousConditionName, currentConditionName);
                }
            });
            this.formulaForm.patchValue({
                equation: formula,
            });
            this.cursorEnd = formula.length;
            this.cursorStart = this.cursorEnd;
        } else {
            this.formulaForm.controls['equation'].setErrors(null);
        }
    }

    clearText() {
        this.formulaEquation = '';
        this.formulaForm.patchValue({
            equation: this.formulaEquation,
        });
    }

    resetTagName(index) {
        let currentTagName = this.conditions.value[index]['tag_name'];
        if (currentTagName) {
            this.previousTagName[index] = currentTagName;
            this.conditions.controls[index].patchValue({ tag_name: '' });
        }
    }

    setTagName(index) {
        let previousTagName = this.conditions.value[index]['tag_name']
            ? this.conditions.value[index]['tag_name']
            : this.previousTagName[index];
        if (previousTagName) {
            this.conditions.controls[index].patchValue({ tag_name: previousTagName });
        }
    }

    resetConditionValue(index) {
        let currentConditionValue = this.conditions.value[index]['equation_value'];
        let isConst = this.conditions.value[index]['constRadio'];
        if (currentConditionValue && isConst == 'false') {
            this.previousConditionValue[index] = currentConditionValue;
            this.conditions.controls[index].patchValue({ equation_value: '' });
        }
    }

    setConditionValue(index) {
        let previousConditionValue = this.conditions.value[index]['equation_value']
            ? this.conditions.value[index]['equation_value']
            : this.previousConditionValue[index];
        let isConst = this.conditions.value[index]['constRadio'];
        if (previousConditionValue && isConst == 'false') {
            this.conditions.controls[index].patchValue({ equation_value: previousConditionValue });
        }
    }

    isShowForm() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType !== 'BUILDING';
    }
}
