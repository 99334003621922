import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Response } from '../../models/response.model';
import { Observable } from 'rxjs/internal/Observable';
import { Quarterback } from '../../models/views-models/quarterback.model';
import { environment } from 'src/environments/environment';

const API = environment.serverUrl;
@Injectable()
export class QuarterbackService {
    constructor(private httpClient: HttpClient) {}

    getQuarterbackSettings(): Observable<Quarterback> {
        return this.httpClient.get<Response>(`${API}quarterback`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const settings: Quarterback = response.data;
                return settings;
            })
        );
    }

    saveQuarterback(settings: Quarterback): Observable<Quarterback> {
        return this.httpClient.post<Response>(`${API}quarterback`, settings, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const settings: Quarterback = response.data;
                return settings;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
        return headers;
    }
}
