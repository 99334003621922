<mat-card-header class="ihm-card-header">
    <div mat-card-avatar class="header-image">
        <i class="material-icons">{{ icon }}</i>
    </div>
    <mat-card-subtitle>{{ subtitle }} </mat-card-subtitle>
    <mat-card-title class="ihm-elevation-y-10"
        >{{ title }} <i *ngIf="info" class="material-icons info-header" [matTooltip]="info">info</i></mat-card-title
    >
</mat-card-header>
<mat-divider class="ihm-elevation-y-15"></mat-divider>
