<div class="container customFont">
    <form [formGroup]="parentForm">
        <div fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px">
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select
                            placeholder="Tipo"
                            formControlName="variableType"
                            (selectionChange)="updateTagsOptions($event.value)"
                            data-cy="variable-type"
                        >
                            <mat-option *ngFor="let item of variableTypes" [value]="item.name">
                                {{ item.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="50%">
                    <div *ngIf="isAntecedent1">
                        <mat-form-field appearance="outline">
                            <mat-label>Variável</mat-label>
                            <input
                                type="text"
                                matInput
                                placeholder="Variável"
                                (focus)="clearField($event.target.value)"
                                (focusout)="restoreInput()"
                                list="antecedent1DL"
                                (change)="setMaxMin($event.target.value)"
                                formControlName="entryName"
                                autocomplete="off"
                                data-cy="tag-name"
                            />
                        </mat-form-field>
                        <datalist id="antecedent1DL">
                            <option *ngFor="let tag of tagsOptions" [value]="tag.name"></option>
                        </datalist>
                    </div>
                    <div *ngIf="!isAntecedent1">
                        <mat-form-field appearance="outline">
                            <mat-label>Variável</mat-label>
                            <input
                                type="text"
                                matInput
                                placeholder="Variável"
                                (focus)="clearField($event.target.value)"
                                (focusout)="restoreInput()"
                                list="antecedent2DL"
                                (change)="setMaxMin($event.target.value)"
                                formControlName="entryName"
                                autocomplete="off"
                                data-cy="tag-name"
                            />
                        </mat-form-field>
                        <datalist id="antecedent2DL">
                            <option *ngFor="let tag of tagsOptions" [value]="tag.name"></option>
                        </datalist>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Formato da função central</mat-label>
                        <mat-select formControlName="curveType" data-cy="curve-type">
                            <mat-option *ngFor="let item of curveTypes" [value]="item.id">
                                {{ item.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Topo do Trapézio (%)</mat-label>
                        <input
                            matInput
                            placeholder="Topo do Trapézio (%)"
                            type="number"
                            min="0"
                            max="100"
                            formControlName="top"
                            autocomplete="off"
                            data-cy="top"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row">
                <div fxFlex="calc(100% + 10px)">
                    <mat-form-field appearance="outline">
                        <mat-label> Conjunto Fuzzy</mat-label>
                        <mat-select
                            placeholder=" Conjunto Fuzzy"
                            formControlName="fuzzySet"
                            (selectionChange)="fuzzyChange()"
                            data-cy="fuzzy-set"
                        >
                            <mat-option *ngFor="let item of fuzzySets" [value]="item.id">
                                {{ item.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>
