import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Weighted } from 'src/app/shared/models/views-models/weighted.model';
import { WeightedService } from 'src/app/shared/service/views-services/weighted.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { AddWeightedDialogComponent } from '../add-weighted-dialog/add-weighted-dialog.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-table-weighted',
    templateUrl: './table-weighted.component.html',
    styleUrls: ['./table-weighted.component.scss'],
})
export class TableWeightedComponent implements OnInit {
    @Input() user;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns = ['name', 'description', 'actions'];
    weighteds: Weighted[];
    weightedData = [];
    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    message = '';
    dialogRef: any;
    dialogRefMsg: any;
    deleteMessage = MessagesEnum.DeleteMessage;

    constructor(private weightedService: WeightedService, public dialog: MatDialog, private currentProjectService: CurrentProjectService) {}

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.weightedService.getAllWeighteds().subscribe((weighteds) => {
            this.weighteds = weighteds;
            this.weightedData = [];
            this.weighteds.forEach((weighted) => {
                this.weightedData.push({
                    name: this.limitStringLength(weighted.tag.name, true),
                    description: this.limitStringLength(weighted.tag.description, false),
                    weighted: weighted.tag.name,
                });
            });
            this.dataSource = new MatTableDataSource(this.weightedData);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
        });
    }

    limitStringLength(text: string, isName: boolean) {
        if (isName) {
            return text.length >= 28 ? text.substring(0, 25) + '...' : text;
        } else {
            return text.length >= 30 ? text.substring(0, 27) + '...' : text;
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    add() {
        this.openDialog(null);
    }

    deleteElement(element: any) {
        const conf = {
            component: ConfirmDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };
        const confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };

        this.openConfirmDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                let weighted = this.weighteds.find((weighted) => weighted.tag.name == element.weighted);
                this.weightedService.deleteWeighted(weighted.id).subscribe((dependencies) => {
                    if (dependencies.length > 0) {
                        conf.width = 'auto';
                        conf.height = 'auto';
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            dependencieNames += tag.name + ', ';
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message =
                            'Conflito ao deletar. Esta ponderada é usada como entrada em: ' + dependencieNames;
                    } else {
                        conf.data.message = this.deleteMessage;
                    }
                    this.openConfirmDialog(conf);
                });
            }
        });
    }

    selectElement(element: any) {
        let weighted = this.weighteds.find((weighted) => weighted.tag.name == element.weighted);
        this.openDialog(weighted);
    }

    openDialog(data: any) {
        this.dialogRef = this.dialog.open(AddWeightedDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '70%',
            height: 'auto',
            maxWidth: '1250px',
            data,
        });

        this.dialogRef.afterClosed().subscribe(() => {
            this.loadData();
        });
    }

    openConfirmDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (options.data.message == this.deleteMessage) {
                this.loadData();
            }
        });
    }

    isShow() {
        const project = this.currentProjectService.getCurrentProject();
        return project?.versionType !== 'BUILDING';
    }
}
