<div fxFlex="40">
    <p>Variável Fuzzy</p>
    <select class="customInput" matInput formControlName="inputName">
        <option></option>
    </select>
</div>
<div fxFlex="10">
    <p>Peso</p>
    <input class="customInput" matInput formControlName="inputName" />
</div>
<div fxFlex>
    <p>Condição (TAG)</p>
    <select class="customInput" matInput formControlName="inputName">
        <option></option>
    </select>
</div>
