import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Response } from '../../models/response.model';
import { Process } from '../../models/views-models/process.model';
import { Tag } from '../../models/views-models/tag.model';
import { CurrentProjectService } from './current-project.service';

const API = environment.serverUrl;

@Injectable()
export class ProcessInputService {
    constructor(private httpClient: HttpClient,  private currentProjectService: CurrentProjectService,) {}

    getAllProcesses(): Observable<Process[]> {
        return this.httpClient.get<Response>(`${API}process/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const processes: Process[] = response.data.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
                return processes;
            })
        );
    }

    getAllProcessesByType(type: string): Observable<Process[]> {
        return this.httpClient
            .get<Response>(`${API}process/all/process-type/${type}`, { headers: this.getHeaders() })
            .pipe(
                map((response: Response) => {
                    const processes: Process[] = response.data.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
                    return processes;
                })
            );
    }

    newProcess(process: Process) {
        const project = this.currentProjectService.getCurrentProject();
        process.tag.project = project;

        return this.httpClient.post<Response>(`${API}process/new`, process, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newProcess: Process = response.data;
                return newProcess;
            })
        );
    }

    editProcess(process: Process) {
        return this.httpClient.put<Response>(`${API}process/edit`, process, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const process: Process = response.data;
                return process;
            })
        );
    }

    deleteProcess(id: string): Observable<Tag[]> {
        return this.httpClient.delete<Response>(`${API}process/delete/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const tags: Tag[] = response.data;
                return tags;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }
}
