import { Component, Inject, OnInit } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Equation } from 'src/app/shared/models/views-models/equation.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { EquationService } from 'src/app/shared/service/views-services/equation.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { getUserData } from 'src/app/shared/utils/userRole';
import { infix2postfix } from 'src/app/shared/utils/rpn';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../../dialogs/delete-dialog/delete-dialog.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
@Component({
    selector: 'app-equation-add-edit',
    templateUrl: './equation-add-edit.component.html',
    styleUrls: ['./equation-add-edit.component.scss'],
})
export class EquationAddEditComponent implements OnInit {
    successMessage = MessagesEnum.SuccessMessage;
    failureMessage = MessagesEnum.FailureNameMessage;
    invalidEquationMessage = 'A sintaxe da equação está incorreta';
    userDoesNotHavePermissionMessage = 'O usuário não possui permissão para realizar esta atividade';
    formAlert = MessagesEnum.invalidFormMessage;
    cursorStart = -1;
    cursorEnd = -1;
    formGroup: UntypedFormGroup;
    dialogRefMsg: any;
    isEditing: boolean;
    user: any;
    tagOptions: Array<Tag> = [];

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };
    equation: Equation;
    displayEquation: any;
    prettier: boolean;
    lastSelectedTag: Tag;
    verified: any;
    equationState: 'checked' | 'unchecked' | 'error' = 'unchecked';

    constructor(
        private tagService: TagService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<EquationAddEditComponent>,
        public currentProjectService: CurrentProjectService,

        private service: EquationService,
        @Inject(MAT_DIALOG_DATA) public data: Equation
    ) {}

    ngOnInit() {
        this.user = getUserData();
        this.initForm();
        this.isEditing =
            this.data !== null && this.data.id !== null && this.data.id !== undefined && this.data.id !== 'undefined';
        this.loadSelectAndInputsData();
        if(this.currentProjectService.isRunningMode()){
            this.prettier = true;
        }
    }

    initForm() {
        this.formGroup = this.formBuilder.group({
            id: [],
            name: [null, [Validators.required, Validators.minLength(1)]],
            description: [null, [Validators.required, Validators.minLength(1)]],
            equation: ['', [Validators.required]],
            tag: [],
            tagValue: [],
        });
        if (!this.user.permissions.canUpdate || this.currentProjectService.isRunningMode()) {
            this.formGroup.disable();
        }

        this.formGroup.get('equation').valueChanges.subscribe((eq) => this.highlightEquation(eq));
    }

    check_tab(element, event) {
        let code = element.value;
        if (event.key == 'Tab') {
            /* Tab key pressed */
            event.preventDefault(); // stop normal
            let before_tab = code.slice(0, element.selectionStart); // text before tab
            let after_tab = code.slice(element.selectionEnd, element.value.length); // text after tab
            let cursor_pos = element.selectionStart + 1; // where cursor moves after tab - moving forward by 1 char to after tab
            element.value = before_tab + '\t' + after_tab; // add tab char
            // move cursor
            element.selectionStart = cursor_pos;
            element.selectionEnd = cursor_pos;
            //update(element.value); // Update text to include indent
        }
    }

    loadSelectAndInputsData() {
        let observables: Array<Observable<Tag[]>> = [
            this.tagService.getAllTagsByType(VariableTypeEnum.FUZZY),
            this.tagService.getAllTagsByType(VariableTypeEnum.PROCESS),
            this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION),
            this.tagService.getAllTagsByType(VariableTypeEnum.WEIGHTED),
            this.tagService.getAllTagsByType(VariableTypeEnum.CALCULATED),
            this.tagService.getAllTagsByType(VariableTypeEnum.ANALOGIC),
            this.tagService.getAllTagsByType(VariableTypeEnum.MANIPULATED_VARIABLE),
            this.tagService.getAllTagsByType(VariableTypeEnum.LIMIT),
        ];

        combineLatest(observables).subscribe((tags) => {
            this.tagOptions = tags.reduce((acc, cur) => acc.concat(cur), []);
            this.tagOptions.sort((a: Tag, b: Tag) => a.name.localeCompare(b.name));
            if (this.isEditing) {
                this.createEditForm(this.data);
            }
        });
    }

    addEquation() {
        let expression = ` tag(${this.formGroup.get('tagValue').value}) `;
        let form: Equation = this.formGroup.getRawValue();
        let formulaEquation = form.equation ? form.equation : '';
        this.cursorStart = this.cursorStart == -1 ? formulaEquation.length : this.cursorStart;
        this.cursorEnd = this.cursorEnd == -1 ? formulaEquation.length : this.cursorEnd;
        let equation = [
            formulaEquation.slice(0, this.cursorStart),
            expression,
            formulaEquation.slice(this.cursorEnd),
        ].join('');

        this.formGroup.patchValue({
            equation: equation,
        });
        this.cursorStart += expression.length;
        this.cursorEnd = this.cursorStart;
    }

    createEditForm(data: Equation) {
        const id = data.id;
        this.service.get(id).subscribe((equation) => {
            this.equation = equation;

            this.formGroup.patchValue({
                id: equation.id,
                name: equation.name,
                description: equation.description,
                equation: this.getTagsNameFromEquation(equation.equation),
                tag: equation.tag,
            });
        });
    }

    delete() {
        const conf = this.defaultDialog;

        const confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };

        this.openDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.service.delete(this.equation.id).subscribe(
                    (deleted) => {
                        if (deleted.error) {
                            conf.data.message = `${deleted.error}. ${deleted.data.map((t) => t.name).join(';')}`;
                            this.openDialog(conf);
                        } else {
                            conf.data.message = MessagesEnum.DeleteMessage;
                            this.openDialog(conf);
                        }
                    },
                    () => {
                        conf.data.message = MessagesEnum.Exception;
                        this.openDialog(conf);
                    }
                );
            }
        });
    }

    async onSubmit() {
        let conf = this.defaultDialog;

        if (!this.isFormValid()) {
            conf.data.message = this.formAlert;
            return this.openDialog(conf);
        }

        if (!this.isUserEnabledToUpdate()) {
            conf.data.message = this.userDoesNotHavePermissionMessage;
            return this.openDialog(conf);
        }

        let equation = this.getEquationFromForm();

        // if (!this.isEquationValid(equation)) {
        //     conf.data.message = this.invalidEquationMessage;
        //     return this.openDialog(conf);
        // }

        let equationRequestPromise = this.service.add(equation);

        if (this.isEditing) {
            equationRequestPromise = this.service.update(equation);
        }

        equationRequestPromise.subscribe(
            (eq) => {
                conf.data.message = eq.data ? MessagesEnum.SuccessMessage : eq.error;
                this.openDialog(conf);
            },
            (e) => {
                conf.data.message = MessagesEnum.Exception + '\n' + e.message;
                this.openDialog(conf);
            }
        );
    }

    getEquationFromForm() {
        const equationData = this.formGroup.getRawValue();
        const equation = new Equation(
            equationData.id,
            equationData.name,
            equationData.description,
            this.getTagsIdsFromEquation(equationData.equation)
        );
        equation.project = this.currentProjectService.getCurrentProject();

        if (this.isEditing) {
            equation.tag = equationData.tag;
        }

        return equation;
    }

    getTagsIdsFromEquation(equation: string): string {
        let tagRegex = /tag\(([^\)]+)\)/g;
        equation = equation.replace(tagRegex, (tagWithName) => {
            let tagName = tagWithName.substring(4, tagWithName.length - 1);
            let tagOption = this.tagOptions.find((t) => t.name == tagName);
            if (tagOption) {
                return `tag(${tagOption.id})`;
            }
            return `[erro => tag ${tagName} nao encontrada]`;
        });

        return equation;
    }

    getTagsNameFromEquation(equation: string): string {
        let guidRegex = /\btag\([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\)/g;

        equation = equation.replace(guidRegex, (tagWitId) => {
            let tagId = tagWitId.substring(4, tagWitId.length - 1);
            let tagOption = this.tagOptions.find((t) => t.id == tagId);
            if (tagOption) {
                return `tag(${tagOption.name})`;
            }
            return `[erro => tag nao encontrada]`;
        });

        return equation;
    }

    highlightEquation(equation) {
        // Syntax Highlight
        this.equationState = 'unchecked';
        let tagRegex = /tag\(([^\)]+)\)/g;
        this.displayEquation = equation.replace(tagRegex, (tagWithName) => {
            let tagName = tagWithName.substring(4, tagWithName.length - 1);
            return `tag('${tagName}')`;
        });
    }

    selectTag(tag: Tag) {
        this.lastSelectedTag = tag;
    }

    updateTag() {
        let tag = this.lastSelectedTag;
        let equation = this.formGroup.get('equation').value;
        let newEquation = equation.replace(`#${tag.name}`, `tag(${tag.name})`);
        this.formGroup.patchValue({
            equation: newEquation,
        });
        this.lastSelectedTag = null;
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (options.data.message == this.successMessage || options.data.message == MessagesEnum.DeleteMessage) {
                this.dialogRef.close();
            }
        });
    }

    isEquationValid(equation: Equation): boolean {
        if (equation.equation.indexOf('erro => ') !== -1) {
            this.formGroup.get('equation').setErrors({ equation: 'Dependência não encontrada' });
            this.equationState = 'error';
            return false;
        }
        return this.isEquationStringValid(equation.equation);
    }

    isEquationStringValid(equation: string): boolean {
        try {
            if (!equation || !equation.trim()) {
                this.formGroup.get('equation').setErrors({ equation: 'Equação vazia' });
                this.equationState = 'error';
                return false;
            }

            for (let eq of equation.split('\n')) {
                if (eq && eq.trim()) {
                    infix2postfix(eq, true);
                }
            }
            this.formGroup.get('equation').setErrors(null);
            this.equationState = 'checked';
            return true;
        } catch (e) {
            console.log(e);
            this.formGroup.get('equation').setErrors({ equation: e.message });
            this.equationState = 'error';
            return false;
        }
    }

    validadeEquation() {
        this.isEquationValid(this.getEquationFromForm());
    }

    isFormValid() {
        return this.formGroup.valid;
    }

    isUserEnabledToUpdate() {
        return this.user.permissions.canUpdate;
    }

    close() {
        this.dialogRef.close();
    }

    getCursor(textArea) {
        this.cursorStart = textArea.selectionStart;
        this.cursorEnd = textArea.selectionEnd;
    }

    getIconCheckEquation() {
        if (this.equationState == 'checked') return 'done';

        if (this.equationState == 'unchecked') return 'cached';

        return 'error';
    }

}

export function validateWeightSum(): ValidatorFn {
    return (formArray: UntypedFormArray): { [key: string]: any } | null => {
        let valid: boolean = false;
        let sum = 0;
        formArray.controls.forEach((x: UntypedFormControl) => {
            sum += x.value.weight_value;
            if (sum == 1) {
                valid = true;
            } else {
                valid = false;
            }
        });
        return valid ? null : { notValid: true };
    };
}
