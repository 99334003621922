<div class="button-container">
    <button
        mat-raised-button
        class="cycle-info-button"
        matTooltip="Ao interagir sobre os indicadores dos ciclos, detalhes dos cálculos serão exibidos."
        matTooltipPosition="above"
    >
        <i class="material-icons custom-icon">info_outline</i>
    </button>
</div>
<div fxLayout="column" fxLayoutGap="16px" *ngIf="sidepanelInfo" fxLayoutAlign="center center">
    <div fxLayout="column" class="right-panel" fxLayoutAlign="center center" *ngIf="isSetpoint">
        <div fxLayout="row" fxLayoutGap="4px" class="value">
            <span>{{ sidepanelInfo.increment }}</span>
            <mat-icon [ngClass]="sidepanelInfo?.increment < 0 ? 'red-icon' : 'green-icon'">{{
                sidepanelInfo.incrementValue < 0 ? "arrow_downward" : "arrow_upward"
            }}</mat-icon>
        </div>
        <span class="title">INCREMENTO</span>
    </div>
    <div fxLayout="column" class="right-panel" fxLayoutAlign="center center">
        <span class="value">{{ sidepanelInfo.setpointValue }}</span>
        <span class="title">{{ panelTitle }}</span>
    </div>
    <div fxLayout="column" class="right-panel" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="4px" class="value">
            <span>{{ sidepanelInfo.setpointOn ? "Sim" : "Não" }}</span>
            <mat-icon [ngClass]="sidepanelInfo.setpointOn ? 'green-icon' : 'red-icon'">{{
                sidepanelInfo.setpointOn ? "check_circle" : "cancel"
            }}</mat-icon>
        </div>
        <span class="title">LIGADO</span>
    </div>
    <div fxLayout="column" class="right-panel" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="4px" class="value">
            <span>{{ getIsIncDecValid(sidepanelInfo.conditionsValid) }}</span>
            <mat-icon
                [ngClass]="sidepanelInfo.conditionsValid ? 'green-icon' : 'red-icon'"
                *ngIf="getIsConditionValid(sidepanelInfo.conditionsValid)"
                >{{ getConditionSymbol(sidepanelInfo.conditionsValid) }}</mat-icon
            >
        </div>
        <span class="title">CONDIÇÕES</span>
    </div>
    <button mat-button class="blue-button" (click)="drawer.toggle()">
        <div fxLayout="column">
            <span>DEMAIS</span>
            <span>CONDIÇÕES</span>
        </div>
    </button>
</div>

<mat-sidenav #drawer mode="over" position="end" class="sidenav-size" [fixedInViewport]="true">
    <div class="sidepanel" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="start center">
            <span *ngIf="sidepanelInfo">LOG: {{ _panelConfig?.name }}</span>
        </div>
        <button mat-icon-button (click)="drawer.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <app-cycle-information *ngIf="_panelConfig" [panelConfig]="_panelConfig"></app-cycle-information>
    <div><mat-divider></mat-divider></div>
    <app-cycle-information-tree *ngIf="_panelConfig" [panelConfig]="_panelConfig"></app-cycle-information-tree>
</mat-sidenav>
