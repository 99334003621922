import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    confirmTitle: string;
    dialogOrigin: string;
    confirmButton: string = 'OK';

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data.title) {
            this.confirmTitle = this.data.title;
        }
        if (this.data.confirmButton) {
            this.confirmButton = this.data.confirmButton;
        }
    }

    close(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
