import { Component, Input, OnInit } from '@angular/core';
import { OpenPanelInfo } from 'src/app/shared/models/views-models/sidepanelInfo.model';
import { SetpointCalculation } from '../../../shared/models/views-models/setpointCalculation.model';
import { SetpointValuesService } from '../setpoint-values.service';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { re } from 'mathjs';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { CycleInfo, CycleStatus } from 'src/app/shared/models/views-models/cycleInfo';
import { CycleOutputType } from '../setpoint-models/axis.config';
import { SpConditionsValuesService } from 'src/app/shared/service/views-services/spConditionValue.service';
import { SpConditionValue } from 'src/app/shared/models/views-models/spConditionValue.model';

@Component({
    selector: 'app-cycle-information',
    templateUrl: './cycle-information.component.html',
    styleUrls: ['./cycle-information.component.scss'],
})
export class CycleInformationComponent implements OnInit {
    _panelConfig: OpenPanelInfo;
    cycleInfo: CycleInfo;
    log: any;
    sidepanelDrawerInfo: any;

    @Input() set panelConfig(value: OpenPanelInfo) {
        this._panelConfig = value;
        this.cycleInfo = this._panelConfig.cycleInfo;
        this.sidepanelDrawerInfo = this._panelConfig.drawer;
        this.loadPanelInfo();
    }

    constructor(
        public setpointvalueService: SetpointValuesService,
        public spConditionsValuesService: SpConditionsValuesService
    ) {}

    ngOnInit() {}

    async loadPanelInfo() {
        this.log = this.sidepanelDrawerInfo;
        this.log.cycles = this._panelConfig?.output?.cycles;
        this.log.conditionsActive = this._getConditionsStatus();
        this.log.equation = this._getEquation();
        this.log.conditionValues = this._getConditions();
    }

    private _getConditionsStatus() {
        return this._getIncStatus() || this._getDecStatus();
    }

    private _getIncStatus() {
        return this._panelConfig?.output?.inc_formula !== null;
    }

    private _getDecStatus() {
        return this._panelConfig?.output?.dec_formula !== null;
    }

    private _getEquation() {
        if (this._isDec()) return this._getDecEquation();
        return this._getIncEquation();
    }

    private _isDec() {
        return this.log.increment < 0 && this._panelConfig?.output?.dec_formula?.activate;
    }

    private _getDecEquation() {
        return this._panelConfig?.output?.dec_formula?.formula;
    }

    private _getIncEquation() {
        return this._panelConfig?.output?.inc_formula?.formula;
    }

    private _getConditions() {
        const conditions = this.setpointvalueService
            .getSeriesValues(SpConditionValue)
            .filter((condition) => +new Date(condition.timestamp) === +new Date(this._panelConfig.timestamp));

        if (conditions.length > 0) return conditions;

        let selectedFormula = this._isDec()
            ? this._panelConfig?.output?.dec_formula
            : this._panelConfig?.output?.inc_formula;

        return selectedFormula?.formula_conditions.map((condition) => {
            return {
                name: condition.name,
                tagName: condition.tag.name,
                operator: condition.equation_operator,
                value: condition?.equation_value || condition?.equation_value_tag?.name,
                valid: null,
            };
        });
    }

    getTimestamp() {
        try {
            if (this.log.timestamp.indexOf('/') != -1) {
                return this.log.timestamp;
            }
        } catch (e) {}
        let date = new Date(this.log.timestamp);
        return date.toLocaleDateString('pt-br') + ' ' + date.toLocaleTimeString('pt-br');
    }

    formartTimestamp(timestamp: string): string {
        let date = new Date(timestamp);
        return date.toLocaleDateString('pt-br') + ' ' + date.toLocaleTimeString('pt-br');
    }

    formartNumber(value: string): string {
        if (isNaN(Number(value))) return value;
        return Number(value).toFixed(4);
    }

    get panelTitle(): string {
        return this.isSetpoint ? 'Setpoint' : 'Saída';
    }

    get isSetpoint() {
        return this.setpointvalueService.getCycleOutputType() === CycleOutputType.SETPOINT;
    }

    getIsConditionValid(value: string): string {
        if (this.isNullOrUndefined(value)) return '';
        return value ? 'True' : 'False';
    }

    getIsIncDecValid(value: string): string {
        if (this.isNullOrUndefined(value)) return '-';
        return value ? 'True' : 'False';
    }

    getConditionSymbol(value: string): string {
        if (this.isNullOrUndefined(value)) return '';
        return value ? 'check_circle' : 'cancel';
    }

    isNullOrUndefined(value: string): boolean {
        return value === null || value === undefined;
    }

    get isExecutionCycle() {
        return this._panelConfig?.cycleInfo?.cycleNumber % this._panelConfig?.output?.cycles === 0;
    }
}
