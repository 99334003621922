import { FuzzyAndWeightedComponent } from './fuzzy-and-weighted.component';
import { Routes } from '@angular/router';

export const FuzzyAndWeightedRoutes: Routes = [
    {
        path: '',
        component: FuzzyAndWeightedComponent,
        data: {
            title: 'FuzzyAndWeighted',
        },
    },
];
