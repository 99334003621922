<div class="container">
    <div class="card">
        <form [formGroup]="incrementSPForm" (ngSubmit)="onSubmit()">
            <div data-cy="title" class="title" fxFlexFill fxLayoutAlign="space-between" fxLayout="row">
                <p>{{ isEditing ? "Editar" : "Adicionar" }} Setpoint</p>

                <div>
                    <span class="setpoint-status"
                        >Setpoint {{ incrementSPForm.controls["spStatus"].value ? "Ligado" : "Desligado" }}
                    </span>
                    <mat-slide-toggle color="primary" formControlName="spStatus"></mat-slide-toggle>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div fxFlexFill fxLayoutAlign="start" fxLayout="row" fxLayoutGap="25px">
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input
                            matInput
                            placeholder="Nome"
                            type="text"
                            formControlName="inputName"
                            autocomplete="off"
                            data-cy="name"
                        />
                    </mat-form-field>
                </div>
                <div fxFlex="50%">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input
                            matInput
                            placeholder="Descrição"
                            type="text"
                            formControlName="inputDesc"
                            autocomplete="off"
                            data-cy="description"
                        />
                    </mat-form-field>
                </div>
            </div>

            <datalist id="opcServersList">
                <option *ngFor="let opcServer of opcServers" [value]="opcServer.name"></option>
            </datalist>

            <div fxFlexFill fxLayoutAlign="start" fxLayout="row" fxLayoutGap="25px">
                <div fxLayout="row" fxFlex="50%">
                    <div fxFlex="30%">
                        <mat-form-field appearance="outline" class="opc-server-container">
                            <mat-label>Servidor</mat-label>
                            <input
                                matInput
                                list="opcServersList"
                                type="text"
                                formControlName="opcServerAddress"
                                data-cy="opc-server"
                                (focus)="resetInputOpcServer()"
                                (focusout)="setOpcServer($event.target.value)"
                            />
                        </mat-form-field>
                    </div>
                    <div fxFlex="70%">
                        <mat-form-field appearance="outline" class="opc-address-container">
                            <mat-label>Endereço do OPC de Saída</mat-label>
                            <input
                                matInput
                                placeholder="Endereço OPC de escrita"
                                type="text"
                                data-cy="opc-address"
                                formControlName="inputOPC"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxFlex="50%">
                    <div fxFlex="30%">
                        <mat-form-field appearance="outline" class="opc-server-container">
                            <mat-label>Servidor</mat-label>
                            <input
                                matInput
                                list="opcServersList"
                                type="text"
                                formControlName="opcServerStatus"
                                data-cy="opc-server-status"
                                (focus)="resetInputOpcServerStatus()"
                                (focusout)="setOpcServerStatus($event.target.value)"
                            />
                        </mat-form-field>
                    </div>
                    <div fxFlex="70%">
                        <mat-form-field appearance="outline" class="opc-address-container">
                            <mat-label>Endereço do OPC de Status</mat-label>
                            <input
                                matInput
                                placeholder="Endereço OPC de escrita"
                                type="text"
                                data-cy="opc-address-status"
                                formControlName="statusOPC"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>

            <p class="subTitle">Novo Setpoint</p>
            <div fxFlexFill fxLayoutAlign="start" fxLayout="row" fxLayoutGap="15px">
                <div fxFlex="30%">
                    <p>&nbsp;</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Setpoint Atual</mat-label>
                        <input
                            type="text"
                            matInput
                            placeholder="Setpoint Atual"
                            (focusout)="getMinMax($event.target.value)"
                            (focus)="resetActualSP()"
                            formControlName="actualTag"
                            list="actualSP"
                            autocomplete="off"
                            data-cy="current-setpoint"
                        />
                    </mat-form-field>
                    <datalist id="actualSP">
                        <option *ngFor="let process of actualTagOptions" [value]="process.tag.name"></option>
                    </datalist>
                </div>

                <i class="material-icons icon">add</i>

                <div fxFlex="35%" fxLayout="column">
                    <p>&nbsp;</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Incremento | Decremento</mat-label>
                        <input
                            type="text"
                            matInput
                            placeholder="Incremento | Decremento"
                            (focusout)="setIncDec($event.target.value)"
                            (focus)="resetIncDec()"
                            formControlName="incrementTag"
                            data-cy="inc-dec-setpoint"
                            list="increment"
                            autocomplete="off"
                        />
                    </mat-form-field>
                    <datalist id="increment">
                        <option *ngFor="let fuzzy of fuzzyOptions" [value]="fuzzy.name"></option>
                    </datalist>

                    <div fxLayout="row">
                        <div fxLayout="row" fxLayoutGap="5px" class="formulaDiv conditionText">
                            <a (click)="openFormula('inc')" data-cy="increment-conditions" >Condições do incremento</a>

                            <mat-slide-toggle color="primary" data-cy="switch-on-off-increment-condition" formControlName="toggleFormulaInc"></mat-slide-toggle>
                        </div>
                        <div fxLayout="row" fxLayoutGap="5px" class="formulaDiv conditionText">
                            <a (click)="openFormula('dec')">Condições do decremento</a>

                            <mat-slide-toggle color="primary" formControlName="toggleFormulaDec"></mat-slide-toggle>
                        </div>
                    </div>
                </div>

                <i class="material-icons icon">close</i>

                <div fxFlex="35%" fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="15px">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select
                                placeholder="Tipo"
                                (selectionChange)="changeIncInput()"
                                formControlName="incFactorType"
                            >
                                <mat-option *ngFor="let item of parameterTypes" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Fator de incremento</mat-label>
                            <input
                                type="{{ isIncConst ? 'number' : 'text' }}"
                                matInput
                                placeholder="Fator de incremento"
                                (focusout)="setIncFactor($event.target.value)"
                                (focus)="resetIncFactor()"
                                formControlName="incFactor"
                                list="incFactorList"
                                min="0.1"
                                step=".1"
                                autocomplete="off"
                            />
                            <div *ngIf="!isIncConst">
                                <datalist id="incFactorList">
                                    <option *ngFor="let fuzzy of fuzzyOptions" [value]="fuzzy.name"></option>
                                </datalist>
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="15px">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select
                                placeholder="Fator de decremento"
                                (selectionChange)="changeDecInput()"
                                formControlName="decFactorType"
                            >
                                <mat-option *ngFor="let item of parameterTypes" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Fator de decremento</mat-label>
                            <input
                                type="{{ isDecConst ? 'number' : 'text' }}"
                                matInput
                                placeholder="Fator de decremento"
                                (focusout)="setDecFactor($event.target.value)"
                                (focus)="resetDecFactor()"
                                formControlName="decFactor"
                                list="decFactorList"
                                min="0.1"
                                step=".1"
                                autocomplete="off"
                            />
                            <div *ngIf="!isDecConst">
                                <datalist id="decFactorList">
                                    <option *ngFor="let fuzzy of fuzzyOptions" [value]="fuzzy.name"></option>
                                </datalist>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider>

            <p class="subTitle">Variáveis Complementares</p>
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field appearance="outline" fxFlex="25%">
                    <mat-label>PV</mat-label>
                    <input
                        matInput
                        formControlName="pvInput"
                        list="pvList"
                        autocomplete="off"
                        (focusout)="setPv($event.target.value)"
                        (focus)="resetPv()"
                    />
                    <button
                        *ngIf="incrementSPForm.controls['pvInput'].value"
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="clearPv()"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <datalist id="pvList">
                    <option *ngFor="let process of pvOptions" [value]="process.tag.name"></option>
                </datalist>
                <mat-form-field appearance="outline" fxFlex="25%" *ngIf="!isActSpMV">
                    <mat-label>MV</mat-label>
                    <input
                        matInput
                        formControlName="mvInput"
                        list="mvList"
                        autocomplete="off"
                        (focusout)="setMv($event.target.value)"
                        (focus)="resetMv()"
                    />
                    <button
                        *ngIf="incrementSPForm.controls['mvInput'].value"
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="clearMv()"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <datalist id="mvList">
                    <option *ngFor="let mv of mvList" [value]="mv.tag.name"></option>
                </datalist>
            </div>

            <mat-divider></mat-divider>

            <div fxFlexFill fxLayoutAlign="start" fxLayout="row" fxLayoutGap="20px">
                <div fxFlex>
                    <p class="subTitle">Frequência de atuação</p>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxFlex fxLayout="row" fxLayoutGap="10px">
                            <div fxFlex="300px">
                                <mat-form-field appearance="outline">
                                    <mat-label>Ciclos</mat-label>
                                    <input
                                        matInput
                                        placeholder="Ciclos"
                                        (change)="calculateTime()"
                                        (keyup)="calculateTime()"
                                        type="number"
                                        min="1"
                                        pattern="\d+"
                                        formControlName="inputCycle"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                            <div fxFlex="80px" fxLayout="row" fxLayoutAlign="start center">
                                <span class="grayText">= {{ dynamicTime }}s</span>
                            </div>
                        </div>
                    </div>
                    <p class="cycleInfo">
                        O tempo de ciclo deste projeto é de <span style="font-weight: bold">{{ cycleTime }}s</span> .
                    </p>
                </div>
                <div fxFlex>
                    <p class="subTitle">Limites de Atuação</p>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <mat-select
                                    placeholder="Tipo"
                                    (selectionChange)="setLimitType()"
                                    formControlName="limitType"
                                >
                                    <mat-option *ngFor="let item of parameterTypes" [value]="item">
                                        {{ item }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxFlex="35%">
                            <mat-form-field appearance="outline">
                                <mat-label>Mínimo</mat-label>
                                <input
                                    matInput
                                    placeholder="Mínimo"
                                    list="minVariableList"
                                    type="{{ isLimitConst ? 'number' : 'text' }}"
                                    formControlName="inputMin"
                                    autocomplete="off"
                                    data-cy="setpoint-inf"
                                    (focus)="resetMinLimit()"
                                    (focusout)="setMinLimit($event.target.value)"
                                />
                            </mat-form-field>
                            <div *ngIf="showMinLabel && spMin && isLimitConst" class="limit-info">
                                O <span style="font-weight: bold">valor mínimo</span> deste setpoint é {{ spMin }}
                            </div>
                        </div>

                        <div fxLayout="column" fxFlex="35%">
                            <mat-form-field appearance="outline">
                                <mat-label>Máximo</mat-label>
                                <input
                                    matInput
                                    placeholder="Máximo"
                                    list="minVariableList"
                                    type="{{ isLimitConst ? 'number' : 'text' }}"
                                    formControlName="inputMax"
                                    autocomplete="off"
                                    data-cy="setpoint-sup"
                                    (focus)="resetMaxLimit()"
                                    (focusout)="setMaxLimit($event.target.value)"
                                />
                            </mat-form-field>
                            <div *ngIf="showMaxLabel && spMax && isLimitConst" class="limit-info">
                                O <span style="font-weight: bold">valor máximo</span> deste setpoint é {{ spMax }}
                            </div>
                            <div *ngIf="!isLimitConst">
                                <datalist id="minVariableList">
                                    <option *ngFor="let limit of limitOptions" [value]="limit.tag.name"></option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxFlex>
                    <button
                        mat-button
                        class="whiteButton"
                        [disabled]="!isEditing"
                        *ngIf="user.permissions.canDelete && currentProjectService.isBuildingMode()"
                        type="button"
                        (click)="delete()"
                    >
                        <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR SETPOINT
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="back()">CANCELAR</button>
                <button mat-button class="blueButton" type="submit" *ngIf="user.permissions.canUpdate && currentProjectService.isBuildingMode()" data-cy="save">SALVAR</button>
            </div>
        </form>
    </div>
</div>
