import { Component, ElementRef, Input, AfterViewInit } from '@angular/core';
import Prism from 'prismjs';
@Component({
    selector: 'prism, [prism]',
    template: '<ng-content></ng-content>',
    styles: [
        `
                     :host.dark {
                         background: #333;
                         color: #fff;
                     }
                 `,
    ],
})
export class PrismComponent implements AfterViewInit {
    @Input() code: string;
    @Input() language = 'python';

    @Input() set update(codeString) {
        const code = codeString || this.code || this.el.nativeElement.innerText;
        let grammar = Prism.languages[this.language];
        const html = Prism.highlight(code, grammar, this.language);
        this.el.nativeElement.innerHTML = html;
    }

    constructor(private el: ElementRef) {}
    ngAfterViewInit() {
        const code = this.code || this.el.nativeElement.innerText;
        const grammar = Prism.languages[this.language];
        const html = Prism.highlight(code, grammar, this.language);
        this.el.nativeElement.innerHTML = html;
    }
}
