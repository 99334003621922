import { Injectable } from '@angular/core';

@Injectable()
export class BaseMatrixService {
    constructor() {}

    fiveValor = ['Muito Alto', 'Alto', 'Normal', 'Baixo', 'Muito Baixo'];
    threeValor = ['Alto', 'Normal', 'Baixo'];
    threeTendence = ['Aumentando', 'Estavel', 'Diminuindo'];

    fiveValorForm = ['veryHigh', 'high', 'normal', 'low', 'veryLow'];
    threeValorForm = ['high', 'normal', 'low'];
    threeTendenceForm = ['goingUp', 'stable', 'fallingDown'];

    obj = [
        { desc: 'Muito Alto', form: 'veryHigh' },
        { desc: 'Alto', form: 'high' },
        { desc: 'Normal', form: 'normal' },
        { desc: 'Baixo', form: 'low' },
        { desc: 'Muito Baixo', form: 'veryLow' },
        { desc: 'Aumentando', form: 'goingUp' },
        { desc: 'Estavel', form: 'stable' },
        { desc: 'Diminuindo', form: 'fallingDown' },
    ];

    getFiveValues() {
        return this.fiveValor;
    }

    getFiveForm() {
        return this.fiveValorForm;
    }

    getThreeValues() {
        return this.threeValor;
    }

    getThreeValorForm() {
        return this.threeValorForm;
    }

    getThreeTendenceValues() {
        return this.threeTendence;
    }

    getThreeTendenceForm() {
        return this.threeTendenceForm;
    }

    findValorByForm(form: string) {
        return this.obj.find((item) => item.form === form);
    }
}
