import { Injectable } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';

import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatSnackBarConfig,
} from '@angular/material/snack-bar';

@Injectable()
export class MessageService {
    constructor(private dir: Directionality) {}

    message = '';
    actionButtonLabel = 'Retry';
    action = false;
    setAutoHide = true;
    autoHide = 10000;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    setMessage(message: string) {
        this.message = message;
    }

    getConfigMessage(): MatSnackBarConfig {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = this.addExtraClass ? ['party'] : undefined;
        config.direction = this.dir.value;
        return config;
    }
}
