import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { Tag } from '../../models/views-models/tag.model';
import { Weighted } from '../../models/views-models/weighted.model';
import { CurrentProjectService } from './current-project.service';

const API = environment.serverUrl;

@Injectable()
export class WeightedService {
    constructor(private httpClient: HttpClient,  private currentProjectService: CurrentProjectService) {}

    getAllWeighteds(): Observable<Weighted[]> {
        return this.httpClient.get<Response>(`${API}weighted/all`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const weighteds: Weighted[] = response.data.sort((a, b) => a.tag.name.localeCompare(b.tag.name));
                return weighteds;
            })
        );
    }

    getWeighted(id: string): Observable<Weighted> {
        return this.httpClient.get<Response>(`${API}weighted/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const weighted: Weighted = response.data;
                return weighted;
            })
        );
    }

    addWeighted(weighted: Weighted): Observable<Weighted> {
        const project = this.currentProjectService.getCurrentProject();
        weighted.tag.project = project;
        return this.httpClient.post<Response>(`${API}weighted/new`, weighted, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const newWeighted: Weighted = response.data;
                return newWeighted;
            })
        );
    }

    updateWeighted(weighted: Weighted): Observable<{ weighted: Weighted; error: string }> {
        return this.httpClient.put<Response>(`${API}weighted/edit`, weighted, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const weighted: Weighted = response.data;
                const error = response.error;
                return { weighted, error };
            })
        );
    }

    deleteWeighted(id: string): Observable<Tag[]> {
        return this.httpClient.delete<Response>(`${API}weighted/delete/${id}`, { headers: this.getHeaders() }).pipe(
            map((response: Response) => {
                const weighteds: Tag[] = response.data;
                return weighteds;
            })
        );
    }

    private getHeaders(): HttpHeaders {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const token = currentUser?.token;
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return headers;
    }
}
