import { LoginRoutes } from './views/login/login.routing';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layout/admin-layout/admin-layout.component';
import { CalculatedEquationsComponent } from './shared/components/views-components/calculated-equations/calculated-equations.component';
import { AuthGuard } from './shared/service/auth/auth.guard';
import { Views } from './shared/utils/views-urls';
import { DataTreatmentComponent } from './views/data-treatment/data-treatment.component';
import { ProjectsListComponent } from './views/projects-list/projects-list.component';
import { RulesTrackingComponent } from './views/rules-tracking/rules-tracking.component';
import { UsersListComponent } from './views/users-list/users-list.component';
import { ProjectResolver } from './shared/service/views-services/current-project.service';
import { SetpointsTrackingComponent } from './views/setpoints-tracking/setpoint-tracking/setpoints-tracking.component';

export const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: Views.controlRules.url,
                loadChildren: () =>
                    import('./views/fuzzy-and-weighted/fuzzy-and-weighted.module').then(
                        (m) => m.FuzzyAndWeightedModule
                    ),
                data: { title: Views.controlRules.name, menuTitle: Views.controlRules.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.saveControlRules.url,
                loadChildren: () =>
                    import('./views/add-control-rule/add-control-rule.module').then((m) => m.AddEditControlRule),
                data: { title: Views.saveControlRules.name, menuTitle: Views.saveControlRules.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.setpointsList.url,
                loadChildren: () => import('./views/setpoints/setpoints.module').then((m) => m.SetpointsModule),
                data: { title: Views.setpointsList.name, menuTitle: Views.setpointsList.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.saveSetpoint.url,
                loadChildren: () =>
                    import('./views/increment-setpoint/increment-setpoint.module').then(
                        (m) => m.AddEditSetpointsModule
                    ),
                data: { title: Views.saveSetpoint.name, menuTitle: Views.saveSetpoint.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.processInputsList.url,
                loadChildren: () =>
                    import('./views/process-inputs-list/process-inputs-list.module').then(
                        (m) => m.ProcessInputsListModule
                    ),
                data: { title: Views.processInputsList.name, menuTitle: Views.processInputsList.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.processOutputsList.url,
                loadChildren: () =>
                    import('./views/process-output-list/process-output-list.module').then(
                        (m) => m.ProcessOutputListModule
                    ),
                data: { title: Views.processOutputsList.name, menuTitle: Views.processOutputsList.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.dataTreatment.url,
                component: DataTreatmentComponent,
                data: { title: Views.dataTreatment.name, menuTitle: Views.dataTreatment.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.rulesTracking.url,
                component: RulesTrackingComponent,
                data: { title: Views.rulesTracking.name, menuTitle: Views.rulesTracking.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.usersList.url,
                component: UsersListComponent,
                data: { title: Views.usersList.name, menuTitle: Views.usersList.menuName },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.projects.url,
                component: ProjectsListComponent,
                data: {
                    title: Views.projects.name,
                    menuTitle: Views.projects.menuName,
                },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.setpointsTracking.url,
                component: SetpointsTrackingComponent,
                data: {
                    title: Views.setpointsTracking.name,
                    menuTitle: Views.setpointsTracking.menuName,
                },
                resolve: { project: ProjectResolver },
            },
            {
                path: Views.equationList.url,
                component: CalculatedEquationsComponent,
                data: {
                    title: Views.equationList.name,
                    menuTitle: Views.equationList.menuName,
                },
                resolve: { project: ProjectResolver },
            },
        ],
    },
    ...LoginRoutes,
];
