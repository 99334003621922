<span class="material-symbols-outlined deploy-version" (click)="drawer.toggle(); updateProjects()"> device_reset </span>

<mat-drawer #drawer class="history" mode="side" position="end">
    <h4 class="history-title">
        Histórico
        <span class="material-symbols-outlined history-close-button" (click)="drawer.close()"> close </span>
    </h4>

    <div *ngFor="let version of versions">
        <span class="history-version-title" fxLayout="100">Versão {{ getVersionType(version) }}</span>
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [ngClass]="version.defaultVersion ? 'history-content-active' : 'history-content'"
        >
            <div class="history-icon-content">
                <span class="material-icons-outlined"> account_circle </span>
            </div>
            <div class="history-box">
                <span fxLayout="80">{{ version.created }}</span>
                <span fxLayout="80">
                    {{ version.creator }}
                </span>
            </div>

            <button
                fxLayout="20"
                mat-icon-button
                class="history-more"
                fxLayoutAlign="start end"
                data-cy="project-menu-button"
                [matMenuTriggerFor]="projectMenu"
            >
                <i class="material-icons">more_vert</i>
            </button>
            <mat-menu #projectMenu="matMenu">
                <button
                    mat-menu-item
                    (click)="apply(drawer, version)"
                    data-cy="project-menu-restore"
                    [disabled]="version.defaultVersion"
                >
                    <span fxLayoutAlign="center center">
                        <span class="material-icons-outlined menu-icon">visibility</span>
                        Visualizar
                    </span>
                </button>

                <button
                    mat-menu-item
                    (click)="restore(drawer)"
                    data-cy="project-menu-restore"
                    *ngIf="getVersionType(version) == 'Building'"
                >
                    <span fxLayoutAlign="center center">
                        <span class="material-icons-outlined menu-icon"> restart_alt </span>
                        Restaurar</span
                    >
                </button>

                <button mat-menu-item (click)="download(version)" data-cy="project-menu-download">
                    <span fxLayoutAlign="center center">
                        <span class="material-icons-outlined menu-icon"> download_for_offline </span>
                        Download</span
                    >
                </button>
            </mat-menu>
        </div>
    </div>

    <button
        mat-button
        class="history-upload"
        fxLayout="row"
        fxLayoutAlign="center center"
        (click)="fileInput.click()"
        [disabled]="isUploadDisabled()"
    >
        <span fxLayoutAlign="center center">
            Upload da versão
            <span class="material-icons-outlined"> upload_file </span>
        </span>
    </button>
    <input type="file" (change)="onFileSelected($event, drawer)" #fileInput accept=".json" style="display: none" />
</mat-drawer>
