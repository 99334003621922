import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { FuzzyRoutes } from './add-control-rule.routing';
import { AddControlRuleComponent } from './add-control-rule.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuzzyService } from 'src/app/shared/service/views-services/fuzzy.service';
import { SystemParService } from 'src/app/shared/service/views-services/systemPar.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [AddControlRuleComponent],
    imports: [
        HttpClientModule,
        FormsModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatCardModule,
        MatButtonModule,
        MatGridListModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatDividerModule,
        CommonModule,
        SharedModule,
        RouterModule.forChild(FuzzyRoutes),
        MatExpansionModule,
        NgxEchartsModule.forRoot({ echarts: chartModule }),
    ],
    providers: [
        FuzzyService,
        SystemParService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class AddEditControlRule {}

export function chartModule(): any {
    return import('echarts');
}
