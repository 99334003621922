import { ProcessInputsListComponent } from './process-inputs-list.component';
import { Routes } from '@angular/router';

export const ProcessInputsListRoutes: Routes = [
    {
        path: '',
        component: ProcessInputsListComponent,
        data: {
            title: 'Entradas',
        },
    },
];
