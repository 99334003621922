import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { IncrementSetpoint } from 'src/app/shared/models/views-models/incrementSetpoint.model';
import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { Tag } from 'src/app/shared/models/views-models/tag.model';

export class AxisList {
    autoScale: boolean;
    mainAxisY1?: number;
    mainAxisY2?: number;
    secondaryAxisY1?: number;
    secondaryAxisY2?: number;
    secondaryAxis: Tag[];
    mainAxis: Tag[];
}

export class AxisConfig {
    autoScale: boolean;
    tags: TagAxis[];
    output: CycleOutput;
    mainLimits: {
        min: number;
        max: number;
    };
    secondLimits: {
        min: number;
        max: number;
    };

    constructor(output: CycleOutput) {
        this.output = output;
        this.mainLimits = {
            max: 100,
            min: 0,
        };
        this.secondLimits = {
            max: 100,
            min: 0,
        };
    }
}

export type AxisType = 'first' | 'second';

export type ChartClickEvent = {
    cycleInfo: CycleInfo;
    isDoubleClick: boolean;
};

export class TagAxis extends Tag {
    axis: AxisType;
    type?: VariableTypeEnum;
    setpoint?: IncrementSetpoint;
}

export type CycleOutput = IncrementSetpoint | OutputProcess;

export enum CycleOutputType {
    SETPOINT = 'Setpoint',
    OUTPUT = 'OutputProcess',
}

export function getCycleOutputType(cycleOutput: CycleOutput): CycleOutputType {
    return Boolean(cycleOutput?.['new_sp_tag']) ? CycleOutputType.SETPOINT : CycleOutputType.OUTPUT;
}

export function getCycleOutputTagName(cycleOutput: CycleOutput) {
    if (getCycleOutputType(cycleOutput) == CycleOutputType.SETPOINT)
        return (cycleOutput as IncrementSetpoint)?.new_sp_tag?.name;
    if (getCycleOutputType(cycleOutput) == CycleOutputType.OUTPUT) return cycleOutput?.name;
}

export function getCycleOutputTagId(cycleOutput: CycleOutput, getOutputTag: boolean = false) {
    if (getCycleOutputType(cycleOutput) == CycleOutputType.SETPOINT)
        return (cycleOutput as IncrementSetpoint)?.new_sp_tag?.id;

    if (getCycleOutputType(cycleOutput) == CycleOutputType.OUTPUT)
        return getOutputTag ? (cycleOutput as OutputProcess)?.tag_id : (cycleOutput as OutputProcess)?.tagVariable.id;
}

export function getOutputTag(cycleOutput: CycleOutput) {
    if (getCycleOutputType(cycleOutput) == CycleOutputType.SETPOINT) return (cycleOutput as IncrementSetpoint)?.act_sp;
    if (getCycleOutputType(cycleOutput) == CycleOutputType.OUTPUT) return (cycleOutput as OutputProcess)?.tagVariable;
}
