<div class="container">
    <mat-card appearance="outlined">
        <mat-card-header>
            <div class="title-home">
                <mat-card-title>Lista de Usuários</mat-card-title>
                <button
                    class="customButton add-button"
                    mat-raised-button
                    type="button"
                    (click)="add()"
                    [disabled]="!user.permissions.canUpdate"
                    data-cy="add"
                >
                    NOVO USUÁRIO
                </button>
            </div>
        </mat-card-header>

        <mat-divider></mat-divider>

        <mat-card-content>
            <mat-form-field appearance="outline">
                <mat-label>Buscar Usuário</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" />
                <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
            </mat-form-field>

            <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                        <mat-cell *matCellDef="let element"
                            ><a (click)="selectElement(element)">{{ element.name }}</a></mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <mat-header-cell *matHeaderCellDef>Empresa</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.company }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Permissão</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.role }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <mat-header-cell *matHeaderCellDef>Login</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="origin">
                        <mat-header-cell *matHeaderCellDef>Origem</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.origin }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions" class="actions">
                        <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button mat-button (click)="selectElement(element)">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                mat-button
                                (click)="deleteElement(element)"
                                [disabled]="!user.permissions.canUpdate || element.username == user.username"
                            >
                                <i class="material-icons">delete</i>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
