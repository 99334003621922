import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ProcessInputsListRoutes } from './process-inputs-list.routing';
import { ProcessInputsListComponent } from './process-inputs-list.component';

@NgModule({
    declarations: [ProcessInputsListComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(ProcessInputsListRoutes)],
})
export class ProcessInputsListModule {}
