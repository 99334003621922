import { CycleInfo } from 'src/app/shared/models/views-models/cycleInfo';
import { SetpointCalculation } from 'src/app/shared/models/views-models/setpointCalculation.model';
import { TagValue } from 'src/app/shared/models/views-models/tagValue.model';
import { CycleOutputType } from './axis.config';
import { DataIntervalType } from './setpoint-chart.options';

export type SetpointSeries = 'CycleInfo' | 'Setpoint' | 'Incremento' | 'Limite_Inf' | 'Limite_Sup' | 'Limites';
export type OutputSeries = 'CycleInfo' | 'Saída' | 'Condição';

export type ChartTypeMapper = {
    serie: SetpointSeries | OutputSeries;
    map: Function;
    label: string;
    yaxis?: number;
    cycleOutputType?: Array<CycleOutputType>;
    status?: boolean;
    intervalType: DataIntervalType;
};

export function chartTypeEquals(chartType: SetpointSeries | OutputSeries, anyType: any): boolean {
    return chartType.toString() == anyType.toString();
}

export function normalizeValue(value): number | string {
    if (value == 'false' || value == '0' || value === false) {
        return 0;
    }
    if (value == 'true' || value == '1' || value === true) {
        return 1;
    }

    const parsedNumber = +parseFloat(value).toFixed(4);

    if (isNaN(parsedNumber)) {
        return ' - ';
    }
    return parsedNumber;
}
export class ChartConfig {
    private defaultChartMappers: ChartTypeMapper[] = [
        {
            serie: 'CycleInfo',
            map: (cycleInfo: CycleInfo) => [cycleInfo.cycleTimestamp, 0],
            label: 'Informações do Ciclo',
            cycleOutputType: [CycleOutputType.SETPOINT, CycleOutputType.OUTPUT],
            status: false,
            intervalType: DataIntervalType.CYCLE_TIME,
        },
    ];

    private setpointChartMappers: ChartTypeMapper[] = [
        ...this.defaultChartMappers,
        {
            serie: 'Incremento',
            map: (setpointCalculation: SetpointCalculation) => [
                setpointCalculation.timestamp,
                normalizeValue(setpointCalculation.increment),
            ],
            label: 'Incremento',
            cycleOutputType: [CycleOutputType.SETPOINT],
            status: true,
            intervalType: DataIntervalType.CYCLE_TIME,
        },

        {
            serie: 'Limite_Inf',
            map: (setpointCalculation: SetpointCalculation) => [
                setpointCalculation.timestamp,
                normalizeValue(setpointCalculation.minLimit),
            ],
            label: 'Limites',
            cycleOutputType: [CycleOutputType.SETPOINT],
            status: true,
            intervalType: DataIntervalType.CYCLE_TIME,
        },
        {
            serie: 'Limite_Sup',
            map: (setpointCalculation: SetpointCalculation) => [
                setpointCalculation.timestamp,
                normalizeValue(setpointCalculation.maxLimit),
            ],
            label: 'Limites',
            cycleOutputType: [CycleOutputType.SETPOINT],
            status: true,
            intervalType: DataIntervalType.CYCLE_TIME,
        },
        {
            serie: 'Setpoint',
            map: (tagValue: TagValue) => [tagValue.timestamp, normalizeValue(tagValue.value)],
            label: 'Setpoint',
            cycleOutputType: [CycleOutputType.SETPOINT],
            status: true,
            intervalType: DataIntervalType.OPC_COLLECTOR,
        },
    ];

    private outputChartMappers: ChartTypeMapper[] = [
        ...this.defaultChartMappers,
        {
            serie: 'Saída',
            map: (tagValue: TagValue) => [tagValue.timestamp, normalizeValue(tagValue.value)],
            label: 'Saída',
            cycleOutputType: [CycleOutputType.OUTPUT],
            status: true,
            intervalType: DataIntervalType.CYCLE_TIME,
        },
        {
            serie: 'Condição',
            map: (tagValue: TagValue) => [tagValue.timestamp, normalizeValue(tagValue.value)],
            label: 'Condição',
            cycleOutputType: [CycleOutputType.OUTPUT],
            intervalType: DataIntervalType.CYCLE_TIME,
            status: true,
        },
    ];

    public getChartMappers(cycleOutputType: CycleOutputType): ChartTypeMapper[] {
        if (cycleOutputType == CycleOutputType.SETPOINT) return this.setpointChartMappers;
        return this.outputChartMappers;
    }

    public getChartTypeMap: Function = (serie: SetpointSeries | OutputSeries) => this.getChartMap(serie)?.map;

    public getChartMap = (serie: SetpointSeries | OutputSeries): ChartTypeMapper =>
        this.setpointChartMappers.find((chartMapper) => chartMapper.serie == serie) ||
        this.outputChartMappers.find((chartMapper) => chartMapper.serie == serie) ||
        this._getDefaultChartType(serie);

    private _getDefaultChartType(serie: string): ChartTypeMapper {
        return {
            serie: serie as SetpointSeries,
            map: (tagValue: TagValue) => [tagValue.timestamp, normalizeValue(tagValue.value)],
            label: serie,
            intervalType: DataIntervalType.FROM_TAG_VALUE,
        };
    }
}
