import { Project } from './project.model';
import { VariableType } from './variableType.model';

export class Tag {
    id: string;
    rootId: string;
    name: string;
    description: string;
    project: Project;

    variableType: VariableType = new VariableType();
    min: number;
    max: number;
    io_type?: String;
    isDisabled?: boolean;
}
