import { Antecedent } from './antecedent.model';
import { CurveType } from './curveType.model';
import { MatrixValues } from './matrixValues.model';
import { Tag } from './tag.model';

export class Fuzzy {
    id: string;
    antecedent1: Antecedent = new Antecedent();
    antecedent2: Antecedent = new Antecedent();
    curveType: CurveType = new CurveType();
    top: number;
    tag: Tag = new Tag();
    outFuzzySet: number;
    veryHigh: MatrixValues[];
    high: MatrixValues[];
    normal: MatrixValues[];
    low: MatrixValues[];
    veryLow: MatrixValues[];
    goingUp: MatrixValues[];
    stable: MatrixValues[];
    fallingDown: MatrixValues[];
}
