export class Point {
    x: number;
    y: number;

    constructor(x: number, y: number){
        if(typeof x == 'number'){
            this.x = x;
        }
        if(typeof y == 'number'){
            this.y = y;
        }
    }
}
