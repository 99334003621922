import { OutputProcess } from 'src/app/shared/models/views-models/outputProcess.model';
import { Component, EventEmitter, Input, IterableDiffers, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';

import { getUserData } from 'src/app/shared/utils/userRole';
import { ProcessOutputService } from 'src/app/shared/service/views-services/processOutput.service';
import { AddProcessOutputComponent } from '../add-process-output/add-process-output.component';
import { CategoriesService } from 'src/app/shared/service/views-services/categories.service';
import { Category } from 'src/app/shared/models/views-models/category.model';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-table-outputs',
    templateUrl: './table-outputs.component.html',
    styleUrls: ['./table-outputs.component.scss'],
})
export class TableOutputsComponent implements OnInit {
    @Input() table?: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Output() updateData = new EventEmitter();

    user: any;
    qbPort: any;
    qbHost: any;

    dataSource: MatTableDataSource<Object>;
    iterableDiffer: any;
    lastUpdate: string;
    message = '';
    displayedColumns = ['status', 'name', 'type', 'description', 'actions'];
    shortColumns = ['status', 'name', 'type', 'description', 'actions'];
    dialogRef: any;
    outputs: OutputProcess[];
    searchText: string;
    showAllTypes = true;
    activeTypes = [];
    selectedList = [];
    deleteMessage = MessagesEnum.DeleteMessage;
    filteredOutputs: OutputProcess[];
    tagTypes: Category[] = [];
    confirmDelete = {
        component: DeleteDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            singleDelete: true,
        },
    };

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };
    constructor(
        private _iterableDiffers: IterableDiffers,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private processOutputService: ProcessOutputService,
        public currentProjectService: CurrentProjectService,
        private categories: CategoriesService
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

    ngOnInit() {
        this.loadDatasource();
        this.user = getUserData();
        if (!this.user.permissions.canCreate) {
            this.displayedColumns = this.shortColumns;
        }
    }

    loadDatasource() {
        this.tagTypes = this.categories.listAllCategories();
        this.processOutputService.getAllOutputProcesses().subscribe((outputs) => {
            this.outputs = outputs;
            this.outputs = this.outputs.map((output) => {
                let tagtype = this.tagTypes.find((element) => element.name == output.tagVariable.variableType.name);
                output.tagVariable.variableType.description = tagtype.description;
                return output;
            });
            this.filteredOutputs = this.outputs;
            this.dataSource = new MatTableDataSource(this.outputs);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = (data: { name: string }, filterValue: string) =>
                data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                if (typeof data[sortHeaderId] === 'string') {
                    return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
            };
            if (this.searchText) {
                this.applyFilter(this.searchText);
            }
        });
        this.lastUpdate = new Date().toLocaleString();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.searchText = filterValue;
    }

    add() {
        this.openOutputModal(null);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadDatasource();
            }
        });
    }

    openOutputModal(data: any) {
        this.dialogRef = this.dialog.open(AddProcessOutputComponent, {
            panelClass: 'custom-dialog-container',
            width: 'auto',
            height: 'auto',
            data: data,
        });
        this.dialogRef.afterClosed().subscribe((input) => {
            if (input) {
                if (!input.id) {
                    let index = this.outputs.map((output) => output.name).indexOf(input.name);
                    this.outputs.splice(index, 1);
                }
            }
            this.loadDatasource();
        });
    }

    deleteElement(element: any) {
        const conf = this.defaultDialog;
        this.openAlertDialog(this.confirmDelete);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.processOutputService.deleteOutputProcess(element.id).subscribe((dependencies) => {
                    if (dependencies.length > 0) {
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            if (
                                dependencieNames.indexOf(tag.name + '(' + tag.variableType.description + ')' + ', ') ==
                                -1
                            ) {
                                dependencieNames += tag.name + '(' + tag.variableType.description + ')' + ', ';
                            }
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message = 'Conflito ao deletar. Esta variável é usada em: ' + dependencieNames;
                    } else {
                        this.selectedList = [];
                        conf.data.message = this.deleteMessage;
                        this.loadDatasource();
                    }
                    this.openAlertDialog(conf);
                    if (conf.data.message == this.deleteMessage) {
                        let index = this.outputs.indexOf(element);
                        this.outputs.splice(index, 1);
                    }
                });
            }
        });
    }

    openAlertDialog(options: any) {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            maxWidth: '720px',
            height: options.height,
            data: options.data,
        });
    }

    changeOutputStatus(output: OutputProcess) {
        output.status = !output.status;
        this.processOutputService.updateOutputStatus(output).subscribe((response) => {
            if (response) {
                this.defaultDialog.data.message = MessagesEnum.SuccessEditMessage;
                this.openDialog(this.defaultDialog);
            }
        });
    }

    openDialog(data: any) {
        this.dialogRef = this.dialog.open(data.component, {
            panelClass: data.panelClass,
            width: data.width,
            height: data.height,
            data: data.data,
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            if (data.data.message == this.deleteMessage) {
                this.loadDatasource();
            }
        });
    }

    selectElement(element: any) {
        let outputInfo = this.outputs.find((item) => item.id == element.id);
        let outputIndex = this.outputs.indexOf(outputInfo);
        let output = Object.assign({}, this.outputs[outputIndex]);
        this.openDialog(output);
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.outputs[outputIndex] = result;
            }
        });
    }

    editModal(output) {
        let outputInfo = this.outputs.find((item) => item.id == output.id);
        let outputIndex = this.outputs.indexOf(outputInfo);
        let outputData = Object.assign({}, this.outputs[outputIndex]);
        this.openOutputModal(outputData);
    }
}
