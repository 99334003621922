//Implementação do rpn extraída do scheduler
// retorna último valor sem removê-lo
const peek = (array) => {
    if (array.length === 0) {
        throw new Error('Equação Vazia');
    }
    return array[array.length - 1];
};

// precedência dos operadores
const ops = { or: 1, and: 2 };
// operadores Complexos
const complexOps = {
    __or__: 1,
    __and__: 2,
    '+': 3,
    '-': 4,
    '*': 5,
    '=': 6,
    __if__: 7,
    __then__: 8,
    __else__: 9,
    '==': 10,
    __not__: 11,
    '^': 12,
    __else____if__: 13,
    '<': 14,
    '>': 15,
    '<=': 16,
    '>=': 17,
    '/': 18,
    __then____if__: 19,
};

const simpleRegex = /(\and|or|\(|\))/gi;
const complexRegex =
    /(tag\(([^\)]+)\)|\-|\+|\*|\^|\/|<=|<|>=|>|==|=|\(|\)|__if__|__then____if__| __then__|__else____if__|__else__|__and__|__or__)/gi;
const specialKeys = /(if|then|elseif|thenif|else|and|or|not)\s/gi;

const isOperand = (token: string, isComplexOperation: boolean, operators: Array<string>) =>
    isComplexOperation ? operators.indexOf(token) == -1 && /[a-z0-9]+/.test(token) : /^c/.test(token);
const isOperator = (token: string, isComplexOperation: boolean) =>
    isComplexOperation ? token in complexOps : token in ops;
const getRegex = (isComplexOperation: boolean) => (isComplexOperation ? complexRegex : simpleRegex);

const isTag = (token: string) => /(tag\(([^\)]+)\))/.test(token);

const isRightParentesis = (token: string) => !isTag(token) && /\)/.test(token);
const isLeftParentesis = (token: string) => !isTag(token) && /\(/.test(token);

// transforma string que representa equação em array de tokens
const tokenize = (eq: string, complexOperation: boolean) => {
    if (complexOperation) {
        eq = eq.toLowerCase().replace(specialKeys, '__$1__');
    }
    if (eq.startsWith('-')) {
        eq = eq.substring(1);
    }
    const negRegex = /\(\s?(\-[^\)]+)\)/;
    while (negRegex.test(eq)) {
        eq = eq.replace(negRegex, 1 + '$1');
    }
    let tokens = eq
        .toLowerCase()
        .replace(/\s/g, '') // remove espaços, tabs, quebras de linhas...
        .replace(getRegex(complexOperation), ' $1 ') // insere espaços antes e depois de 'and', 'or', '(' e ')'
        .split(' ') // transforma em array
        .filter((e) => e); // remove elementos nulos

    return tokens;
};

function validateInfix(equation: string, isComplexOperation: boolean) {
    let equationTokens = tokenize(equation, isComplexOperation);
    let rightParenthesis = equationTokens.filter(isRightParentesis);
    let leftParenthesis = equationTokens.filter(isLeftParentesis);
    let equationOperators = equationTokens.filter((token: string) => isOperator(token, isComplexOperation));

    let equationOperands = equationTokens.filter((token: string) =>
        isOperand(token, isComplexOperation, equationOperators)
    );

    if (rightParenthesis.length !== leftParenthesis.length) {
        throw new Error('verifique os parentesis');
    }
    let lastToken = peek(equationTokens);

    if (isOperator(lastToken, isComplexOperation)) {
        throw new Error('Equação terminada com operador');
    }

    if (isLeftParentesis(lastToken)) {
        throw new Error('Equação terminada com (');
    }

    const firstOperator = equationOperators[0] || '';
    const secondOperator = equationOperators[1] || '';

    if (firstOperator == '__if__') {
        equationOperators.splice(0, 1);
        if (equationOperands[0].startsWith('tag(')) {
            equationOperands.splice(0, 1);
        }
    }

    if (secondOperator == '__if__') {
        equationOperators.splice(1, 1);
    }

    if (equationOperators.length + 1 > equationOperands.length) {
        throw new Error('Existem operadores sem variáveis associadas');
    }

    if (equationOperators.length + 1 < equationOperands.length) {
        throw new Error('Existem variáveis sem operadores associados');
    }
}

export function infix2postfix(equation: string, isComplexOperation = false) {
    const stack = [];
    validateInfix(equation, isComplexOperation);
    // implementação padrão do algoritmo shunting yard
    // https://en.wikipedia.org/wiki/Shunting-yard_algorithm#The_algorithm_in_detail
    let postfix = tokenize(equation, isComplexOperation)
        .reduce((queue, token) => {
            switch (true) {
                // operando
                case isOperand(token, isComplexOperation, []):
                    queue.push(`(${token})`);
                    break;
                // operador
                case isOperator(token, isComplexOperation):
                    while (stack.length && peek(stack) in ops && ops[token] <= ops[peek(stack)]) {
                        queue.push(stack.pop());
                    }
                    stack.push(token);
                    break;
                // parêntese esquerdo
                case /\(/.test(token):
                    stack.push(token);
                    break;
                // parêntese direito
                case /\)/.test(token):
                    while (peek(stack) !== '(') {
                        queue.push(stack.pop());
                    }
                    stack.pop();
                    break;
                default:
                    throw new Error('Token inválido na equação');
            }
            return queue;
        }, [])
        .concat(stack.reverse()) // concatena restante dos operadores conforme algoritmo
        .join(' ');
    return postfix;
}
