export class RolesEnum {
    public static roles = [
        { role: 'ROLE_OWNER', description: 'Global', id: '5e1a6fe4-59bf-11eb-ae93-0242ac130002' },
        { role: 'ROLE_ADMIN', description: 'Administrador', id: 'f591e765-f08f-4375-8eb6-5625cd34a57e' },
        { role: 'ROLE_MANTAINER', description: 'Sustentação', id: '72ad6b6e-59bf-11eb-ae93-0242ac130002' },
        { role: 'ROLE_USER', description: 'Visualização', id: '55f9d294-0e05-4518-bbe6-2f5168ad019b' },
    ];

    public static ROLE_OWNER = 'ROLE_OWNER';

    public static ROLE_ADMIN = 'ROLE_ADMIN';

    public static ROLE_MANTAINER = 'ROLE_MANTAINER';
}
