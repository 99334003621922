import { Tag } from './tag.model';

export class FuzzyCalculation {
    id: string;
    fuzzy_tag: Tag;
    antecedent1_tag: Tag;
    antecedent2_tag: Tag;
    fuzzy_value: number;
    antecedent1_value: number;
    antecedent2_value: number;
    timestamp: string;
}
