import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Project } from '../../models/views-models/project.model';

@Injectable()
export class LocalStorageService {
    private localStorageChanges = new Subject<Project>();
    newLocalStorageData = this.localStorageChanges.asObservable();

    triggerLocalStorageChanges(project: Project) {
        this.localStorageChanges.next(project);
    }

    clean(project: Project) {
        const sessionData = JSON.parse(localStorage.getItem('currentUser'));
        if (sessionData?.defaultProject?.name == project?.name) {
            delete sessionData.defaultProject;
            localStorage.setItem('currentUser', JSON.stringify(sessionData));
            this.triggerLocalStorageChanges(project);
        }
    }

    reset(project: Project) {
        const sessionData = JSON.parse(localStorage.getItem('currentUser'));
        sessionData.defaultProject = project;
        localStorage.setItem('currentUser', JSON.stringify(sessionData));
    }

    setQuaterbackReload(reload: any) {
        localStorage.setItem('quaterback', JSON.stringify(reload));
    }

    getQuaterbackReload() {
        return JSON.parse(localStorage.getItem('quaterback'));
    }

    hasDefaultProject() {
        const sessionData = JSON.parse(localStorage.getItem('currentUser'));
        return Boolean(sessionData?.defaultProject);
    }

    getDefaultProject() {
        const sessionData = JSON.parse(localStorage.getItem('currentUser'));
        return sessionData?.defaultProject;
    }
}
