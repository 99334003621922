import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Response } from '../../models/response.model';
import { SpConditionValue } from '../../models/views-models/spConditionValue.model';

const API = environment.serverUrl;

@Injectable()
export class SpConditionsValuesService {
    constructor(private httpClient: HttpClient) {}

    getSpConditionsValues(
        setpointId: string,
        startDatetime: string,
        endDatetime: string
    ): Observable<SpConditionValue[]> {
        return this.httpClient
            .get<Response>(`${API}sp-condition-values/${setpointId}/${startDatetime}/${endDatetime}`, {
                headers: this.getHeaders(),
            })
            .pipe(
                map((response: Response) => {
                    const spConditionsValues: SpConditionValue[] = response.data.sort((a, b) =>
                        a.name.localeCompare(b.name)
                    );
                    return spConditionsValues;
                })
            );
    }

    private getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'content-type': 'application/json', accept: 'application/json' });
        return headers;
    }
}
