import { AddControlRuleComponent } from './add-control-rule.component';
import { Routes } from '@angular/router';

export const FuzzyRoutes: Routes = [
    {
        path: '',
        component: AddControlRuleComponent,
        data: {
            title: 'AddEditControlRule',
        },
    },
];
