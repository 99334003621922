import { Component, OnInit } from '@angular/core';
import { getUserData } from 'src/app/shared/utils/userRole';

@Component({
    selector: 'app-setpoints',
    templateUrl: './setpoints.component.html',
    styleUrls: ['./setpoints.component.scss'],
})
export class SetpointsComponent implements OnInit {
    user: any;

    constructor() {}

    ngOnInit() {
        this.user = getUserData();
    }
}
