export class OpcServer {
    id: string;
    user: string;

    host: string;
    domain: string;
    username: string;
    password: string;
    keyType: string;
    clsId: string;
    progId: string;
    name: string;
}
