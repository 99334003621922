import { Project } from './project.model';
import { Tag } from './tag.model';

export class Equation {
    id: string;
    name: string;
    description: string;
    tag: Tag;
    project: Project;
    equation: string;

    constructor(id: string, name: string, description: string, equation: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.equation = equation;
    }
}
