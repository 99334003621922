import { SPFormula } from './spFormula.model';
import { Tag } from './tag.model';

export class SPFormulaCondition {
    id: string;
    name: string;
    sp_formula: SPFormula = new SPFormula();
    tag: Tag = new Tag();
    equation_value_tag: Tag = new Tag();
    equation_operator: string;
    equation_value: string;
    cycles: number;
}
