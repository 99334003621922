<div class="container">
    <div class="title" data-cy="title">{{ isEditing ? "Editar" : "Adicionar" }} Ponderada</div>

    <mat-divider></mat-divider>

    <div class="containerCard">
        <form [formGroup]="weightedForm" (ngSubmit)="onSubmit()">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxFlex="30">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input
                            matInput
                            placeholder="Nome da Variável Ponderada"
                            type="text"
                            formControlName="inputName"
                            autocomplete="off"
                            data-cy="name"
                        />
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input
                            matInput
                            placeholder="Descrição"
                            type="text"
                            formControlName="inputDesc"
                            autocomplete="off"
                            data-cy="description"
                        />
                    </mat-form-field>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div class="containerCard">
                <span class="subtitle">Fórmula </span>
                <i
                    class="material-icons info-header"
                    matTooltip="Pn = (Vo * Po, se Co) + ... + (Vn * Pn, se Cn)"
                    matTooltipPosition="after"
                    >info</i
                >
                <p class="description">
                    Insira as variáveis fuzzy, os pesos e as condições para montar a fórmula da ponderada
                </p>
            </div>

            <div class="fuzzy-inputs">
                <div
                    formArrayName="weightedEntries"
                    *ngFor="let item of getControls(); let i = index"
                    class="margin-top"
                >
                    <div [formGroupName]="i">
                        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutGap="15px">
                            <div class="subtitle index">
                                {{ i + 1 }}
                            </div>

                            <div fxFlex="40%" class="entry-tag-{{ i }}">
                                <mat-form-field appearance="outline">
                                    <mat-label>Variável Fuzzy</mat-label>
                                    <input
                                        type="text"
                                        matInput
                                        placeholder="Variável Fuzzy"
                                        (focus)="resetFuzzyField(i)"
                                        (focusout)="checkPreviousFuzzy(i)"
                                        list="fuzzyList"
                                        formControlName="entry_tag"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                                <datalist id="fuzzyList">
                                    <option *ngFor="let fuzzy of fuzzyOptions" [value]="fuzzy.name"></option>
                                </datalist>
                            </div>

                            <div fxFlex class="weight-value-{{ i }}">
                                <mat-form-field appearance="outline">
                                    <mat-label>Peso</mat-label>
                                    <input
                                        matInput
                                        placeholder="Peso"
                                        type="number"
                                        min="0"
                                        max="1"
                                        step=".01"
                                        formControlName="weight_value"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                            <div fxFlex="25%" class="condition-tag-{{ i }}">
                                <mat-form-field appearance="outline">
                                    <mat-label>Condição</mat-label>
                                    <input
                                        type="text"
                                        matInput
                                        placeholder="Condição"
                                        (focus)="resetConditionField(i)"
                                        (focusout)="checkPreviousCondition(i)"
                                        list="processList"
                                        formControlName="condition_tag"
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                                <datalist id="processList">
                                    <option
                                        *ngFor="let condition of conditionOptions"
                                        [value]="condition.name"
                                    ></option>
                                </datalist>
                            </div>
                            <div fxFlex="10%" class="remove-entry-{{ i }}">
                                <div class="delete-icon">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="removeWeightedEntry(i)"
                                        *ngIf="user.permissions.canDelete"
                                    >
                                        <i class="material-icons">delete</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    weightedForm.controls['weightedEntries'].hasError('notValid') &&
                    weightedForm.controls['weightedEntries'].touched
                "
                fxLayoutAlign="center center"
                class="form-error-msg"
                data-cy="weight-error"
            >
                <div>A soma dos pesos deve ser igual a 1</div>
            </div>

            <div *ngIf="user.permissions.canCreate">
                <button
                    mat-button
                    class="cancelButton"
                    type="button"
                    (click)="addWeightedEntry()"
                    [disabled]="!user.permissions.canUpdate"
                    data-cy="add-entry"
                >
                    <i class="material-icons"> add_circle </i> Adicionar variável fuzzy
                </button>
            </div>

            <mat-divider></mat-divider>

            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div fxFlex>
                    <button
                        mat-button
                        class="whiteButton"
                        [disabled]="!isEditing"
                        *ngIf="user.permissions.canDelete && currentProjectService.isBuildingMode()"
                        type="button"
                        (click)="deleteWeighted()"
                        data-cy="delete"
                    >
                        <i class="material-icons buttonIcon">delete</i>&nbsp;EXCLUIR PONDERADA
                    </button>
                </div>
                <button mat-button class="cancelButton" type="button" (click)="close()">CANCELAR</button>
                <button mat-button class="blueButton" type="submit" *ngIf="user.permissions.canUpdate && currentProjectService.isBuildingMode()" data-cy="save">
                    SALVAR
                </button>
            </div>
        </form>
    </div>
</div>
