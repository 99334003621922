<div class="container">
    <div fxFlexFill fxLayout="column">
        <div *ngIf="this.deleteTitle" fxFlex class="title">
            <p>{{ deleteTitle }}</p>
        </div>
        <div fxFlex class="warning" data-cy="pop-up">
            <p>{{ deleteMessage }}</p>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
            <button mat-button class="cancelDelete" type="button" (click)="close()">CANCELAR</button>
            <button mat-button class="redButton" type="button" (click)="confirm()" data-cy="confirm-delete">
                SIM, EXCLUIR
            </button>
        </div>
    </div>
</div>
