import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enrichArray } from './app/shared/utils/common.utils';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Third party JS imports
// Page loader
import './vendor/pace/pace.min.js';
// Charts
import './vendor/Chart.min.js';

declare global {
    interface Array<T> {
        first(): T | undefined;
    }
}

if (environment.production) {
    enableProdMode();
}

enrichArray();

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
